const T_INVALIDATE = 'tree/T_INVALIDATE';
const T_SELECT_NODE = 'tree/T_SELECT_NODE';

const T_FETCH_TREE = 'tree/FETCH_TREE';
const T_FETCH_TREE_SUCCESS = 'tree/FETCH_TREE_SUCCESS';
const T_FETCH_TREE_FAIL = 'tree/FETCH_TREE_FAIL';

const T_FETCH_DETAILS = 'tree/FETCH_DETAILS';
const T_FETCH_DETAILS_SUCCESS = 'tree/FETCH_DETAILS_SUCCESS';
const T_FETCH_DETAILS_FAIL = 'tree/FETCH_DETAILS_FAIL';

const T_ALLOW_BLOCK_PATH = 'tree/ALLOW_BLOCK_FILE';
const T_ALLOW_BLOCK_PATH_SUCCESS = 'tree/ALLOW_BLOCK_FILE_SUCCESS';
const T_ALLOW_BLOCK_PATH_FAIL = 'tree/ALLOW_BLOCK_FILE_FAIL';


const initialTreeState = {
    _nodes: [],
    _meta: {},
    selectedNode: undefined,
    details: {
        loading: false,
        error: undefined,
        data: undefined,
    },
};

// Reducers
//
function projectTreeReducer(state = initialTreeState, action = {}) {
    switch (action.type) {
        case T_INVALIDATE:
            return initialTreeState;
        case T_SELECT_NODE:
            const { nodeId, kind } = action;
            return {
                ...state,
                selectedNode: { nodeId, kind },
            };
        case T_FETCH_TREE:
            return {
                ...state,
                _meta: setNodeMeta(state._meta, action.path, { loading: true, error: undefined }),
            };
        case T_FETCH_TREE_SUCCESS:
            const isRoot = action.path === '/';
            return {
                ...state,
                _nodes: mergeDataIntoTree(isRoot ? [] : state._nodes, action.result),
                _meta: setNodeMeta(state._meta, action.path, { loading: false }),
            };
        case T_FETCH_TREE_FAIL:
            return {
                ...state,
                _meta: setNodeMeta(state._meta, action.path, { loading: false, error: action.error }),
            };
        case T_FETCH_DETAILS:
        case T_ALLOW_BLOCK_PATH:
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    error: undefined,
                },
            };
        case T_FETCH_DETAILS_SUCCESS:
        case T_ALLOW_BLOCK_PATH_SUCCESS:
            return {
                ...state,
                details: {
                    loading: false,
                    data: action.result,
                },
            };
        case T_FETCH_DETAILS_FAIL:
        case T_ALLOW_BLOCK_PATH_FAIL:
            return {
                ...state,
                details: {
                    loading: false,
                    error: action.error,
                },
            };
        default:
            return state;
    }
}


function reducer(state = {}, action = {}) {
    switch (action.type) {
        case T_INVALIDATE:
        case T_SELECT_NODE:
        case T_FETCH_TREE:
        case T_FETCH_TREE_SUCCESS:
        case T_FETCH_TREE_FAIL:
        case T_FETCH_DETAILS:
        case T_FETCH_DETAILS_SUCCESS:
        case T_FETCH_DETAILS_FAIL:
        case T_ALLOW_BLOCK_PATH:
        case T_ALLOW_BLOCK_PATH_SUCCESS:
        case T_ALLOW_BLOCK_PATH_FAIL:
            return {
                ...state,
                [action.projectId]: projectTreeReducer(state[action.projectId], action)
            };
        default:
            return state;
    }
}

// Util

function setNodeMeta(state, path, meta) {
    return {
        ...state,
        [path]: {
            ...state[path],
            ...meta,
        }
    };
}

function mergeDataIntoTree(_nodes, result) {
    result.paths.forEach((pathData) => {
        const node = pathDataToNode(pathData);
        const levels = pathData.path.split('/').slice(1); // /etc/bin/sh -> ['etc', 'bin', 'sh'];

        _nodes = upsertTreeNode(_nodes, node, levels);
    }, _nodes);

    return _nodes;
}

function pathDataToNode({ _t, path, packageId, ...rest }) {
    const isFile = _t === 'file';
    const label = path.slice(path.lastIndexOf('/') + 1); // /etc/bin/sh -> sh
    const packagePath = `${ packageId || '' }:${ path }`; // dir: ':/etc/bin', file: 'Default:/etc/bin/sh' or 'A:/etc/bin/sh'
    return {
        isFile,
        label,
        packagePath,
        data: { path, packageId, ...rest },
        ...(isFile ? {} : { _nodes: [] }),
    };
}

function upsertTreeNode(_nodes, newNode, levels) {
    const [curLevel, ...downLevels] = levels;
    const isLeaf = !downLevels.length;
    const treeNode = _nodes.find(({ isFile, label, packagePath }) => {
        if (isFile && !isLeaf) { return false; } // tree file is not on the same level as new file
        if (isFile && isLeaf) { return packagePath === newNode.packagePath; } // same file package:path
        return curLevel === label; // dir on file path
    });

    //console.log('>>upsert [%s], isLeaf?=%s, oldNode=', newNode.packagePath, isLeaf, treeNode);

    if (isLeaf) {
        if (treeNode) {
            // update data of found leaf
            return replace(_nodes, treeNode, { ...treeNode, data: newNode.data });
        } else {
            // add new new leaf to current level
            return [..._nodes, newNode];
        }
    }
    else {
        if (treeNode) {
            // go one level deep
            treeNode._nodes = upsertTreeNode(treeNode._nodes, newNode, downLevels);
            return _nodes;
        }
        else {
            console.error('Should not happen?'); //TODO
            return _nodes;
        }
    }
}

function replace(arr, item, newItem) {
    const itemIdx = arr.indexOf(item);
    if (itemIdx < 0) { return arr; }
    return [
        ...arr.slice(0, itemIdx),
        newItem,
        ...arr.slice(itemIdx + 1),
    ];
}


// Action creators
//

export function invalidateTree(projectId) {
    return {
        type: T_INVALIDATE,
        projectId,
    };
}

export function selectNode(projectId, nodeId, kind) {
    return {
        type: T_SELECT_NODE,
        projectId,
        nodeId,
        kind,
    };
}

export function fetchTreeNode(projectId, path = '/') {
    const isRoot = path === '/';
    const query = isRoot ? undefined : { dir: path };

    return {
        types: [T_FETCH_TREE, T_FETCH_TREE_SUCCESS, T_FETCH_TREE_FAIL],
        promise: (client) => client.get(`/api/projects/${ projectId }/whitelist/tree`, { query }),
        projectId,
        path,
        noAlert: 404,
    };
}

export function fetchPathDetails(projectId, nodeId, kind) {
    const [packageId, path] = nodeId.split(':');
    const query = {
        [kind]: path,
        ...( kind === 'file' ? { packageId } : {} ),
    };
    return {
        types: [T_FETCH_DETAILS, T_FETCH_DETAILS_SUCCESS, T_FETCH_DETAILS_FAIL],
        promise: (client) => client.get(`/api/projects/${ projectId }/whitelist/details`, { query }),
        projectId,
        path,
    };
}

export function allowBlockPath(projectId, nodeId, kind, op = 'allow') {
    const [packageId, path] = nodeId.split(':');
    const query = {
        [kind]: path,
        ...( kind === 'file' ? { packageId } : {} ),
    };
    return {
        types: [T_ALLOW_BLOCK_PATH, T_ALLOW_BLOCK_PATH_SUCCESS, T_ALLOW_BLOCK_PATH_FAIL],
        promise: (client) => client.post(`/api/projects/${ projectId }/whitelist/${ op }`, { query }),
        projectId,
        path,
    };
}

export default reducer;
