import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PrivateRouteContainer from "./PrivateRouteContainer";
import AuthPage from "./AuthPage";
import PrivatePage from "./PrivatePage";


const Routes = () => (
    <Router>
        <Switch>
            <Route path="/login" component={AuthPage}/>
            <Route path="/signup" component={AuthPage}/>
            <Route path="/approve" component={AuthPage}/>
            <Route path="/verify" component={AuthPage}/>
            <Route path="/restore(-proceed|-complete)?" component={AuthPage}/>
            <Route path="/invite-complete" component={AuthPage}/>
            <PrivateRouteContainer path="/projects" component={PrivatePage}/>
            <PrivateRouteContainer path="/attacks" component={PrivatePage}/>
            <PrivateRouteContainer path="/actions" component={PrivatePage}/>
            <Redirect from="/" to="/login"/>
        </Switch>
    </Router>
);


export default Routes;
