export function onDOMLoad(cb) {
    if (['complete', 'loaded', 'interactive'].includes(document.readyState) && document.body) {
        cb();
    } else {
        document.addEventListener('DOMContentLoaded', cb, false);
    }
}

export function addEventListener(node, event, listener) {
    if (node.addEventListener) {
        node.addEventListener(event, listener, false);
    } else {
        node.attachEvent(`on${event}`, listener);
    }
}

export function removeEventListener(node, event, listener) {
    if (node.removeEventListener) {
        node.removeEventListener(event, listener, false);
    } else {
        node.detachEvent(`on${event}`, listener);
    }
}

export function windowScrollX() {
    return (window.pageXOffset !== undefined) ? window.pageXOffset :
        (document.documentElement || document.body.parentNode || document.body).scrollLeft;
}

export function windowScrollY() {
    return (window.pageYOffset !== undefined) ? window.pageYOffset :
        (document.documentElement || document.body.parentNode || document.body).scrollTop;
}

// Util for 'sm-down' -> '(max-width: 768px)'
// Follows naming scheme of pre-bs4 grid classes (like 'hidden-sm-down')
export function matchMedia(gridClass) {
    const [bp, dir] = gridClass.split('-');
    const breakpoints = {
        down: { xs: '576px', sm: '768px', md: '992px', lg: '1200px' },
        up: { sm: '576px', md: '768px', lg: '992px', xl: '1200px' },
    };
    const dirs = { up: 'min', down: 'max' };
    const query = `(${ dirs[dir] }-width: ${ breakpoints[dir][bp] })`;

    return window.matchMedia(query).matches;
}

export const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
