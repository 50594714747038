import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Card, CardTitle, CardText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { SvgIcon, Toggler } from "../Elements";

class ProjectPolicyCard extends Component {

    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        buttonLink: PropTypes.string.isRequired,
        buttonDisabled: PropTypes.bool.isRequired,
        onToggle: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    render() {
        const { disabled, title, icon, buttonLink, buttonDisabled, children, onToggle } = this.props;
        const disabledClass = disabled ? 'disabled' : '';
        const description = !disabled ? children : 'Disabled';

        return (
            <Card className={ `policy-card ${ disabledClass }` }>
                <div className="policy-card-left">
                    <CardTitle tag="h5">{ title }</CardTitle>
                    <SvgIcon icon={ icon } sz="lg" classes="policy-icon"/>
                    <Toggler checked={ !disabled }
                        onChange={ onToggle }/>
                </div>
                <div className="policy-card-right">
                    <CardText>{ description }</CardText>
                    <Button color="danger" className="project-link" role="button"
                        tag={ Link } to={ buttonLink } disabled={ buttonDisabled }
                    >
                        Change <SvgIcon icon="btn-change" sz="xs"/>
                    </Button>
                </div>
            </Card>
        );
    }
}

export default ProjectPolicyCard;
