const LOGIN = 'auth/LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';
const SIGNUP = 'auth/SIGNUP';
const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
const SIGNUP_FAIL = 'auth/SIGNUP_FAIL';
const LOGOUT = 'auth/LOGOUT';
const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';
const RESTORE_START = 'auth/RESTORE_START';
const RESTORE_START_SUCCESS = 'auth/RESTORE_START_SUCCESS';
const RESTORE_START_FAIL = 'auth/RESTORE_START_FAIL';
const RESTORE_COMPLETE = 'auth/RESTORE_COMPLETE';
const RESTORE_COMPLETE_SUCCESS = 'auth/RESTORE_COMPLETE_SUCCESS';
const RESTORE_COMPLETE_FAIL = 'auth/RESTORE_COMPLETE_FAIL';
const INVITE_COMPLETE = 'auth/INVITE_COMPLETE';
const INVITE_COMPLETE_SUCCESS = 'auth/INVITE_COMPLETE_SUCCESS';
const INVITE_COMPLETE_FAIL = 'auth/INVITE_COMPLETE_FAIL';


// Reducers
//
const authReducer = ([ start, success, fail ]) => (state = {
    response: undefined,
    error: undefined,
    loading: false,
}, action) => {
    switch (action.type) {
        case start:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case success:
            return {
                ...state,
                loading: false,
                response: action.result,
                error: undefined,
            };
        case fail:
            return {
                ...state,
                loading: false,
                response: undefined,
                error: action.error,
            };
        default:
            return state;
    }
};

function reducer(state = {
    login: {},
    signup: {},
    logout: {},
    restoreStart: {},
    restoreComplete: {},
    invite: {},
}, action = {}) {
    switch (action.type) {
        case LOGIN:
        case LOGIN_SUCCESS:
        case LOGIN_FAIL:
            return {
                ...state,
                login: authReducer([LOGIN, LOGIN_SUCCESS, LOGIN_FAIL])(state.login, action),
            };
        case SIGNUP:
        case SIGNUP_SUCCESS:
        case SIGNUP_FAIL:
            return {
                ...state,
                signup: authReducer([SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL])(state.signup, action),
            };
        case LOGOUT:
        case LOGOUT_SUCCESS:
        case LOGOUT_FAIL:
            return {
                ...state,
                logout: authReducer([LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL])(state.logout, action),
            };
        case RESTORE_START:
        case RESTORE_START_SUCCESS:
        case RESTORE_START_FAIL:
            return {
                ...state,
                restoreStart: authReducer([RESTORE_START, RESTORE_START_SUCCESS, RESTORE_START_FAIL])(state.restoreStart, action),
            };
        case RESTORE_COMPLETE:
        case RESTORE_COMPLETE_SUCCESS:
        case RESTORE_COMPLETE_FAIL:
            return {
                ...state,
                restoreComplete: authReducer([RESTORE_COMPLETE, RESTORE_COMPLETE_SUCCESS, RESTORE_COMPLETE_FAIL])(state.restoreComplete, action),
            };
        case INVITE_COMPLETE:
        case INVITE_COMPLETE_SUCCESS:
        case INVITE_COMPLETE_FAIL:
            return {
                ...state,
                invite: authReducer([INVITE_COMPLETE, INVITE_COMPLETE_SUCCESS, INVITE_COMPLETE_FAIL])(state.invite, action),
            };
        default:
            return state;
    }
}

// Action creators
//
export function login({ email, password, token }) {
    const data = { email, password, token };
    return {
        types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
        promise: (client) => client.post('/api/auth/login', { data }),
        noAlert: true,
    };
}

export function signup({ email, password, token }) {
    const data = { email, password, token };
    return {
        types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
        promise: (client) => client.post('/api/auth/signup', { data }),
        noAlert: true,
    };
}

export function logout({ email }) {
    const data = { email };
    return {
        types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
        promise: (client) => client.post('/api/auth/logout', { data }),
    };
}

export function restoreStart({ email, token }) {
    const data = { email, token };
    return {
        types: [RESTORE_START, RESTORE_START_SUCCESS, RESTORE_START_FAIL],
        promise: (client) => client.post('/api/auth/restore-start', { data }),
        noAlert: true,
    };
}

export function restoreComplete({ email, code, password, token }) {
    const data = { email, code, password, token };
    return {
        types: [RESTORE_COMPLETE, RESTORE_COMPLETE_SUCCESS, RESTORE_COMPLETE_FAIL],
        promise: (client) => client.post('/api/auth/restore-complete', { data }),
        noAlert: true,
    };
}

export function inviteComplete({ email, password }) {
    const data = { email, password };
    return {
        types: [INVITE_COMPLETE, INVITE_COMPLETE_SUCCESS, INVITE_COMPLETE_FAIL],
        promise: (client) => client.post('/api/auth/invite-complete', { data }),
        noAlert: true,
    };
}

export default reducer;
