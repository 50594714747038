import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getProjectTitle } from "../../redux/modules/project-list";
import { setProjectTitle } from "../../redux/modules/project-details";
import EditableProjectTitle from "./EditableProjectTitle";


const mapStateToProps = (state, { projectId }) => {
    return {
        title: getProjectTitle(state, { projectId })
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        setProjectTitle: (title) => dispatch(setProjectTitle(projectId, title)),
    }
};

class EditableProjectTitleContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        isNew: PropTypes.bool.isRequired,
        // from connect
        title: PropTypes.string,
        setProjectTitle: PropTypes.func.isRequired,
    };

    state = {
        doneFirstTimeEditing: false,
    };

    handleTitleChange(title) {
        const { setProjectTitle, isNew } = this.props;

        setProjectTitle(title).then(({ error }) => {
            if (error) { return; }

            if (isNew) {
                this.setState({ doneFirstTimeEditing: true }); // remove '/new' on first change
            }
        });
    }

    render() {
        const { projectId, title, isNew } = this.props;
        const { doneFirstTimeEditing } = this.state;
        const onChange = (title) => this.handleTitleChange(title);

        if (!title) { return null; }
        if (doneFirstTimeEditing) { return <Redirect to={ `/projects/${projectId}` }/>; }

        return (
            <EditableProjectTitle value={ title }
                isNew={ isNew }
                onChange={ onChange }/>
        );
    }
}

EditableProjectTitleContainer = connect(mapStateToProps, mapDispatchToProps)(EditableProjectTitleContainer);

export default EditableProjectTitleContainer;
