import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Icon } from "../Elements";
import { reduxForm, Field, reset } from "redux-form";
import { InputGroup } from "../Form";
import { validateEmail } from "../../../common/util";


// Filed-level validation
const required = (value) => value ? undefined : '* required';
const isEmail = (value) => validateEmail(value) ? undefined : 'invalid email';
const notSelfEmail = (selfEmail, isOwner) => (value) => (
    selfEmail !== value ? undefined : `you are already the project ${ isOwner ? 'owner' : 'collaborator' }`
);
const notCollaboratorEmail = (collaborators) => (value) => {
    const found = collaborators.find(c => c.userId === value);
    return !found ? undefined : `${ found.userId } is already a${found.isOwner ? 'n owner' : ' collaborator' }`;
};
const validate = [
    required,
    isEmail,
];


const formConfig = {
    form: 'invite',
    fields: ['email'],
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetForm: () => dispatch(reset('invite')),
    }
};

class AddCollaboratorModal extends Component {

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        userEmail: PropTypes.string.isRequired,
        ownerEmail: PropTypes.string.isRequired,
        collaborators: PropTypes.array.isRequired,
        onCancel: PropTypes.func.isRequired,
        // from redux-form
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        // from connect
        resetForm: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps) {
        const justOpened = !prevProps.isOpen && this.props.isOpen;
        if (justOpened) {
            this.props.resetForm();
            setTimeout(() => this.setDefaultFocus(), 100); // after render
        }
    }

    setDefaultFocus() {
        const emailField = this._email && this._email.getRenderedComponent();
        if (emailField) {
            emailField.focus();
        }
    }

    _validateEmail = () => {
        const { userEmail, ownerEmail, collaborators } = this.props;

        // Workaround for `react-redux` bug: https://github.com/erikras/redux-form/issues/3211#issuecomment-316726499
        // Per comment, the same array instance must be used in each render(), but we have 2 validators depending on props.
        // thus we *mutate* array here.
        //
        validate
            .splice(2, 2, notSelfEmail(userEmail, userEmail === ownerEmail), notCollaboratorEmail(collaborators));

        return validate;
    };

    render() {
        const { isOpen, onCancel, handleSubmit, valid, dirty, submitting } = this.props;
        const disableSubmit = !dirty || !valid || submitting;
        const disabledClass = disableSubmit ? 'disabled' : '';
        const onSaveClick = () => handleSubmit();

        return (
            <Modal size="md" isOpen={ isOpen } toggle={ onCancel } className="modal-add">
                <ModalHeader toggle={ onCancel }>
                    <Icon icon="user-plus" classes="mb-2"/>
                    <span>New collaborator</span>
                </ModalHeader>
                <ModalBody>
                    { /*Workaround for a Modal error leading to `Target container is not a DOM element` err on second open*/ }
                    { isOpen && (
                        <Field type="email" name="email" component={ InputGroup }
                            validate={ this._validateEmail() }
                            label="Email address"
                            forwardRef={ true } ref={ (c) => this._email = c }
                        />
                    ) }
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" block
                        className={ disabledClass }
                        onClick={ onSaveClick }
                    >Save changes</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

AddCollaboratorModal = connect(null, mapDispatchToProps)(
    reduxForm(formConfig)(AddCollaboratorModal)
);

export default AddCollaboratorModal;
