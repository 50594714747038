import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { MainContent } from "../Layout";
import { SvgIcon, BackIcon } from "../Elements";
import ExclustionList from "./ExclusionList";
import ExclusionEditForm from "./ExclusionEditForm";
import "./ProjectPolicyInMemory.scss";


class ProjectPolicyInMemory extends Component {
    static propTypes = {
        exclusionList: PropTypes.array.isRequired,
        onCreateExclusionItem: PropTypes.func.isRequired,
        onUpdateExclusionItem: PropTypes.func.isRequired,
        onDeleteExclusionItem: PropTypes.func.isRequired,
    };

    state = {
        editedItem: undefined, // undefined = not edited, null = create new, {...} = edit existing
        isEditing: false,
        isNew: undefined,
    };

    toggleEdit(editedItem) {
        this.setState({
            editedItem,
            isEditing: editedItem !== undefined,
            isNew: editedItem === null,
        });
    }

    renderNav() {
        const { isEditing } = this.state;
        const cancelItemEdit = () => this.toggleEdit();

        if (!isEditing) {
            return (
                <Nav>
                    <NavItem>
                        <NavLink href="#">Exclusion List</NavLink>
                    </NavItem>
                </Nav>
            );
        }
        else {
            return (
                <Nav>
                    <NavItem>
                        <NavLink href="#" onClick={ cancelItemEdit }>
                            <BackIcon />
                            Back to Exclusion List
                        </NavLink>
                    </NavItem>
                </Nav>
            );
        }
    }


    handleSubmit = (data) => {
        const { onCreateExclusionItem, onUpdateExclusionItem } = this.props;
        const { isNew, editedItem } = this.state;

        this.toggleEdit();

        if (isNew) {
            onCreateExclusionItem(data)
        } else {
            const { _id:  exclusionId } = editedItem;
            onUpdateExclusionItem(exclusionId, data);
        }
    }

    render() {
        const { exclusionList, onDeleteExclusionItem } = this.props;
        const { isNew, editedItem, isEditing } = this.state;
        const startItemEdit = (item) => this.toggleEdit(item);
        const deleteItem = (exclusionId) => onDeleteExclusionItem(exclusionId);

        let exclusionContent;
        if (!isEditing) {
            exclusionContent = (
                <ExclustionList list={ exclusionList }
                    onEdit={ startItemEdit }
                    onDelete={ deleteItem }
                />
            );
        }
        else {
            exclusionContent = (
                <ExclusionEditForm
                    { ...(editedItem || {}) }
                    isNew={ isNew }
                    onSubmit={ this.handleSubmit }
                />
            );
        }

        return (
            <Col>
                <MainContent className="project-in-memory">
                    <Row>
                        {/*Icon*/}
                        <Col xs="12" lg={{ size: 3 }} className="icon-col">
                            <SvgIcon icon="policy-static" sz="lg"/>
                            <h2 className="d-block d-lg-none">In-Memory Validation</h2>
                        </Col>

                        {/*Content*/}
                        <Col xs="12" lg={{ size: 9 }} className="content-col">
                            {/*Navigation - TODO*/}
                            <Row>
                                <Col xs="12" className="mb-2">
                                    { this.renderNav() }
                                </Col>
                            </Row>
                            {/*Exclusion List & Edit*/}
                            <Row>
                                <Col xs="12">
                                    { exclusionContent }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </MainContent>
            </Col>
        );
    }
}

export default ProjectPolicyInMemory;
