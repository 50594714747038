const TOGGLE_SIDE_MENU = 'common/TOGGLE_SIDE_MENU';


function reducer(state = {
    sideMenuOpen: true,
}, action = {}) {
    switch (action.type) {
        case TOGGLE_SIDE_MENU:
            return {
                ...state,
                sideMenuOpen: !state.sideMenuOpen
            };
        default:
            return state;
    }
}

// Action creators
export function toggleSideMenu() {
    return {
        type: TOGGLE_SIDE_MENU,
    };
}

export default reducer;
