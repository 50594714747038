import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connectSocket, disconnectSocket } from '../../services/IoClient';
import { Page, PrivatePageLayout } from '../Layout'
import ProjectListContainer from '../ProjectList';
import Project404 from "../Project404";
import ProjectDetailsContainer from '../ProjectDetails';
import ProjectWhiteList from '../ProjectWhiteList';
import ProjectPolicyInMemoryContainer from '../ProjectPolicyInMemory';
import ProjectSettingsContainer from '../ProjectSettings';
import { AttackListView, AttackDetailsContainer } from '../Attacks';
import ActionCenterContainer from '../ActionCenter';
import ProjectPageContainer from "./ProjectPageContainer";


const redirect = (history) => ({ to, replace, search, state }) => {
    const method = replace ? 'replace' : 'push';
    history[method](to, state);
};
const ProjectListPage = ({ history }) => (
    <Page title="Projects">
        <ProjectListContainer redirect={ redirect(history) }/>
    </Page>
);
const Project404Page = ({ match: { params: { projectId } }, history }) => (
    <Page title="Projects">
        <Project404 projectId={ projectId } redirect={ redirect(history) }/>
    </Page>
);
const ProjectDetailsPage = ({ match: { params: { projectId } }, history }) => (
    <ProjectPageContainer key={ `prj-${ projectId }` } projectId={ projectId } redirect={ redirect(history) }>
        <ProjectDetailsContainer projectId={ projectId }/>
    </ProjectPageContainer>
);
const ProjectWLPolicyPage = ({ match: { params: { projectId } }, history }) => (
    <ProjectPageContainer projectId={ projectId } redirect={ redirect(history) } subTitle="Application Whitelisting">
        <ProjectWhiteList projectId={ projectId }/>
    </ProjectPageContainer>
);
const ProjectIMPolicyPage = ({ match: { params: { projectId } }, history }) => (
    <ProjectPageContainer projectId={ projectId } redirect={ redirect(history) } subTitle="In-Memory Validation">
        <ProjectPolicyInMemoryContainer projectId={ projectId }/>
    </ProjectPageContainer>
);
const ProjectSettingsPage = ({ match: { params: { projectId } }, history }) => (
    <ProjectPageContainer projectId={ projectId } redirect={ redirect(history) } subTitle="Settings">
        <ProjectSettingsContainer projectId={ projectId }/>
    </ProjectPageContainer>
);
const AttackListPage = ({ history, location }) => (
    <Page title="Attack Overview">
        <AttackListView redirect={ redirect(history) } location={ location }/>
    </Page>
);
const AttackDetailsPage = ({ match: { params: { incidentId } }, history }) => (
    <Page title="Attack Overview">
        <AttackDetailsContainer key={ incidentId } incidentId={ incidentId } redirect={ redirect(history) }/>
    </Page>
);
const ActionsPage = () => (
    <Page title="Actions">
        <ActionCenterContainer isPage/>
    </Page>
);


class PrivatePage extends Component {
    UNSAFE_componentWillMount() {
        connectSocket();
    }

    componentWillUnmount() {
        disconnectSocket();
    }

    render() {
        return (
            <PrivatePageLayout>
                <Switch>
                    <Route path="/projects/:projectId/not-found" component={ Project404Page }/>
                    <Route path="/projects/:projectId/whitelist" component={ ProjectWLPolicyPage }/>
                    <Route path="/projects/:projectId/in-memory" component={ ProjectIMPolicyPage }/>
                    <Route path="/projects/:projectId/settings" component={ ProjectSettingsPage }/>
                    <Route path="/projects/:projectId" component={ ProjectDetailsPage }/>
                    <Route path="/projects" component={ ProjectListPage }/>
                    <Route path="/attacks/:incidentId" component={ AttackDetailsPage }/>
                    <Route path="/attacks" component={ AttackListPage }/>
                    <Route path="/actions" component={ ActionsPage }/>
                </Switch>
            </PrivatePageLayout>
        );
    }
}

export default PrivatePage;

