import React, { Component } from "react";
import PropTypes from 'prop-types';
import { LayoutPrivate } from '../Layout';
import AlertContainer from "../Alert";


class InfoBarPrivate extends Component {

    static propTypes = {
        children: PropTypes.node,
        addonAfter: PropTypes.node,
    };

    handleClick(e) {
        if (e.ctrlKey && e.altKey) {
            // debug feature on ctrl+alt+click
            e.preventDefault();
            e.stopPropagation();
            this._alert.toggleTest();
        }
    }

    render() {
        const { children, addonAfter } = this.props;
        const onClick = (e) => this.handleClick(e);

        return (
            <LayoutPrivate className="info-bar-row" id="info-bar"
                addonAfter={ addonAfter }
                onClick={ onClick }
            >
                { children }
                <AlertContainer ref={ (c) => this._alert = c }/>
            </LayoutPrivate>
        );
    }
}

export default InfoBarPrivate;
