import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Layout from "../Layout";
import Header, { PageTitle, PageLogo } from "../../Header";
import InfoBar from "../../InfoBar";
import { isDev } from '../../../../config';
import './ErrorPageLayout.scss';


function ErrorPageLayout({ error }, context) {
    let htmlTitle = 'Error';
    let content = 'Sorry, a critical error occurred on this page.';
    let errorMessage = null;

    if (error.status === 404) {
        htmlTitle = 'Page Not Found';
        content = 'Sorry, the page you were trying to view does not exist.';
    } else if (isDev) {
        errorMessage = <pre>{error.stack}</pre>;
    }

    context.setTitle(htmlTitle);

    return (
        <Layout>
            <Header>
                <PageTitle title={ error.status }/>
                <PageLogo />
            </Header>

            <InfoBar/>

            <Row>
                <Col md={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }} className="error-content">
                    <h1>{htmlTitle}</h1>
                    <p>{ content }</p>
                    { errorMessage }
                    <p><Link to="/projects">Visit Projects</Link></p>
                </Col>
            </Row>
        </Layout>
    );
}

ErrorPageLayout.propTypes = { error: PropTypes.object.isRequired };
ErrorPageLayout.contextTypes = { setTitle: PropTypes.func.isRequired };

export default ErrorPageLayout;
