import React, { Component } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Row, Col, Card, CardTitle, CardText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { MainContent } from "../Layout";
import { CloseButton, Icon } from "../Elements";
import DeleteProjectModal from "./DeleteProjectModal";
import ProjectCollaboratorList from "./ProjectCollaboratorList";
import "./ProjectList.scss";


function getStateStr(stage) {
    switch (stage) {
        case 'Download':
            return 'Ready to Download';
        case 'Ready':
            return 'Ready to Build';
        case 'Sealed':
            return 'Sealed!';
        default:
            return `${stage}`;
    }
}

class ProjectList extends Component {

    static propTypes = {
        list: PropTypes.array.isRequired,
        userEmail: PropTypes.string.isRequired,
        onAdd: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    state = {
        deletingProject: null,
    };

    toggleDeleteModal(prjOrNull) {
        this.setState({ deletingProject: prjOrNull });
    }

    render() {
        const { list, userEmail, onRemove, onAdd } = this.props;
        const { deletingProject } = this.state;
        const emptyClass = (list.length === 0) ? 'empty-list' : '';
        const onClickAdd = () => onAdd();

        // Delete modal
        const openDeleteModal = (prj) => this.toggleDeleteModal(prj);
        const closeDeleteModal = () => this.toggleDeleteModal(null);
        const submitDeleteModal = (projectId) => {
            this.toggleDeleteModal(null);
            onRemove(projectId);
        };

        // Items
        const listItems = list.map(({ _id: projectId, title, stage, collaborators }, i) => {
            const isOwner = collaborators.find(c => c.isOwner).userId === userEmail;
            const classes = classNames({ 'project-item': true, 'no-delete': !isOwner });
            const closeTitle = isOwner ? 'Remove project' : 'Disallowed for non-owner';
            const onCloseClick = (e) => {
                e.preventDefault();
                isOwner && openDeleteModal({ projectId, title });
            };
            return (
                <Col key={ `pi-${i}` } xs="12" sm="6" lg="4">
                    <Link to={ `/projects/${ projectId }` }>
                        <Card className={ classes } body>
                            <CloseButton onClick={ onCloseClick } title={ closeTitle }/>
                            <CardTitle>{ title }</CardTitle>
                            <CardText>{ getStateStr(stage) }</CardText>
                            <ProjectCollaboratorList
                                collaborators={ collaborators }
                                userEmail={ userEmail }/>
                        </Card>
                    </Link>
                </Col>
            );
        });

        return (
            <Col className={ `project-list-container ${emptyClass}` }>
                <MainContent className="project-list">
                    <Row>
                        <Col xs="12" sm="6" lg="4">
                            <Card className="project-item hollow" body>
                                <CardTitle className="project-new-title">Add New<br/> Project</CardTitle>
                                <Button size="lg" color="danger" className="btn-round" onClick={ onClickAdd }>
                                    <Icon icon="plus"/>
                                </Button>
                            </Card>
                        </Col>

                        { listItems }
                    </Row>
                </MainContent>

                <Button size="lg" color="danger" className="btn-round hanging d-block d-sm-none" onClick={ onClickAdd }>
                    <Icon icon="plus"/>
                </Button>

                <DeleteProjectModal
                    deletingProject={ deletingProject }
                    onClose={ closeDeleteModal }
                    onSubmit={ submitDeleteModal }
                />
            </Col>
        );
    }
}

export default ProjectList;
