import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const PrivateRoute = ({ component: Component, user: { isAuthenticated }, ...rest }) => (
    <Route { ...rest } render={ props => (
        isAuthenticated ? (
            <Component { ...props }/>
        ) : (
            <Redirect to="/login"/>
        )
    ) }/>
);

const PrivateRouteContainer = connect(mapStateToProps)(PrivateRoute);
export default PrivateRouteContainer;
