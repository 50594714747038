import React, { Component } from "react";
import PropTypes from 'prop-types';


const decodeFromURL = (location) => {
    const { projects = 'all', dates = 'all', types = 'all' } = Object.fromEntries(
        new URLSearchParams(location.search)
    );
    return { projects, dates, types };
};

const encodeToURL = (filter) => {
    const urlSearchParams = new URLSearchParams();
    Object
        .keys(filter)
        .forEach(key => {
            if (filter[key] && filter[key] !== 'all') {
                urlSearchParams.append(key, filter[key]);
            }
        });
    return urlSearchParams.toString();
};

const withQueryFilter = (prefixUrl) => (WrappedComponent) => {
    return class _WithQueryFilter extends Component {
        static propTypes = {
            // from router
            redirect: PropTypes.func.isRequired,
            location: PropTypes.object.isRequired,
        };

        state = {
            filter: decodeFromURL(this.props.location),
        };

        componentDidUpdate(prevProps) {
            const { location } = this.props;
            const locationChanged = (location !== prevProps.location);
            if (locationChanged) {
                this.setState({
                    filter: decodeFromURL(location),
                });
            }
        }

        navToFilter = (filter) => {
            const { redirect } = this.props;
            const search = encodeToURL(filter);

            redirect({ to : `${ prefixUrl }?${ search }` });
        };

        render() {
            const { filter } = this.state;
            return (
                <WrappedComponent filter={ filter } setFilter={ this.navToFilter } { ...this.props }/>
            );
        }
    }
};


export default withQueryFilter;
