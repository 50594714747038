import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Icon, ProgressIcon } from '../../Elements';
import './AttackListHeader.scss';

const RefreshButton = (props) => (
    <Button size="sm" className="btn-refresh" title="Refresh" { ...props }>
        <Icon icon="repeat"/>
    </Button>
);

const AttackListHeader = ({ isLoading = false, onClickRefresh, children = null }) => {
    return (
        <div className="attack-list-header">
            { isLoading && (
                <ProgressIcon className="spinner" color="mute"/>
            ) }
            <RefreshButton
                disabled={ isLoading }
                onClick={ onClickRefresh }
            />
            { Children.map(children, (child) => (
                cloneElement(child, { isLoading })
            )) }
        </div>
    );
};

AttackListHeader.propTypes = {
    isLoading: PropTypes.bool,
    onClickRefresh: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default AttackListHeader;
