import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from "reactstrap";
import { Icon } from "../Elements";
import "./ProjectDownloadModal.scss";


class ProjectDownloadModal extends Component {

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        children: PropTypes.node.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const { isOpen, children, onClose } = this.props;

        return (
            <Modal size="md" isOpen={ isOpen } toggle={ onClose } className="modal-download">
                <ModalHeader toggle={ onClose }>
                    <Icon icon="arrow-circle-down"/>
                    <span>XGuard Installer Download</span>
                </ModalHeader>
                { children }
            </Modal>
        );
    }
}

export default ProjectDownloadModal;
