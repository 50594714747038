import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { restoreStart } from "../../redux/modules/auth";
import AuthRestoreStartForm from "./AuthRestoreStartForm";


const mapStateToProps = (state) => {
    return {
        error: state.auth.restoreStart.error,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        restoreStart: (data) => dispatch(restoreStart(data)),
    }
};

class AuthRestoreStartFormContainer extends Component {

    static propTypes = {
        redirect: PropTypes.func.isRequired,
        //from connect
        error: PropTypes.object,
        restoreStart: PropTypes.func.isRequired,
    };

    handleRestoreStart(data) {
        const { restoreStart, redirect } = this.props;
        const { email } = data;

        return restoreStart(data).then(({ error }) => {
            if (!error) {
                return redirect({ to: `/restore-proceed/${email}`, replace: true });
            }
        });
    }

    renderErrorMsg(error) {
        if (!error) { return ''; }


        const { error: errorStatus } = error;
        if (errorStatus === 'Not Found') { return 'Oops! That email address is not found.'; }
        return 'Oops! Something went wrong. Please try again later.'; // unknown error
    }

    render() {
        const { error } = this.props;
        const errorMsg = this.renderErrorMsg(error);
        const onSubmit = (data) => this.handleRestoreStart(data);

        return (
            <AuthRestoreStartForm
                errorMsg={ errorMsg }
                onSubmit={ onSubmit }
            />
        );
    }
}
AuthRestoreStartFormContainer = connect(mapStateToProps, mapDispatchToProps)(AuthRestoreStartFormContainer);

export default AuthRestoreStartFormContainer;
