import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input } from "reactstrap";
import { Icon } from "../Elements";


const selectLabel = `Select a new owner...`;

class TransferOwnershipModal extends Component {

    static propTypes = {
        userEmail: PropTypes.string.isRequired,
        ownerEmail: PropTypes.string.isRequired,
        collaborators: PropTypes.array.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        selectedEmail: undefined
    };

    componentDidUpdate(prevProps) {
        const justOpened = !prevProps.isOpen && this.props.isOpen;
        if (justOpened) {
            this.resetSelect();
        }
    }

    resetSelect() {
        this.setState({ selectedEmail: undefined });
    }

    handleSelect(value) {
        this.setState({ selectedEmail: value });
    }

    renderEnabledBody() {
        const { collaborators } = this.props;
        const optionEls = collaborators
            .filter(c => !c.isOwner)
            .map((c, i) => <option key={ `opt-${i}` }>{ c.userId }</option>);
        const onSelect = (e) => this.handleSelect(e.target.value);

        return (
            <FormGroup>
                <Input type="select" name="select" onChange={ onSelect }>
                    <option className="muted">{ selectLabel }</option>
                    <optgroup label="Collaborators">
                        { optionEls }
                    </optgroup>
                </Input>
            </FormGroup>
        );
    }

    renderDisabledBody() {
        const { ownerEmail } = this.props;

        return (
            <div>
                <h6>Transfer Ownership management not available</h6>
                <p>Only the owner, <b>{ ownerEmail }</b>, can transfer this project.</p>
            </div>
        );
    }

    render() {
        const { isOpen, ownerEmail, userEmail, onCancel, onSubmit } = this.props;
        const { selectedEmail } = this.state;
        const isProjectOwner = (ownerEmail === userEmail);
        const isDisabled = !selectedEmail || (selectedEmail === selectLabel);
        const disabledClass = isDisabled ? 'disabled' : '';
        const onSaveClick = () => !isDisabled && onSubmit(selectedEmail);

        let modalFooter = null;
        let modalBodyContent = null;
        if (isProjectOwner) {
            modalBodyContent = this.renderEnabledBody();
            modalFooter = (
                <ModalFooter>
                    <Button color="danger" block
                        className={ disabledClass }
                        onClick={ onSaveClick }>Transfer</Button>
                </ModalFooter>
            )
        } else {
            modalBodyContent = this.renderDisabledBody();
        }

        return (
            <Modal size="md" isOpen={ isOpen } toggle={ onCancel } className="modal-add">
                <ModalHeader toggle={ onCancel }>
                    <Icon icon="user" classes="mb-2"/>
                    <span>Transfer ownership</span>
                </ModalHeader>
                <ModalBody>
                    { modalBodyContent }
                </ModalBody>
                { modalFooter }
            </Modal>
        );
    }
}

export default TransferOwnershipModal;
