import React, { Component } from "react";


const withToggle = ({ show }) => (WrappedComponent) => {
    return class _WIthToggle extends Component {
        state = {
            show: show,
        };

        toggle = () => {
            this.setState({ show: !this.state.show });
        };

        render() {
            const { show } = this.state;
            return (
                <WrappedComponent show={ show } onToggle={ this.toggle } { ...this.props }/>
            );
        }
    }
};
export default withToggle;
