import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button } from "reactstrap";
import { Icon, ProgressIcon } from "../../Elements";
import { PackageLabel } from "../FileTree/FileTree";
import './FileDetails.scss';


const DetailItem = ({ title, value, className }) => {
    return (
        <div className="detail-item">
            <div className="detail-title text-mute">{ title }:</div>
            <div className={ cs('detail-value', className) }>{ value }</div>
        </div>
    )
};

const AllowedLabel = ({ isLoading, allowed }) => {
    if (isLoading) {
        return <ProgressIcon/>;
    }

    return (
        allowed
            ? <span className="text-success">Allowed</span>
            : <span className="text-danger">Blocked</span>
    );
};

export class DirDetails extends Component {
    static propTypes = {
        data: PropTypes.shape({
            path: PropTypes.string.isRequired,
            totalFiles: PropTypes.number.isRequired,
            allowedFiles: PropTypes.number.isRequired,
            files: PropTypes.array.isRequired,
        }),
        isLoading: PropTypes.bool.isRequired,
        onAllow: PropTypes.func.isRequired,
        onBlock: PropTypes.func.isRequired,
    };

    render() {
        const { data, isLoading, onAllow, onBlock } = this.props;
        const { path, totalFiles, allowedFiles, files } = data;
        const statsLabel = (
            <span> { `${allowedFiles} of ${ totalFiles } allowed` } { isLoading && <ProgressIcon className="d-inline"/> } </span>
        );
        const fileItems = files.map(({ path, packageId, allowed }, i) => {
            const packageLabel = packageId !== 'Default' ? <PackageLabel packageId={ packageId }/> : null;
            return (
                <div key={ `ln-${i}` } className={ cs({ 'text-mute': !allowed }) }>{ path } { packageLabel }</div>
            );
        });
        const allowDisabled = allowedFiles === totalFiles;
        const blockDisabled = allowedFiles === 0;

        return (
            <div className="file-details">
                <div className="file-details-data">
                    <h6 className="details-title"><Icon icon="fw folder-open-o" classes="node-icon"/> Directory</h6>
                    <DetailItem title="Path" value={ path }/>
                    <DetailItem title="Stats" value={ statsLabel }/>
                    <DetailItem title="Files" value={ fileItems } className="with-list"/>
                </div>
                <div className="file-details-actions">
                    <Button color="success" disabled={ allowDisabled } onClick={ onAllow }>Allow All</Button>
                    <Button color="danger" disabled={ blockDisabled } onClick={ onBlock }>Block All</Button>
                </div>
            </div>
        );
    }
}


class FileDetails extends Component {

    static propTypes = {
        data: PropTypes.shape({
            allowed: PropTypes.bool.isRequired,
            path: PropTypes.string.isRequired,
            hash: PropTypes.string.isRequired,
            packageId: PropTypes.string.isRequired,
            twins: PropTypes.array.isRequired,
        }),
        isLoading: PropTypes.bool.isRequired,
        onAllow: PropTypes.func.isRequired,
        onBlock: PropTypes.func.isRequired,
    };

    render() {
        const { data, isLoading, onAllow, onBlock } = this.props;
        const { allowed, path, hash, packageId, twins } = data;
        const statusValue = <AllowedLabel isLoading={ isLoading } allowed={ allowed }/>;
        const packageValue = <PackageLabel packageId={ packageId }/>;
        const twinItems = twins.map((it, i) => (
            <div key={ `ln-${i}` } className={ cs('twins-item', { selected: it === path, 'text-mute': !allowed }) }>{ it }</div>
        ));

        return (
            <div className="file-details">
                <div className="file-details-data">
                    <h5 className="details-title"><Icon icon="fw file-text-o" classes="node-icon"/> Executable file</h5>
                    <DetailItem title="Status" value={ statusValue }/>
                    <DetailItem title="Path" value={ path } className={ cs({ 'text-mute': !allowed }) }/>
                    <DetailItem title="Package" value={ packageValue }/>
                    <DetailItem title="Hash" value={ hash } className="font-80p"/>
                    { twins.length > 0 && <DetailItem title="Identical files" value={ twinItems } className="with-list"/> }
                </div>

                <div className="file-details-actions">
                    <Button color="success" disabled={ allowed } onClick={ onAllow }>Allow</Button>
                    <Button color="danger" disabled={ !allowed } onClick={ onBlock }>Block</Button>
                </div>
            </div>
        );
    }
}

export default FileDetails;
