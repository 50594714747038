import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { CloseButton } from '../Elements';
import './Alert.scss';


class Alert extends Component {

    static propTypes = {
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        message: PropTypes.any.isRequired, // may be string or element
        onClose: PropTypes.func,
    };

    render() {
        const { type, title, message, onClose } = this.props;
        const onCloseClick = (e) => {
            e.stopPropagation();
            onClose();
        };

        return (
            <div className={ cs('alert', type, { 'alert-dismissible': !!onClose }) } role="alert">
                { onClose && <CloseButton onClick={ onCloseClick }/> }
                <strong>{ title } </strong>
                <span>{ message }</span>
            </div>
        );
    }
}

export default Alert;
