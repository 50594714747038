import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from "./Loader";


function loaderHOC(displayName, mapStateToProps) {
    class LoaderContainer extends Component {
        static displayName = displayName;

        static propTypes = {
            // from connect
            isLoading: PropTypes.bool.isRequired,
        };

        render() {
            const { isLoading, ...rest } = this.props;

            return isLoading && <Loader show={isLoading} {...rest} />;
        }
    }

    return connect(mapStateToProps)(LoaderContainer);
}

export const AuthLoaderContainer = loaderHOC('AuthLoaderContainer', (state) => {
    const { login, signup } = state.auth;
    return {
        isLoading: !!(login.loading || signup.loading)
    };
});
