import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Form, Button } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defaultMemoize as memoize } from "reselect";
import { EXCLUSION_TYPE_BASENAME, EXCLUSION_TYPE_GLOB } from "../../../common/constants";
import { validate, InputGroup, TextareaGroup } from "../Form";
import "./ExclusionEditForm.scss";


function isBasename(str) {
    return !/[/\\]/.test(str); // no slashes
}

// From https://github.com/jonschlinkert/is-glob/blob/master/index.js
function isGlob(str) {
    const regex = /(\\).|([*?]|\[.*\]|\{.*\}|\(.*\|.*\)|^!)/;
    let match;

    // eslint-disable-next-line
    while ((match = regex.exec(str))) {
        if (match[2]) return true;
        str = str.slice(match.index + match[0].length);
    }
    return false;
}

const detectTypeByTarget = memoize(
    (target) => {
        if (isGlob(target)) { return EXCLUSION_TYPE_GLOB; }
        if (isBasename(target)) { return EXCLUSION_TYPE_BASENAME; }
        return undefined;
    }
);

const FORM_NAME = 'editExclusion';
const selector = formValueSelector(FORM_NAME);
const formConfig = {
    form: FORM_NAME,
    fields: ['target', 'description'],
    validate: validate([
        {
            target: (val, { target }) => {
                if (!val) { return '* required'; }
                if (!detectTypeByTarget(target)) { return '* invalid'; }
                return '';
            }
        },
    ]),
};
const mapStateToProps = (state, ownProps) => {
    const { target, description = '' } = ownProps;

    return {
        initialValues: { target, description },
        currentTarget: selector(state, 'target'),
    };
};


class ExclusionEditForm extends Component {
    static propTypes = {
        target: PropTypes.string,
        description: PropTypes.string,
        isNew: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        // from redux-form
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        //from redux
        currentTarget: PropTypes.string,
    };

    componentDidMount() {
        this.setFocus();
    }

    componentDidUpdate(prevProps) {
        const newError = !prevProps.errorMsg && !!this.props.errorMsg;
        if (newError) {
            this.reset();
        }
    }

    setFocus() {
        const targetField = this._target.getRenderedComponent();
        targetField.focus();
    }

    renderHelpText(type) {
        const { currentTarget: target } = this.props;

        const invalid = (
            <span>
                <i>Hint</i>:
                    type <a href="//en.wikipedia.org/wiki/basename" target="_blank" rel="noopener noreferrer"><code>basename</code></a> or
                    a valid <a href="//en.wikipedia.org/wiki/Glob_(programming)" target="_blank" rel="noopener noreferrer"><code>glob pattern</code></a>.<br/>
                <i>Examples</i>: <code>{ 'prog' }</code>, <code>{ '/bin/*.name' }</code>
            </span>
        );
        const validBasename = (
            <span>
                <i>Hint</i>: will match any program named <code>{ target }</code>, on any path.<br/>
                <i>Examples</i>: <code>{ `/bin/${target}` }</code>, <code>{ `/usr/local/my/${target}` }</code>
            </span>
        );
        const validGlob = (
            <span>
                <i>Hint</i>: will match specified <a href="//en.wikipedia.org/wiki/Glob_(programming)" target="_blank" rel="noopener noreferrer"><code>glob pattern</code></a>.<br/>
                <br/>
            </span>
        );

        if (!target) { return invalid; }
        if (type === EXCLUSION_TYPE_BASENAME) { return validBasename; }
        if (type === EXCLUSION_TYPE_GLOB) { return validGlob; }
        return invalid;
    }

    render() {
        const { handleSubmit, onSubmit, valid, currentTarget, isNew } = this.props;
        const type = detectTypeByTarget(currentTarget);
        const buttonLabel = isNew ? "Add Exclusion" : "Save";

        return (
            <Form onSubmit={ handleSubmit(data => onSubmit({ ...data, type})) } className="exclusion-form clearfix">
                <Field name="target" component={ InputGroup }
                    type="text"
                    label="Target"
                    helpText={ this.renderHelpText(type) }
                    forwardRef={ true } ref={ (c) => this._target = c }
                />
                <Field name="description" component={ TextareaGroup }
                    label="Description"
                    placeholder="Add some descriptive text"
                />
                <Button color="danger mt-3 pull-right" disabled={ !valid }>{ buttonLabel }</Button>
            </Form>
        );
    }
}

ExclusionEditForm = connect(mapStateToProps)(
    reduxForm(formConfig)(
        ExclusionEditForm
    )
);

export default ExclusionEditForm;
