import React from "react";
import classNames from "classnames";


function ProjectCollaboratorList({ collaborators, userEmail }) {
    const maxDisplay = 13; // others don't feet
    const items = collaborators
        .slice(0, maxDisplay - 1)
        .map(({ userId: email, isOwner }, i) => {
            const label = email[0].toUpperCase();
            const isSelf = (email === userEmail);
            const classes = classNames({ 'collaborator-item': true, owner: isOwner, self: isSelf });
            const titlePrefix = isOwner && isSelf ? 'Owner (me): ' : isOwner ? 'Owner: ' :
                isSelf ? 'Collaborator (me): ' : 'Collaborator: ';
            return (
                <div key={`col-${i}`} className={ classes } title={ titlePrefix + email }>
                    <div>{ label }</div>
                </div>
            )
        });
    const dots = collaborators.length > maxDisplay ? '…' : '';

    return (
        <div className="collaborator-list">{ items }{ dots }</div>
    )
}


export default ProjectCollaboratorList;
