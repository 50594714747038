import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InboxIcon } from "../Elements";


class AuthRestoreProceed extends Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        error: PropTypes.string,
    };

    render() {
        const { email, error } = this.props;
        const errorMsg = !!error && (
            <div className="critical-error">Oops! There is something wrong with this link.
                Please make sure you copy the entire link from the email in order to proceed with password reset.
                <br/>
                <em>Note:</em> If more than 20 minutes has passed since we sent an email message about resetting your password, please
                request a reset again.
            </div>
        );

        return (
            <div className="verify">
                { errorMsg }
                <h4 className="mb-3 text-muted">
                    Check your inbox to restore
                </h4>

                <p>We've sent an email message to <span className="text-muted">{ email }</span>. Check your inbox for a message about
                    restoring access.</p>
                <p>Follow the link provided in the message to reset your password.</p>

                <p className="text-center">
                    <InboxIcon/>
                </p>

                <p className="form-text text-muted">
                    <Link to="/login">Back to login</Link>
                </p>
            </div>
        );
    }
}

export default AuthRestoreProceed;

