import React, { Component } from 'react';
import cs from "classnames";
import PropTypes from 'prop-types';
import { Tooltip } from "reactstrap";
import { Icon } from "./Icon";


class TooltipPlus extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        render: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
        placement: PropTypes.string,
    };

    state = {
        tooltipOpen: false,
    };

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        const { id, render, children, placement = 'left', ...rest } = this.props;
        const { tooltipOpen } = this.state;
        const tid = `tooltip-${id}`;

        return (
            <div { ...rest }>
                <span id={ tid } data-tooltip>{ render() }</span>
                <Tooltip
                    placement={ placement }
                    isOpen={ tooltipOpen }
                    target={ tid }
                    toggle={ () => this.toggle() }
                    autohide={ false }
                    delay={ 0 }
                >
                    { children }
                </Tooltip>
            </div>
        );
    }
}

export const TextWithTooltip = ({ id, children, ...rest }) => (
    <TooltipPlus
        id={ id }
        render={ () => children }
        { ...rest }
    >
        { children }
    </TooltipPlus>
);

export const IconWithTooltip = ({ id, icon, children, ...rest }) => (
    <TooltipPlus
        id={ id }
        render={ () => <Icon icon={ icon }/> }
        { ...rest }
    >
        { children }
    </TooltipPlus>
);

export const Hint = ({ id, children, icon = "question-circle", placement = "top", className = null }) => (
    <IconWithTooltip
        id={ id }
        className={cs("d-inline hint-icon", className)}
        placement={ placement }
        icon={ icon }
    >{ children }</IconWithTooltip>
);
