import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import rootReducer from "./modules";
import createApiMiddleware from "./middleware/apiMiddleware";
import { isDev } from "../../config";


const loggerMiddleware = createLogger({
    collapsed: (getState, action) => {
        return true; // true = collapse all
    },
    predicate: (getState, action) => !/redux-form/.test(action.type), // mute redux-form - too verbose
});


const configureStore = (client, enableLogger = isDev) => {
    const apiMiddleware = createApiMiddleware(client);
    const middleware = [
        apiMiddleware,
        ...(enableLogger ? [loggerMiddleware] : [])
    ];

    return createStore(
        rootReducer,
        applyMiddleware.apply(null, middleware)
    );
};

export default configureStore;
