import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchPathDetails, allowBlockPath } from '../../../redux/modules/project-policy-tree';
import { ProgressIcon } from "../../Elements";
import FileDetails, { DirDetails } from "./FileDetails";


const mapStateToProps = (state, { projectId }) => {
    const projectTree = (state.project.tree[projectId] || {});
    return {
        selectedNode: projectTree.selectedNode,
        details: projectTree.details || {},
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        fetchPathDetails: ({ nodeId, kind }) => dispatch(fetchPathDetails(projectId, nodeId, kind)),
        allowBlockPath: ({ nodeId, kind }, op) => dispatch(allowBlockPath(projectId, nodeId, kind, op)),
    };
};


class FileDetailsContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        // from connect
        selectedNode: PropTypes.object,
        details: PropTypes.object.isRequired,
        fetchPathDetails: PropTypes.func.isRequired,
        allowBlockPath: PropTypes.func.isRequired,
    };

    _timeout = null;

    componentDidMount() {
        const { selectedNode, fetchPathDetails } = this.props;

        if (selectedNode) {
            fetchPathDetails(selectedNode);
        }
    }

    componentDidUpdate(prevProps) {
        const { selectedNode, fetchPathDetails } = this.props;
        const { nodeId } = selectedNode || {};
        const { nodeId: prevNodeId } = prevProps.selectedNode || {};

        if (nodeId && nodeId !== prevNodeId) {
            if (!prevNodeId) {
                fetchPathDetails(selectedNode); // first load
            } else {
                this.runDelayed(() => fetchPathDetails(selectedNode)); // delay to reduce API calls on tree browsing with keyboard
            }
        }
    }

    runDelayed (fn) {
        clearTimeout(this._timeout);
        this._timeout = setTimeout(fn, 150);
    }

    render() {
        const { selectedNode, details: { loading: isLoading, data }, allowBlockPath } = this.props;
        const isEmpty = !selectedNode;
        const isReady = selectedNode && !!data;

        if (isEmpty) {
            return null;
        }
        if (!isReady) {
            return <ProgressIcon className="p-3"/>;
        }

        const { _t: type } = data;
        const isFile = type === 'file';
        const onAllowPath = () => allowBlockPath(selectedNode, 'allow');
        const onBlockPath = () => allowBlockPath(selectedNode, 'block');

        if (isFile) {
            return (
                <FileDetails data={ data }
                    isLoading={ isLoading }
                    onAllow={ onAllowPath }
                    onBlock={ onBlockPath }
                />
            );
        }
        else {
            return (
                <DirDetails data={ data }
                    isLoading={ isLoading }
                    onAllow={ onAllowPath }
                    onBlock={ onBlockPath }
                />
            );
        }
    }
}

FileDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(FileDetailsContainer);

export default FileDetailsContainer;
