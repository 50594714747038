import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import ProjectTitleContainer, { EditableProjectTitleContainer } from "../ProjectTitle";


export const PageTitle = ({ children }) => (
    <NavbarBrand tag="div" className="page-title">
        { children }
    </NavbarBrand>
);


const PageTitleRouter = () => (
    <Switch>
        <Route exact path="/projects" render={ () => <PageTitle>Projects</PageTitle> }/>
        <Route exact path="/projects/:projectId/whitelist" render={ ({ match: { params: { projectId } } }) =>
            <PageTitle>
                <ProjectTitleContainer projectId={ projectId } subTitle="Application Whitelisting"/>
            </PageTitle>
        }/>
        <Route exact path="/projects/:projectId/in-memory" render={ ({ match: { params: { projectId } } }) =>
            <PageTitle>
                <ProjectTitleContainer projectId={ projectId } subTitle="In-Memory Validation"/>
            </PageTitle>
        }/>
        <Route exact path="/projects/:projectId/settings" render={ ({ match: { params: { projectId } } }) =>
            <PageTitle>
                <ProjectTitleContainer projectId={ projectId } subTitle="Settings"/>
            </PageTitle>
        }/>
        <Route path="/projects/:projectId/not-found" render={ () =>
            <PageTitle>
                <div><span className="sub-title">Not Found</span></div>
            </PageTitle>
        }/>
        <Route path="/projects/:projectId/:isNew?" render={ ({ match: { params: { projectId, isNew } } }) =>
            <PageTitle>
                <EditableProjectTitleContainer key={ `p-${projectId}-${isNew}` } projectId={ projectId } isNew={ isNew === 'new' }/>
            </PageTitle>
        }/>
        <Route exact path="/attacks" render={ () => <PageTitle>Cyber Attacks Overview</PageTitle> }/>
        <Route exact path="/attacks/:incidentId" render={ () => <PageTitle>Attack Details</PageTitle> }/>
        <Route path="/actions" render={ () => <PageTitle>Actions</PageTitle> }/>
    </Switch>
);

export default PageTitleRouter;
