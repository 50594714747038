import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleSideMenu } from '../../redux/modules/common';
import { fetchProjectList } from '../../redux/modules/project-list';
import { getUnreadActionsNum } from '../../redux/modules/actions';
import { getUserIncidentFiltersForMenu } from '../../redux/modules/user';
import { onSyncUpMessage } from '../../services/IoClient';
import SideMenu from "./SideMenu";

const mapStateToProps = (state, { location }) => {
    return {
        isOpen: state.common.sideMenuOpen,
        projects: state.project.list.data,
        incidentFilters: getUserIncidentFiltersForMenu(state, { location }),
        unreadActionsNum: getUnreadActionsNum(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggleSideMenu: () => dispatch(toggleSideMenu()),
        fetchProjectList: () => dispatch(fetchProjectList()),
    }
};

class SideMenuContainer extends Component {

    static propTypes = {
        // from withRouter
        location: PropTypes.object.isRequired,
        // from connect
        isOpen: PropTypes.bool.isRequired,
        projects: PropTypes.array.isRequired,
        incidentFilters: PropTypes.array.isRequired,
        unreadActionsNum: PropTypes.number.isRequired,
        toggleSideMenu: PropTypes.func.isRequired,
        fetchProjectList: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { fetchProjectList } = this.props;

        fetchProjectList();

        this._unsubscribe = onSyncUpMessage(`projects`, () => {
            fetchProjectList();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    render() {
        const { isOpen, location, projects = [], incidentFilters, unreadActionsNum, toggleSideMenu } = this.props;

        return (
            <SideMenu
                pathname={ location.pathname }
                isOpen={ isOpen }
                projects={ projects }
                incidentFilters={ incidentFilters }
                hasUnreadActions={ unreadActionsNum > 0 }
                toggle={ toggleSideMenu }
            />
        );
    }
}

SideMenuContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SideMenuContainer)
);

export default SideMenuContainer;
