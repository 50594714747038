import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { onSyncUpMessage } from "../../services/IoClient";
import {
    fetchExclusionList,
    createExclusionItem,
    updateExclusionItem,
    deleteExclusionItem
} from '../../redux/modules/project-policy-exclusions';
import ProjectPolicyInMemory from './ProjectPolicyInMemory';


const mapStateToProps = (state, { projectId }) => {
    return {
        exclusions: state.project.exclusions[projectId] || {},
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        fetchExclusionList: () => dispatch(fetchExclusionList(projectId)),
        createExclusionItem: (data) => dispatch(createExclusionItem(projectId, data)),
        updateExclusionItem: (exclusionId, data) => dispatch(updateExclusionItem(projectId, exclusionId, data)),
        deleteExclusionItem: (exclusionId) => dispatch(deleteExclusionItem(projectId, exclusionId)),
    }
};


class ProjectPolicyInMemoryContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        // from connect
        exclusions: PropTypes.object.isRequired,
        fetchExclusionList: PropTypes.func.isRequired,
        createExclusionItem: PropTypes.func.isRequired,
        updateExclusionItem: PropTypes.func.isRequired,
        deleteExclusionItem: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { projectId, fetchExclusionList } = this.props;

        fetchExclusionList();

        this._unsubscribe = onSyncUpMessage(`projects/${projectId}/in-memory`, () => {
            fetchExclusionList();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    render() {
        const {
            exclusions: { list },
            createExclusionItem,
            updateExclusionItem,
            deleteExclusionItem,
        } = this.props;
        const onCreateExclusionItem = (data) => createExclusionItem(data);
        const onUpdateExclusionItem = (exclusionId, data) => updateExclusionItem(exclusionId, data);
        const onDeleteExclusionItem = (exclusionId) => deleteExclusionItem(exclusionId);

        if (!list) { return null; }

        return (
            <ProjectPolicyInMemory
                exclusionList={ list }
                onCreateExclusionItem={ onCreateExclusionItem }
                onUpdateExclusionItem={ onUpdateExclusionItem }
                onDeleteExclusionItem={ onDeleteExclusionItem }
            />
        );
    }
}

ProjectPolicyInMemoryContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectPolicyInMemoryContainer);

export default ProjectPolicyInMemoryContainer;
