import React from "react";
import { Row, Col } from "reactstrap";
import Layout from "../Layout";
import { AuthLoaderContainer } from "../../Loader";
import Header, { PageTitle, PageLogo } from "../../Header";
import InfoBar, { InfoBarLinkRouter } from "../../InfoBar";
import "./AuthPageLayout.scss";
import packageInfo from "../../../../../package.json";


const AuthPageLayout = ({ children }) => (
    <Layout>
        <Header>
            <PageTitle>Welcome to Karamba</PageTitle>
            <PageLogo/>
        </Header>

        <InfoBar>
            <InfoBarLinkRouter/>
        </InfoBar>

        <Row className="auth-content">
            <Col sm={ { size: 8 } } md={ { size: 6 } } lg={ { size: 5 } } xl={ { size: 4 } } className="auth-form-col">
                <AuthLoaderContainer color="pink"/>
                { children }

                <div className="version">v{ packageInfo.version }</div>
            </Col>
        </Row>
    </Layout>
);

export default AuthPageLayout;
