import { connect } from "react-redux";
import { toggleActionCenter, getUnreadActionsNum } from '../../redux/modules/actions';
import ActionCenterBar from "./ActionCenterBar";


const mapStateToProps = (state) => {
    return {
        isOpen: state.actions.center.visible,
        totalActionsNum: state.actions.events.list.length,
        unreadActionsNum: getUnreadActionsNum(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggle: (show) => dispatch(toggleActionCenter(show)),
    };
};
const ActionCenterBarContainer = connect(mapStateToProps, mapDispatchToProps)(ActionCenterBar);

export default ActionCenterBarContainer;
