import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, reset } from "redux-form";
import { validate, InputGroup } from "../Form";
import { Link } from 'react-router-dom';
import { Captcha } from "../Form";


const formConfig = {
    form: 'restore2',
    fields: ['password', 'token'],
    validate: validate([
        { password: '* required' },
        { password: (s = '') => s.length < 6 && 'at least 6 chars' },
    ]),
};
const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: {
            email: ownProps.email
        }
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetForm: () => dispatch(reset('restore2')),
    }
};


class AuthRestoreCompleteForm extends Component {

    static propTypes = {
        email: PropTypes.string,
        errorMsg: PropTypes.string,
        // from redux-form
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        // from connect
        resetForm: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.setDefaultFocus();
    }

    componentDidUpdate(prevProps) {
        const newError = !prevProps.errorMsg && !!this.props.errorMsg;
        if (newError) {
            this.reset();
        }
    }

    setDefaultFocus() {
        const passwordField = this._password.getRenderedComponent();
        passwordField.focus();
    }

    reset() {
        this.props.resetForm();
        setTimeout(() => this.setDefaultFocus(), 0);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (Captcha.enabled) {
            this._captcha.getRenderedComponent().execute();
        } else {
            this.props.handleSubmit();
        }
    }

    handleCaptchaChange() {
        setTimeout(() => this.props.handleSubmit(), 0);
        this._captcha.getRenderedComponent().reset();
    }

    render() {
        const { email, errorMsg, valid, dirty, submitting } = this.props;
        const errorClass = (errorMsg && !dirty) ? 'visible' : '';
        const disableSubmit = !dirty || !valid || submitting;
        const onSubmit = (e) => this.handleSubmit(e);
        const onCaptchaChange = () => this.handleCaptchaChange();

        return (
            <form className="clearfix" onSubmit={ onSubmit }>
                <p>Please provide a new password below, and click Restore to restore access to your account.</p>

                <FormGroup>
                    <Label for="email" className="text-muted">Email</Label>
                    <Input type="email" id="email" name="email" value={ email } valid disabled/>
                </FormGroup>

                <Field type="password" name="password" component={ InputGroup }
                    label="New password"
                    placeholder="Password"
                    forwardRef={ true } ref={ (c) => this._password = c }
                />

                { Captcha.enabled && (
                    <Field name="token" component={ Captcha }
                        forwardRef={ true } ref={ (c) => this._captcha = c }
                        onChange={ onCaptchaChange }
                    />
                )}

                <small className={ `form-text text-danger server-error ${errorClass}` }>
                    { errorMsg }&nbsp;
                </small>

                <Button type="submit" block color="danger" size="lg" disabled={ disableSubmit }>Restore</Button>

                <p className="form-text text-muted">
                    <Link to="/login">Back to login</Link>
                </p>
            </form>
        );
    }
}
AuthRestoreCompleteForm = connect(mapStateToProps, mapDispatchToProps)(
    reduxForm(formConfig)(AuthRestoreCompleteForm)
);

export default AuthRestoreCompleteForm;
