// timeAgo - utility function that formats dates to 'x minutes|seconds|etc ago' labels
//
const epochs = [
    ['year', 31536000],
    ['month', 2592000],
    ['day', 86400],
    ['hour', 3600],
    ['minute', 60],
    ['second', 1]
];
const getDuration = (timeAgoInSeconds) => {
    for (let [epoch, seconds] of epochs) {
        const interval = Math.floor(timeAgoInSeconds / seconds);

        if (interval >= 1) {
            return { interval, epoch };
        }
    }
};
export const timeAgo = (date) => {
    const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
    const { interval, epoch } = getDuration(timeAgoInSeconds) || {};
    const suffix = interval === 1 ? '' : 's';

    return interval ? `${interval} ${epoch}${suffix} ago` : 'Just now';
};

// validateEmail - email validation with simplified RegEx
//
export const validateEmail = (value) => {
    const simpleEmailRe = /\S+@\S+\.\S+/;

    return simpleEmailRe.test(value);
};

// normalizeEmail - trim & lowercase
//
export const normalizeEmail = (email) => String(email).trim().toLowerCase();

// sanitizeStr - replace secrets with **** and show only last 4 chars
//
export const sanitizeStr = str => '****' + String(str).slice(-4);

// Convert arbitrary string into stable HSL color.
// Adopted from https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
//
export function stringToHSL(str, { sat = 100, level = 40 } = {}) {
    const hash = str
        .split('')
        .map(ch => ch.charCodeAt(0))
        .reduce((hash, code) => {
            hash = code + ((hash << 5) - hash);
            return hash & hash; // convert to 32 bit int
        }, 0);
    const hue = hash % 360;

    return `hsl(${ hue },${ sat }%,${ level }%)`;
}
