import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, reset } from "redux-form";
import { validate, InputGroup } from "../Form";
import { validateEmail } from "../../../common/util";
import { Link } from 'react-router-dom';
import { Captcha } from "../Form";


const formConfig = {
    form: 'signup',
    fields: ['email', 'password', 'token'],
    validate: validate([
        { email: '* required' },
        { email: (s = '') => !validateEmail(s) && 'invalid email' },
        { password: '* required' },
        {
            password: (p = '', { password2 = '' }) => (
                (p.length < 6) ?
                    'at least 6 chars' :
                    (password2 === '') ? 'please repeat this password below' :
                        (p !== password2) ? 'passwords do not match' : false
            )
        },
        { password2: '* required' },
        {
            password2: (p2 = '', { password = '' }) => (
                (p2.length < 6) ? 'at least 6 chars' :
                    (password === '') ? 'please repeat this password above' :
                        (p2 !== password) ? 'passwords do not match' : false
            )
        },
    ]),
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetForm: () => dispatch(reset('signup')),
    }
};


class AuthSignupForm extends Component {

    static propTypes = {
        errorMsg: PropTypes.any,
        // from redux-form
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        //from connect
        resetForm: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.setDefaultFocus();
    }

    componentDidUpdate(prevProps) {
        const newError = !prevProps.errorMsg && !!this.props.errorMsg;
        if (newError) {
            this.reset();
        }
    }

    setDefaultFocus() {
        const emailField = this._email.getRenderedComponent();
        const passwordField = this._password.getRenderedComponent();

        if (!emailField.props.input.value) {
            emailField.focus();
        } else {
            passwordField.focus();
        }
    }

    reset() {
        this.props.resetForm();
        setTimeout(() => this.setDefaultFocus(), 0);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (Captcha.enabled) {
            this._captcha.getRenderedComponent().execute();
        } else {
            this.props.handleSubmit();
        }
    }

    handleCaptchaChange() {
        setTimeout(() => this.props.handleSubmit(), 0);
        this._captcha.getRenderedComponent().reset();
    }

    render() {
        const { errorMsg, valid, dirty, submitting } = this.props;
        const errorClass = (errorMsg && !dirty) ? 'visible' : '';
        const disableSubmit = !dirty || !valid || submitting;
        const onSubmit = (e) => this.handleSubmit(e);
        const onCaptchaChange = () => this.handleCaptchaChange();

        return (
            <form className="clearfix" onSubmit={ onSubmit }>
                <Field type="email" name="email" component={ InputGroup }
                    label="Email"
                    placeholder="Email"
                    forwardRef={ true } ref={ (c) => this._email = c }
                />
                <Field type="password" name="password" component={ InputGroup }
                    label="Password"
                    placeholder="Password"
                    forwardRef={ true } ref={ (c) => this._password = c }
                />
                <Field type="password" name="password2" component={ InputGroup }
                    label="Repeat password"
                    placeholder="Password"
                />

                { Captcha.enabled && (
                    <Field name="token" component={ Captcha }
                        forwardRef={ true } ref={ (c) => this._captcha = c }
                        onChange={ onCaptchaChange }
                    />
                ) }

                <small className={ `form-text text-danger server-error ${errorClass}` }>
                    { errorMsg }&nbsp;
                </small>

                <Button type="submit" block color="danger" size="lg" disabled={ disableSubmit }>Create</Button>

                <p className="form-text text-muted">
                    Have an account? <Link to="/login">Log in</Link>
                </p>
            </form>
        );
    }
}

AuthSignupForm = connect(null, mapDispatchToProps)(
    reduxForm(formConfig)(AuthSignupForm)
);

export default AuthSignupForm;

