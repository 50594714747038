import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, reset } from "redux-form";
import { validate, InputGroup } from "../Form";
import { validateEmail } from "../../../common/util";
import { Link } from 'react-router-dom';
import { Captcha } from "../Form";


const formConfig = {
    form: 'restore',
    fields: ['email', 'token'],
    validate: validate([
        { email: '* required' },
        { email: (s = '') => !validateEmail(s) && 'invalid email' },
    ]),
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetForm: () => dispatch(reset('restore')),
    }
};

class AuthRestoreStartForm extends Component {

    static propTypes = {
        errorMsg: PropTypes.string,
        // from redux-form
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        // from connect
        resetForm: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.setDefaultFocus();
    }

    componentDidUpdate(prevProps) {
        const newError = !prevProps.errorMsg && !!this.props.errorMsg;
        if (newError) {
            this.reset();
        }
    }

    setDefaultFocus() {
        const emailField = this._email.getRenderedComponent();
        emailField.focus();
    }

    reset() {
        this.props.resetForm();
        setTimeout(() => this.setDefaultFocus(), 0);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (Captcha.enabled) {
            this._captcha.getRenderedComponent().execute();
        } else {
            this.props.handleSubmit();
        }
    }

    handleCaptchaChange() {
        setTimeout(() => this.props.handleSubmit(), 0);
        this._captcha.getRenderedComponent().reset();
    }

    render() {
        const { errorMsg, valid, dirty, submitting } = this.props;
        const errorClass = (errorMsg && !dirty) ? 'visible' : '';
        const disableSubmit = !dirty || !valid || submitting;
        const onSubmit = (e) => this.handleSubmit(e);
        const onCaptchaChange = () => this.handleCaptchaChange();

        return (
            <form className="clearfix" onSubmit={ onSubmit }>
                <Field type="email" name="email" component={ InputGroup }
                    label="Email"
                    placeholder="Email"
                    forwardRef={ true } ref={ (c) => this._email = c }
                />

                { Captcha.enabled && (
                    <Field name="token" component={ Captcha }
                        forwardRef={ true } ref={ (c) => this._captcha = c }
                        onChange={ onCaptchaChange }
                    />
                )}

                <small className={ `form-text text-danger server-error ${errorClass}` }>
                    { errorMsg }&nbsp;
                </small>

                <Button type="submit" block color="danger" size="lg" disabled={ disableSubmit }>Send</Button>

                <p className="form-text text-muted">
                    <Link to="/login">Back to login</Link>
                </p>
            </form>
        );
    }
}
AuthRestoreStartForm = connect(null, mapDispatchToProps)(
    reduxForm(formConfig)(AuthRestoreStartForm)
);

export default AuthRestoreStartForm;
