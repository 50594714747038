import { combineReducers } from 'redux';
import list from './project-list';
import details from './project-details';
import installer from './project-installer';
import tree from './project-policy-tree';
import exclusions from './project-policy-exclusions';


export default combineReducers({
    list,
    details,
    installer,
    tree,
    exclusions,
});
