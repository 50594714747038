import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import {
    canSaveCurrentIncidentFilter,
    canRemoveCurrentIncidentFilter,
    addUserIncidentFilter,
    removeUserIncidentFilter,
} from '../../../redux/modules/user';
import SaveFilterModal from './SaveFilterModal';

const mapStateToProps = (state, { location }) => {
    return {
        userIncidentFilters: state.user.incidentFilters || [],
        canSaveCurrentFilter: canSaveCurrentIncidentFilter(state, { location }),
        canRemoveCurrentFilter: canRemoveCurrentIncidentFilter(state, { location }),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addUserIncidentFilter: (filter) => dispatch(addUserIncidentFilter(filter)),
        removeUserIncidentFilter: (filterId) => dispatch(removeUserIncidentFilter(filterId)),
    };
};

class AttackListFilterSaveRemoveContainer extends Component {
    static propTypes = {
        filterLabel: PropTypes.string.isRequired,
        // from withRouter
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        // from connect
        userIncidentFilters: PropTypes.array.isRequired,
        canSaveCurrentFilter: PropTypes.bool.isRequired,
        canRemoveCurrentFilter: PropTypes.bool.isRequired,
        addUserIncidentFilter: PropTypes.func.isRequired,
        removeUserIncidentFilter: PropTypes.func.isRequired,
    };

    state = {
        isSaveFilterModalOpen: false,
    };

    toggleSaveFilterModal = () => {
        this.setState({
            isSaveFilterModalOpen: !this.state.isSaveFilterModalOpen,
        });
    };

    render() {
        const {
            filterLabel,
            history,
            location,
            userIncidentFilters,
            canSaveCurrentFilter,
            canRemoveCurrentFilter,
            addUserIncidentFilter,
            removeUserIncidentFilter,
        } = this.props;
        const { isSaveFilterModalOpen } = this.state;
        const onClickRemove = () => {
            const userFilter = userIncidentFilters.find(({ search }) => location.search === search);
            if (userFilter) {
                removeUserIncidentFilter(userFilter._id).then(() => {
                    history.push('/attacks');
                });
            }
        };
        const onSubmitSaveFilterModal = ({ label }) => {
            this.toggleSaveFilterModal();
            addUserIncidentFilter({ label, search: location.search });
        };

        return (
            <div className="attack-filter-add-remove">
                { canSaveCurrentFilter && (
                    <NavLink href="#" onClick={ this.toggleSaveFilterModal } title="Save this filter to keep it in left-side Menu">Save</NavLink>
                ) }
                { canRemoveCurrentFilter && (
                    <NavLink href="#" onClick={ onClickRemove } title="Remove this filter from left-side Menu">Remove</NavLink>
                ) }

                <SaveFilterModal
                    isOpen={ isSaveFilterModalOpen }
                    initLabel={ filterLabel }
                    onCancel={ this.toggleSaveFilterModal }
                    onSubmit={ onSubmitSaveFilterModal }
                />
            </div>
        );
    }
}

AttackListFilterSaveRemoveContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AttackListFilterSaveRemoveContainer),
);

export default AttackListFilterSaveRemoveContainer;
