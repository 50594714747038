import {
    RESET,
    LIST_FETCH_PAGE,
    LIST_FETCH_PAGE_SUCCESS,
    LIST_FETCH_PAGE_FAIL,
} from './incident-list';

const initialState = {
    requestCount: 0,
    errorCount: 0,
    receivedStubsCount: 0,
    receivedStubsDiff: 0,
    receivedBytesCount: 0,
    receivedBytesDiff: 0,
    pendingRequests: [],
};

// Reducer
//
function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case RESET:
            return {
                ...initialState,
            };
        case LIST_FETCH_PAGE:
            return {
                ...state,
                requestCount: state.requestCount + 1,
                pendingRequests: [
                    ...state.pendingRequests,
                    `GET /api/incidents?${ action.queryString }`,
                ],
            };
        case LIST_FETCH_PAGE_SUCCESS: {
            const requestIdx = state.pendingRequests.indexOf(`GET /api/incidents?${ action.queryString }`);
            const payloadSize = JSON.stringify(action.result).length;
            const stubsCount = action.result.stubs.length;
            return {
                ...state,
                receivedStubsCount: state.receivedStubsCount + stubsCount,
                receivedStubsDiff: stubsCount,
                receivedBytesCount: state.receivedBytesCount + payloadSize,
                receivedBytesDiff: payloadSize,
                pendingRequests: [
                    ...state.pendingRequests.slice(0, requestIdx),
                    ...state.pendingRequests.slice(requestIdx + 1),
                ],
            };
        }
        case LIST_FETCH_PAGE_FAIL: {
            const requestIdx = state.pendingRequests.indexOf(`GET /api/incidents?${ action.queryString }`);
            return {
                ...state,
                errorCount: state.errorCount + 1,
                pendingRequests: [
                    ...state.pendingRequests.slice(0, requestIdx),
                    ...state.pendingRequests.slice(requestIdx + 1),
                ],
            };
        }
        default:
            return state;
    }
}

export default reducer;
