import React, { Component } from "react";
import PropTypes from 'prop-types';
import { captchaSiteKey } from "../../../config";
import ReCAPTCHA from 'react-google-recaptcha';


// Options: https://developers.google.com/recaptcha/docs/invisible#render_param

class Captcha extends Component {
    static propTypes = {
        // from Field
        input: PropTypes.object.isRequired,
    };

    static enabled = !!captchaSiteKey;

    execute() {
        // This should be called on user action (form submit) to let reCAPTCHA script call home.
        // This may or may not show UI to user, but anyway response from server produces a token to be verified on server.
        // After the call, onChange is triggered => then form data with token is posted to server.
        this._captcha.execute();
    }

    reset() {
        // Need to call reset() after execute(), otherwise repeated call (e.g. if user provided wrong form data)
        // does not lead to onChange callback call by ReCAPTCHA and it blocks repetitive form submit
        this._captcha.reset();
    }

    render() {
        const { onChange } = this.props.input;

        return (
            <div>
                <ReCAPTCHA
                    ref={ c => this._captcha = c }
                    size="invisible"
                    badge="bottomleft"
                    sitekey={ captchaSiteKey }
                    onChange={ onChange }
                />
            </div>
        );
    }
}

export default Captcha;
