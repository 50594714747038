import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { restoreComplete } from "../../redux/modules/auth";
import { updateUser } from "../../redux/modules/user";
import AuthRestoreCompleteForm from "./AuthRestoreCompleteForm";


const mapStateToProps = (state) => {
    return {
        error: state.auth.restoreComplete.error,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        restoreComplete: (data) => dispatch(restoreComplete(data)),
        updateUser: (user) => dispatch(updateUser(user)),
    }
};


class AuthRestoreCompleteFormContainer extends Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        redirect: PropTypes.func.isRequired,
        //from connect
        error: PropTypes.object,
        restoreComplete: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
    };

    handleRestoreComplete(data) {
        const { restoreComplete, updateUser, redirect } = this.props;

        restoreComplete(data).then(({ error, result: user }) => {
            if (!error) {
                updateUser({ isAuthenticated: true, ...user });
                redirect({ to: '/projects' });
            }

            // TODO: redirect('/restore-start?err') if session expired
        });
    }

    renderErrorMsg(error) {
        if (!error) { return ''; }

        const { error: errorStatus } = error;
        if (errorStatus === 'Unauthorized') { return 'Oops! Seems you provided incorrect code. Please copy/paste the code from email.'; }
        if (errorStatus === 'Not Found') { return 'Oops! Seems you email restore session is expired. Please repeat the process.'; }
        return 'Oops! Something went wrong. Please try again later.'; // unknown error
    }

    render() {
        const { email, error } = this.props;
        const errorMsg = this.renderErrorMsg(error);
        const onSubmit = (data) => this.handleRestoreComplete(data);

        return (
            <AuthRestoreCompleteForm
                email={ email }
                errorMsg={ errorMsg }
                onSubmit={ onSubmit }
            />
        );
    }
}
AuthRestoreCompleteFormContainer = connect(mapStateToProps, mapDispatchToProps)(AuthRestoreCompleteFormContainer);

export default AuthRestoreCompleteFormContainer;
