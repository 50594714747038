import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Row } from "reactstrap";
import Layout from "../Layout";
import Header, { MenuTogglerContainer, PageTitleRouter, UserBarContainer } from "../../Header";
import { InfoBarPrivate, InfoBarLinkRouter } from "../../InfoBar";
import SideMenuContainer from "../../SideMenu";
import ActionCenterContainer, { ActionCenterBarContainer } from '../../ActionCenter';
import PerfBarContainer from "../../PerfBar";
import "./PrivatePageLayout.scss";


class PrivatePageLayout extends Component {

    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Layout>
                <PerfBarContainer/>

                <Header>
                    <MenuTogglerContainer/>
                    <PageTitleRouter/>
                    <UserBarContainer/>
                </Header>

                <InfoBarPrivate addonAfter={ <ActionCenterBarContainer/> }>
                    <InfoBarLinkRouter/>
                </InfoBarPrivate>

                <SideMenuContainer/>
                <ActionCenterContainer/>

                <Row className="private-content">
                    { children }
                </Row>
            </Layout>
        );
    }
}

export default PrivatePageLayout;
