import React, { Component } from "react";
import cs from "classnames";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Col, Row, Card, CardTitle } from "reactstrap";
import { BackIcon, Hint, ProgressIcon } from '../../Elements';
import { action2icon, action2text, type2text, type2icon, formatTimeLong } from "../incident-utils";
import "./AttackDetails.scss";

class AttackDetails extends Component {

    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        projectTitle: PropTypes.string,
        incident: PropTypes.shape({
            action: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            diagnostics: PropTypes.string,
            time: PropTypes.string.isRequired,
            creationDate: PropTypes.string.isRequired,
            projectId: PropTypes.string.isRequired,
        }),
    };

    render() {
        const {
            isLoading,
            projectTitle = '?',
            incident: {
                projectId,
                action,
                type,
                severity,
                description,
                diagnostics,
                diagnosticsObj,
                time,
                timeEnd,
                aggregatedCount,
                creationDate,
                deviceId,
                deviceIPAddress,
                customData,
            },
        }  = this.props;
        const actionStr = action2text(action);
        const typeStr = type2text(type);
        const typeIcon = type2icon(type);
        const serverTimeStr = creationDate ? formatTimeLong(creationDate) : 'N/A';
        const ecuTimeStr = formatTimeLong(time);
        const ecuTimeEndStr = formatTimeLong(timeEnd);
        const showCount = aggregatedCount > 1;

        return (
            <Col>
                {/*Summary*/}
                <Row className="attack-details-summary">
                    <Col xs="12" lg="4" className="summary-col">
                        <div className="summary-row-title">
                            <Link to="/attacks" className="back-icon-link d-block d-sm-none">
                                <BackIcon />
                            </Link>

                            { isLoading ? (
                                <ProgressIcon className="d-inline spinner" color="mute"/>
                            ) : (
                                <img src={ action2icon(action) } className="summary-icon" alt=""/>
                            )}
                            <h5 className="summary-title">
                                <span className="summary-label">Action:</span>
                                <span className="summary-value">{ actionStr }</span>
                            </h5>
                        </div>
                    </Col>
                    <Col xs="12" lg="4" className="summary-col">
                        <div className="summary-row border-top">
                            <span className="summary-label">ECU time{ showCount ? ' (first)' : ''}:</span>
                            <span className="summary-value" title={ ecuTimeStr }>{ ecuTimeStr }</span>
                        </div>
                        { showCount && (
                            <>
                                <div className="summary-row">
                                    <span className="summary-label">ECU time (last):</span>
                                    <span className="summary-value" title={ ecuTimeEndStr }>{ ecuTimeEndStr }</span>
                                </div>
                                <div className="summary-row">
                                    <span className="summary-label">Count:</span>
                                    <span className="summary-value" >{ aggregatedCount }</span>
                                    <Hint id="count-hint" className="ml-2">Number of incidents aggregated into one during period</Hint>
                                </div>
                            </>
                        )}
                    </Col>
                    <Col xs="12" lg="4" className="summary-col">
                        <div className={cs("summary-row", {'border-top': showCount })}>
                            <span className="summary-label">Server time:</span>
                            <span className="summary-value" title={ serverTimeStr }>{ serverTimeStr }</span>
                        </div>
                        <div className="summary-row border-bottom">
                            <span className="summary-label">Type:</span>
                            <span className="summary-value" title={ typeStr }>{ typeStr }</span>
                        </div>
                    </Col>
                </Row>

                {/*Card*/}
                <Row>
                    <Col>
                        <Card className="attack-details-card">
                            <CardTitle>
                                <img src={ typeIcon } className="card-icon" alt=""/>Details
                            </CardTitle>

                            <div className="card-list">
                                <div className="card-row">
                                    <div className="card-label">Project:</div>
                                    <div className="card-value">
                                        <Link to={ `/projects/${ projectId }` }>{ projectTitle }</Link>
                                    </div>
                                </div>
                                <div className="card-row">
                                    <div className="card-label">Device ID:</div>
                                    <div className="card-value">{ deviceId || 'N/A' }</div>
                                </div>
                                <div className="card-row">
                                    <div className="card-label">Device IP address:</div>
                                    <div className="card-value">{ deviceIPAddress || 'N/A' }</div>
                                </div>
                                <div className="card-row">
                                    <div className="card-label">Severity:</div>
                                    <div className="card-value">{ severity || 'N/A' }</div>
                                </div>
                                <div className="card-row">
                                    <div className="card-label">Action:</div>
                                    <div className="card-value">{ actionStr }</div>
                                </div>
                                <div className="card-row">
                                    <div className="card-label">Incident Type:</div>
                                    <div className="card-value">{ typeStr }</div>
                                </div>
                                <div className="card-row">
                                    <div className="card-label">Details:</div>
                                    <div className="card-value">{ description }</div>
                                </div>
                                { diagnostics && (
                                    <div className="card-row flex-wrap">
                                        <div className="card-label">Diagnostics:</div>
                                        <pre className="card-value-block">
                                            <code>{ diagnostics }</code>
                                        </pre>
                                    </div>
                                )}
                                { diagnosticsObj && (
                                    <div className="card-row flex-wrap">
                                        <div className="card-label">Diagnostics:</div>
                                        <pre className="card-value-block">
                                            <code>{ JSON.stringify(diagnosticsObj, null, 2) }</code>
                                        </pre>
                                    </div>
                                )}
                                { customData && (
                                    <div className="card-row flex-wrap">
                                        <div className="card-label">Custom data:</div>
                                        <pre className="card-value-block">
                                            <code>{ customData }</code>
                                        </pre>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Col>
        );
    }
}

export default AttackDetails;
