import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Table } from "reactstrap";
import { Icon } from "../Elements";
import "./CollaboratorList.scss";


class CollaboratorList extends Component {

    static propTypes = {
        list: PropTypes.array.isRequired,
        userEmail: PropTypes.string.isRequired,
        onDeleteItem: PropTypes.func.isRequired,
    };

    render() {
        const { list, userEmail, onDeleteItem } = this.props;

        const rows = list.map((item, i) => {
            const { userId: email, isOwner } = item;
            const role = isOwner ? 'owner' : 'collaborator';
            const isRemovable = !isOwner;
            const deleteClass = isRemovable ? '' : 'disabled';
            const isSelf = email === userEmail;
            const selfNote = isSelf ? <span className="self-note">(me)</span>: '';
            const onDeleteClick = () => isRemovable && onDeleteItem(email);

            const removeLink = (
                <span title="Remove collaborator"><Icon icon="user-times"/></span>
            );

            return (
                <tr key={ `er-${i}` }>
                    <td className="td-email" title={ email }>{ email }</td>
                    <td className="td-role">{ role } { selfNote }</td>
                    <td className={ `td-delete ${deleteClass}` } onClick={ onDeleteClick }>
                        { isRemovable && removeLink }
                    </td>
                </tr>
            )
        });

        return (
            <Table className="collaborator-table">
                <thead>
                <tr>
                    <th className="td-email">Email</th>
                    <th>Role</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                { rows }
                </tbody>
            </Table>
        );
    }
}

export default CollaboratorList;
