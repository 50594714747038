import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchIncidentDetails, getProjectTitleById } from '../../../redux/modules/incident-details';
import { onSyncUpMessageByReason } from '../../../services/IoClient';
import { Row, Col } from 'reactstrap';
import { MainContent } from '../../Layout';
import AttackDetails from './AttackDetails';

const mapStateToProps = (state, { incidentId }) => {
    return {
        incident: state.incidentDetails[incidentId],
        projectTitle: getProjectTitleById(state, { incidentId }),
    };
};
const mapDispatchToProps = (dispatch, { incidentId }) => {
    return {
        fetchIncidentDetails: () => dispatch(fetchIncidentDetails(incidentId)),
    };
};

class AttackDetailsContainer extends Component {

    static propTypes = {
        incidentId: PropTypes.string.isRequired,
        // from router
        redirect: PropTypes.func.isRequired,
        // from connect
        incident: PropTypes.object,
        projectTitle: PropTypes.string,
        fetchIncidentDetails: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { fetchIncidentDetails, redirect } = this.props;
        const navigateBackToList = () => redirect({ to: '/attacks' });

        fetchIncidentDetails();

        this._unsubscribe = onSyncUpMessageByReason('IncidentsRemoved/all', () => {
            navigateBackToList();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    render() {
        const { incident, projectTitle } = this.props;
        const { loading, data } = incident || {};

        if (!incident || !data) {
            return null;
        }

        return (
            <Col>
                <MainContent>
                    <Row>
                        <AttackDetails
                            isLoading={ loading }
                            incident={ data }
                            projectTitle={ projectTitle }
                        />
                    </Row>
                </MainContent>
            </Col>
        );
    }
}

AttackDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(AttackDetailsContainer);

export default AttackDetailsContainer;
