import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import common from './common';
import auth from './auth';
import alert from './alert';
import user from './user';
import project from './project-index';
import actions from './actions';
import incidentList from './incident-list';
import incidentListStats from './incident-list-stats';
import incidentDetails from './incident-details';


export default combineReducers({
    form,
    common,
    auth,
    alert,
    user,
    project,
    actions,
    incidentList,
    incidentListStats,
    incidentDetails,
});
