import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '../../redux/create';
import ApiClient from '../../services/ApiClient';
import '../bootstrap.scss'; // required before components ensures it will be correctly overridden by components .scss
import Routes from '../Routes';
import './App.scss';


const client = ApiClient();
const store = configureStore(client);

const App = () => (
    <Provider store={ store }>
        <Routes/>
    </Provider>
);

export default App;
