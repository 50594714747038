import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InboxIcon } from "../Elements";


class AuthSignupVerify extends Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        error: PropTypes.string,
    };

    render() {
        const { email, error } = this.props;
        const errorMsg = !!error && (
                <div className="critical-error">Oops! There is something wrong with this link.
                    Please make sure you copy the entire link from the email in order to proceed with sign up.
                </div>
            );

        return (
            <div className="verify">
                { errorMsg }
                <h4 className="mb-3 text-muted">
                    Check your inbox to verify your email
                </h4>

                <p>We've sent an email message to <span className="text-muted">{ email }</span>.</p>
                <p>Follow the instructions in the message to verify your email address.</p>

                <p className="text-center">
                    <InboxIcon/>
                </p>

                <p className="form-text text-muted">
                    <Link to="/login">Back to login</Link>
                </p>
            </div>
        );
    }
}

export default AuthSignupVerify;

