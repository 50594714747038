const EXCLUSIONS_FETCH = 'exclusions/FETCH';
const EXCLUSIONS_FETCH_SUCCESS = 'exclusions/FETCH_SUCCESS';
const EXCLUSIONS_FETCH_FAIL = 'exclusions/FETCH_FAIL';

const EXCLUSIONS_CREATE = 'exclusions/CREATE';
const EXCLUSIONS_CREATE_SUCCESS = 'exclusions/CREATE_SUCCESS';
const EXCLUSIONS_CREATE_FAIL = 'exclusions/CREATE_FAIL';

const EXCLUSIONS_UPDATE = 'exclusions/UPDATE';
const EXCLUSIONS_UPDATE_SUCCESS = 'exclusions/UPDATE_SUCCESS';
const EXCLUSIONS_UPDATE_FAIL = 'exclusions/UPDATE_FAIL';

const EXCLUSIONS_DELETE = 'exclusions/DELETE';
const EXCLUSIONS_DELETE_SUCCESS = 'exclusions/DELETE_SUCCESS';
const EXCLUSIONS_DELETE_FAIL = 'exclusions/DELETE_FAIL';

// Reducers
//
function projectExclusionsReducer(state = {
    list: undefined,
    loading: false,
    error: undefined
}, action = {}) {
    switch (action.type) {
        case EXCLUSIONS_FETCH:
        case EXCLUSIONS_CREATE:
        case EXCLUSIONS_UPDATE:
        case EXCLUSIONS_DELETE:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case EXCLUSIONS_FETCH_SUCCESS:
            return {
                list: action.result,
                loading: false,
                error: undefined
            };
        case EXCLUSIONS_CREATE_SUCCESS:
            return {
                list: [
                    ...state.list,
                    action.result, // append
                ],
                loading: false,
                error: undefined
            };
        case EXCLUSIONS_UPDATE_SUCCESS:
            return {
                list: listReplaceItem(state.list, action.result),
                loading: false,
                error: undefined
            };
        case EXCLUSIONS_DELETE_SUCCESS:
            return {
                list: listDeleteItem(state.list, action.result),
                loading: false,
                error: undefined
            };
        case EXCLUSIONS_FETCH_FAIL:
        case EXCLUSIONS_CREATE_FAIL:
        case EXCLUSIONS_UPDATE_FAIL:
        case EXCLUSIONS_DELETE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

function reducer(state = {}, action = {}) {
    switch (action.type) {
        case EXCLUSIONS_FETCH:
        case EXCLUSIONS_FETCH_SUCCESS:
        case EXCLUSIONS_FETCH_FAIL:
        case EXCLUSIONS_CREATE:
        case EXCLUSIONS_CREATE_SUCCESS:
        case EXCLUSIONS_CREATE_FAIL:
        case EXCLUSIONS_UPDATE:
        case EXCLUSIONS_UPDATE_SUCCESS:
        case EXCLUSIONS_UPDATE_FAIL:
        case EXCLUSIONS_DELETE:
        case EXCLUSIONS_DELETE_SUCCESS:
        case EXCLUSIONS_DELETE_FAIL:
            return {
                ...state,
                [action.projectId]: projectExclusionsReducer(state[action.projectId], action)
            };
        default:
            return state;
    }
}


//Util
function listReplaceItem(list, item) {
    const oldItem = list.find(({ _id }) => _id === item._id);
    const oldIdx = list.indexOf(oldItem);

    if (oldIdx > -1) {
        return [
            ...list.slice(0, oldIdx),
            item,
            ...list.slice(oldIdx + 1)
        ];
    }

    return list;
}

function listDeleteItem(list, item) {
    const oldItem = list.find(({ _id }) => _id === item._id);
    const oldIdx = list.indexOf(oldItem);

    if (oldIdx > -1) {
        return [
            ...list.slice(0, oldIdx),
            ...list.slice(oldIdx + 1)
        ];
    }

    return list;
}

// Action creators
//
export function fetchExclusionList(projectId) {
    return {
        types: [EXCLUSIONS_FETCH, EXCLUSIONS_FETCH_SUCCESS, EXCLUSIONS_FETCH_FAIL],
        promise: (client) => client.get(`/api/projects/${ projectId }/in-memory/exclusions`),
        projectId,
        noAlert: 404,
    };
}

export function createExclusionItem(projectId, data) {
    return {
        types: [EXCLUSIONS_CREATE, EXCLUSIONS_CREATE_SUCCESS, EXCLUSIONS_CREATE_FAIL],
        promise: (client) => client.post(`/api/projects/${ projectId }/in-memory/exclusions`, { data }),
        projectId,
    };
}

export function updateExclusionItem(projectId, exclusionId, data) {
    return {
        types: [EXCLUSIONS_UPDATE, EXCLUSIONS_UPDATE_SUCCESS, EXCLUSIONS_UPDATE_FAIL],
        promise: (client) => client.put(`/api/projects/${ projectId }/in-memory/exclusions/${ exclusionId }`, { data }),
        projectId,
    };
}

export function deleteExclusionItem(projectId, exclusionId) {
    return {
        types: [EXCLUSIONS_DELETE, EXCLUSIONS_DELETE_SUCCESS, EXCLUSIONS_DELETE_FAIL],
        promise: (client) => client.del(`/api/projects/${ projectId }/in-memory/exclusions/${ exclusionId }`),
        projectId,
    };
}

export default reducer;
