import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { signup } from "../../redux/modules/auth";
import { updateUser } from "../../redux/modules/user";
import AuthSignupForm from "./AuthSignupForm";


const mapStateToProps = (state) => {
    return {
        error: state.auth.signup.error,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        signup: (data) => dispatch(signup(data)),
        updateUser: (user) => dispatch(updateUser(user)),
    }
};


class AuthSignupFormContainer extends Component {

    static propTypes = {
        redirect: PropTypes.func.isRequired,
        //from connect
        error: PropTypes.object,
        signup: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
    };

    handleSignup(data) {
        const { signup, updateUser, redirect } = this.props;

        signup(data).then(({ error, result: user }) => {
            if (!error) {
                updateUser({ isAuthenticated: true, ...user });
                return redirect({ to: '/projects' });
            }

            const { error: errorStatus, email } = error;
            if (errorStatus === 'Email Not Approved') {
                redirect({ to: `/approve/${email}?e=pending` });
            }
            if (errorStatus === 'Email Not Verified') {
                redirect({ to: `/verify/${email}` });
            }
        });
    }

    renderErrorMsg(error) {
        if (!error) { return ''; }

        const { error: errorStatus } = error;

        if (errorStatus === 'Email Not Approved') { return ''; }
        if (errorStatus === 'Email Not Verified') { return ''; }
        if (errorStatus === 'Conflict') { return 'Oops! This email address is used already.'; }
        return 'Oops! Something went wrong. Please try again later.'; // unknown error
    }

    render() {
        const { error } = this.props;
        const errorMsg = this.renderErrorMsg(error);
        const onSubmit = (data) => this.handleSignup(data);

        return <AuthSignupForm errorMsg={ errorMsg } onSubmit={ onSubmit }/>;
    }
}

AuthSignupFormContainer = connect(mapStateToProps, mapDispatchToProps)(AuthSignupFormContainer);

export default AuthSignupFormContainer;
