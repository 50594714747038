import { createSelector } from "reselect";

const LIST_FETCH = 'installer/LIST_FETCH';
const LIST_FETCH_SUCCESS = 'installer/LIST_FETCH_SUCCESS';
const LIST_FETCH_FAIL = 'installer/LIST_FETCH_FAIL';
const SELECT_PACKAGE = 'installer/SELECT_PACKAGE';

// Helper
//
const findDefaultPackageName = (list) => list.find(it => it.isDefault).packageName;

// Reducers
//
function reducer(state = {
    selectedPackageName: undefined,
    list: {
        data: undefined,
        error: undefined,
        loading: false,
    },
}, action = {}) {
    switch (action.type) {
        case LIST_FETCH:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                    error: undefined,
                },
            };
        case LIST_FETCH_SUCCESS:
            return {
                selectedPackageName: findDefaultPackageName(action.result.result),
                list: {
                    data: action.result.result,
                    loading: false,
                    error: undefined,
                },
            };
        case LIST_FETCH_FAIL:
            return {
                ...state,
                list: {
                    data: undefined,
                    loading: false,
                    error: action.error,
                },
            };
        case SELECT_PACKAGE:
            return {
                ...state,
                selectedPackageName: action.packageName,
            };
        default:
            return state;
    }
}


// Action creators
//
export function fetchProjectInstallerList(projectId) {
    return {
        types: [LIST_FETCH, LIST_FETCH_SUCCESS, LIST_FETCH_FAIL],
        promise: (client) => client.get(`/api/projects/${ projectId }/agent-install/list`),
        noAlert: true,
    };
}

export function selectPackage(packageName) {
    return {
        type: SELECT_PACKAGE,
        packageName,
    };
}

// Selectors
//
const getPackageList = (state) => state.project.installer.list.data;
const getSelectedPackageName = (state) => state.project.installer.selectedPackageName;

export const getSelectedPackage = createSelector(
    [getPackageList, getSelectedPackageName],
    (list = [], selectedPackageName) => (
        list.find(it => it.packageName === selectedPackageName)
    )
);

export default reducer;
