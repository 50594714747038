import React, { Component } from 'react';
import PropTypes from 'prop-types';


class EditForm extends Component {
    static propTypes = {
        initTitle: PropTypes.string.isRequired,
        isNew: PropTypes.bool.isRequired,
        toggle: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        value: this.props.initTitle,
        isValid: !!this.props.initTitle,
    };

    componentDidMount() {
        this.setDefaultFocus();
        document.addEventListener('keydown', this.handleEscape);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscape);
    }

    handleEscape = ({ keyCode }) => {
        if (keyCode === 27) {
            this.props.toggle();
        }
    }

    setDefaultFocus() {
        this._input.focus();

        if (this.props.isNew) {
            this._input.select();
        }
    }

    handleChange(value) {
        this.setState({
            value,
            isValid: !!value,
        });
    }

    handleSubmit(e) {
        const { initTitle, onSubmit, toggle } = this.props;
        const { value, isValid } = this.state;

        if (isValid && value !== initTitle) {
            onSubmit(value);
        }
        toggle();
    }

    render() {
        const { value, isValid } = this.state;
        const errorClass = !isValid ? 'is-invalid' : '';
        const onChange = (e) => this.handleChange(e.target.value);
        const onSubmit = (e) => {
            e.preventDefault();
            this.handleSubmit();
        };
        const onBlur = () => this.handleSubmit();

        return (
            <form onSubmit={ onSubmit } className="form-inline title-form">
                <div className={ `form-group ${errorClass}` }>
                    { !isValid && <small className="invalid-feedback">required</small> }
                    <div className="input-group">
                        <input type="text" className="form-control"
                            maxLength="100"
                            value={ value }
                            onChange={ onChange }
                            onBlur={ onBlur }
                            ref={ (c) => this._input = c }
                        />
                    </div>
                </div>
            </form>
        );
    }
}


export default EditForm;
