import React from "react";
import classNames from "classnames";
import "./Loader.scss";


const Loader = ({ show = false, color }) => {
    const classes = classNames(
        'loader',
        `color-${color}`,
        /*{ show }*/
    );

    return (
        <div className={ classes }/>
    );
};

export default Loader;
