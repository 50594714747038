import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SvgIcon } from '../../Elements';

const DeleteAttacksModal = ({ isOpen, onClose, onSubmit }) => {
    return (
        <Modal size="md" isOpen={ isOpen } toggle={ onClose } className="modal-delete">
            <ModalHeader toggle={ onClose }>
                <SvgIcon icon="danger" sz="xl"/>
                <span>Clear the entire list?</span>
            </ModalHeader>
            <ModalBody>
                <em>Warning</em>: This operation deletes all Cyber Attack entries, not only those selected for display.
                Cleared entries cannot be recovered!
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={ onSubmit } block>Clear All Entries</Button>
            </ModalFooter>
        </Modal>
    );
}

DeleteAttacksModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired, // called by handleSubmit
    onClose: PropTypes.func.isRequired,
}

export default DeleteAttacksModal;
