import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import UserBarContainer from '../UserBar';
import PageTitleRouter, { PageTitle } from './PageTitleRouter';
import MenuTogglerContainer from './MenuTogglerContainer';
import brandLogoImage from './images/logo-153x50-bw.png';
import './Header.scss';


const PageLogo = () => {
    return (
        <NavbarBrand tag={ Link } to="/">
            <img className="brand-icon" src={ brandLogoImage } alt="Logo"/>
        </NavbarBrand>
    );
};


class Header extends Component {

    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Navbar fixed="top" color="primary" dark>
                { children }
            </Navbar>
        );
    }
}

export { PageLogo, PageTitle, PageTitleRouter, UserBarContainer, MenuTogglerContainer };
export default Header;
