import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink, Button } from "reactstrap";
import { MainContent } from "../Layout";
import { Icon } from "../Elements";
import CollaboratorList from "./CollaboratorList";
import AddCollaboratorModal from "./AddCollaboratorModal";
import TransferOwnershipModal from "./TransferOwnershipModal";
import "./ProjectSettings.scss";


class ProjectSettings extends Component {

    static propTypes = {
        userEmail: PropTypes.string.isRequired,
        collaborators: PropTypes.array.isRequired,
        onAddCollaborator: PropTypes.func.isRequired,
        onRemoveCollaborator: PropTypes.func.isRequired,
        onTransferOwner: PropTypes.func.isRequired,
    };

    state = {
        isAddModalOpen: false,
        isTransferModalOpen: false,
    };

    toggleAddCollaboratorModal(value) {
        this.setState({
            isAddModalOpen: value
        });
    }

    toggleTransferOwnershipModal(value) {
        this.setState({
            isTransferModalOpen: value
        });
    }

    renderNav() {
        const onTransferOwnershipClick = (e) => {
            e.preventDefault();
            this.toggleTransferOwnershipModal(true);
        };

        return (
            <Nav>
                <NavItem>
                    <NavLink href="#">Collaborators</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#" onClick={ onTransferOwnershipClick }>Transfer Ownership</NavLink>
                </NavItem>
            </Nav>
        );
    }

    render() {
        const { collaborators, userEmail, onAddCollaborator, onRemoveCollaborator, onTransferOwner } = this.props;
        const { isAddModalOpen, isTransferModalOpen } = this.state;
        const ownerEmail = collaborators.find(c => c.isOwner).userId;
        const onDeleteItem = (email) => onRemoveCollaborator(email);
        const onAddItem = () => this.toggleAddCollaboratorModal(true);
        const onCancelAddModal = () => this.toggleAddCollaboratorModal(false);
        const onSubmitAddModal = ({ email }) => {
            this.toggleAddCollaboratorModal(false);
            onAddCollaborator(email)
        };
        const onCancelTransferModal = () => this.toggleTransferOwnershipModal(false);
        const onSubmitTransferModal = (email) => {
            this.toggleTransferOwnershipModal(false);
            onTransferOwner(email);
        };

        return (
            <Col>
                <MainContent className="project-settings">
                    <Row>
                        {/*Icon*/}
                        <Col xs="12" lg={{ size: 3 }} className="icon-col">
                            <Icon icon="cog" classes="page-icon"/>
                            <h2 className="d-block d-lg-none">Settings</h2>
                        </Col>

                        <Col xs="12" lg={{ size: 9 }} className="content-col">
                            {/*Navigation*/}
                            <Row>
                                <Col xs="12" className="mb-2">
                                    { this.renderNav() }
                                </Col>
                            </Row>
                            {/*Collaborators List & Edit*/}
                            <Row>
                                <Col xs="12">
                                    <CollaboratorList
                                        list={ collaborators }
                                        userEmail={ userEmail }
                                        onDeleteItem={ onDeleteItem }/>

                                    <Button color="danger mt-3 pull-right" onClick={ onAddItem }>Add collaborator</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </MainContent>

                <AddCollaboratorModal
                    isOpen={ isAddModalOpen }
                    userEmail={ userEmail }
                    ownerEmail={ ownerEmail }
                    collaborators={ collaborators }
                    onCancel={ onCancelAddModal }
                    onSubmit={ onSubmitAddModal }
                />
                <TransferOwnershipModal
                    isOpen={ isTransferModalOpen }
                    userEmail={ userEmail }
                    ownerEmail={ ownerEmail }
                    collaborators={ collaborators }
                    onCancel={ onCancelTransferModal }
                    onSubmit={ onSubmitTransferModal }
                />
            </Col>
        );
    }
}

export default ProjectSettings;
