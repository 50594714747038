import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PerfBar from './PerfBar';


const data = {
    name: "Some time",
    chunks: [
        { time: 22, name: 'Process abc' },
        { time: 60.1, name: 'Store data' },
        { time: 111.15, name: 'Store data' },
        { time: 5.1, name: 'Store data' },
    ]
};
data.time = data.chunks.reduce((acc, { time }) => acc + time, 0);

const mapStateToProps = (state) => {
    return {
        data: data, //TODO: use state
    };
};


class PerfBarContainer extends Component {

    static propTypes = {
        // from connect
        data: PropTypes.object.isRequired,
        //close: PropTypes.func.isRequired,
    };

    state = {
        open: false,
    };

    componentDidMount() {
        window._perf = () => this.setState({ open: true });
    }

    render() {
        const { data } = this.props;
        const { open } = this.state;
        const onClose = () => this.setState({ open: false });

        if (!open) { return null; }

        return <PerfBar
            data={ data }
            onClose={ onClose }
        />;
    }
}

PerfBarContainer = connect(mapStateToProps)(PerfBarContainer);

export default PerfBarContainer;
