import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { CloseButton } from '../Elements';
import './PerfBar.scss';


const percent2color = (percent, { sat = 100, level = 45 } = {}) => {
    const hue = 90 * (1 - percent / 100);
    return `hsl(${ hue },${ sat }%,${ level }%)`;
};
const round = (num, digits = 1) => {
    const tenPow = Math.pow(10, digits);
    return Math.round(num * tenPow) / tenPow;
};


class PerfBar extends Component {

    static propTypes = {
        data: PropTypes.shape({
            time: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            chunks: PropTypes.array.isRequired,
        }),
        onClose: PropTypes.func,
    };

    state = {
        showParts: false,
    };

    render() {
        const { data: { time, name, chunks }, onClose } = this.props;
        const { showParts } = this.state;
        const onCloseClick = () => onClose();
        const onFooterClick = () => this.setState({ showParts: !showParts });

        const chunksData = chunks.map(({ time: t, name }) => {
            const percent = t / time * 100;
            const title = `${ name }: ${t}ms (${ round(percent) }%)`;
            const hsl = percent2color(percent);

            return { title, percent, hsl };
        });

        const colorBars = chunksData.map(({ title, percent, hsl }, i) => {
            const style = { backgroundColor: hsl, width: `${ percent }%` };

            return (
                <div key={ `chunk-${i}` } className="perf-chunk" style={ style } title={ title }/>
            );
        });

        const parts = chunksData.map(({ title, hsl }, i) => {
            const style = { backgroundColor: hsl };

            return (
                <div key={ `chunk-${i}` }>
                    <div className="perf-bullet" style={ style }></div>
                    { title }
                </div>);
        });

        return (
            <div className="perf-bar" role="alert">
                <CloseButton onClick={ onCloseClick }/>

                <div className={ cs("perf-body", { 'show-parts': showParts }) }>
                    <div className="font-weight-bold">{ name }: { time }ms</div>
                    <div className="perf-parts">{ parts }</div>
                </div>

                <div className="perf-footer" onClick={ onFooterClick }>
                    { colorBars }
                </div>
            </div>
        );
    }
}

export default PerfBar;
