import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleAlert } from '../../redux/modules/alert';
import Alert from './Alert';


export const ALERT_INFO = 'alert-info'; // blue
export const ALERT_WARNING = 'alert-warning'; // pink


const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggleAlert: (show, params) => dispatch(toggleAlert(show, params)),
    }
};


class AlertContainer extends Component {

    static propTypes = {
        // from connect
        alert: PropTypes.object.isRequired,
        toggleAlert: PropTypes.func.isRequired,
    };

    toggleTest() {
        const { toggleAlert } = this.props;

        toggleAlert(true, {
            type: ALERT_INFO,
            title: 'Test',
            message: 'This is how info alert looks',
            onClose: () => toggleAlert(false),
        });
    }

    render() {
        const { alert: { show, ...rest } } = this.props;

        if (!show) { return null; }

        return <Alert { ...rest }/>;
    }
}
AlertContainer = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AlertContainer);

export default AlertContainer;
