import React from "react";
import classNames from "classnames";

const CloseButton = ({ onClick, className, ...rest }) => {
    const classes = classNames('close', className);

    return (
        <button type="button" className={ classes } aria-label="Close" onClick={ onClick } { ...rest }>
            <span aria-hidden="true">&times;</span>
        </button>
    );
};

export default CloseButton;
