import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import { addEventListener, removeEventListener } from "../../services/DOMUtils";


class ProjectDownloadFormButton extends Component {

    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        param: PropTypes.string,
        onError: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this._iframe = this._form.querySelector('iframe');
        addEventListener(this._iframe, 'load', this.handleSubmitResult);
    }

    componentWillUnmount() {
        removeEventListener(this._iframe, 'load', this.handleSubmitResult);
    }

    // iFrame's onLoad event is not firing when download succeeds, thus we can only catch errors this way
    //
    handleSubmitResult = (e) => {
        const { onError } = this.props;
        const iframe = e.target;
        const innerDoc = iframe && (iframe.contentDocument || iframe.contentWindow.document);
        const error = innerDoc && innerDoc.body.innerText;

        if (error) {
            onError(error);
        }
    };

    render() {
        const { disabled, label, url, param } = this.props;

        return (
            <form method="post" action={ url } target="download-frame"
                style={ { width: "100%" } }
                ref={ c => this._form = c }
            >
                { param && (
                    <input type="hidden" name="param" value={ param }/>
                ) }
                <Button color="danger" block disabled={ disabled }>{ label }</Button>
                <iframe name="download-frame" title="df" width="0" height="0" frameBorder="0" style={ { position: 'absolute' } }/>
            </form>
        );
    }
}

export default ProjectDownloadFormButton;
