import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { createProject, deleteProject, fetchProjectList } from "../../redux/modules/project-list";
import { onSyncUpMessage } from "../../services/IoClient";
import ProjectList from "./ProjectList";


const mapStateToProps = (state) => {
    return {
        userEmail: state.user.email || '--',
        projects: state.project.list.data,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createProject: (data) => dispatch(createProject(data)),
        deleteProject: (projectId) => dispatch(deleteProject(projectId)),
        fetchProjectList: () => dispatch(fetchProjectList()),
    }
};


class ProjectListContainer extends Component {

    static propTypes = {
        // from Router
        redirect: PropTypes.func.isRequired,
        // from connect
        userEmail: PropTypes.string.isRequired,
        projects: PropTypes.array.isRequired,
        createProject: PropTypes.func.isRequired,
        deleteProject: PropTypes.func.isRequired,
        fetchProjectList: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { fetchProjectList } = this.props;

        this._unsubscribe = onSyncUpMessage('projects/collaborators', () => {
            fetchProjectList();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    handleAddProject() {
        const { createProject, redirect } = this.props;

        createProject().then(({ error, result }) => {
            if (error) { return; }

            redirect({ to: `/projects/${ result._id }/new` });
        });
    }

    handleRemoveProject(projectId) {
        this.props.deleteProject(projectId);
    }

    render() {
        const { projects, userEmail } = this.props;
        const onAdd = () => this.handleAddProject();
        const onRemove = (projectId) => this.handleRemoveProject(projectId);

        return (
            <ProjectList list={ projects }
                userEmail={ userEmail }
                onAdd={ onAdd }
                onRemove={ onRemove }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListContainer);
