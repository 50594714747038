import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { getProjectTitle } from "../../redux/modules/project-list";
import { InfoBarLink } from "../InfoBar";
import { onSyncUpMessage } from "../../services/IoClient";


const mapStateToProps = (state, { projectId, title }) => {
    return {
        title: title || getProjectTitle(state, { projectId })
    };
};

class ProjectBackLinkContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        topLevel: PropTypes.bool,
        // from connect or own
        title: PropTypes.string,
    };

    state = {
        notFound: {},
    };

    componentDidMount() {
        const { projectId } = this.props;

        this._unsubscribe = onSyncUpMessage(`projects/${projectId}/removed`, () => {
            // This is the single common component for all project-related pages (Details, Settings, Policies...),
            // so re-using it to navigate away once the project is not accessible anymore (removed or unshared).
            this.setState({
                notFound: {
                    ...this.state.notFound,
                    [projectId]: true,
                }
            });
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    render() {
        const { projectId, topLevel = false, title } = this.props;
        const { notFound } = this.state;
        const url = topLevel ? '/projects' : `/projects/${projectId}`;

        if (!title) { return null; }
        if (notFound[projectId]) { return <Redirect to="/projects"/>; }

        return (
            <InfoBarLink to={ url } withBack >Back to { title }</InfoBarLink>
        );
    }
}
ProjectBackLinkContainer = connect(mapStateToProps)(ProjectBackLinkContainer);

export default ProjectBackLinkContainer;
