import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUser, updateUser } from '../../redux/modules/user';
import { logout } from '../../redux/modules/auth';
import UserBar from './UserBar';


const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: () => dispatch(fetchUser()),
        updateUser: (user) => dispatch(updateUser(user)),
        logout: (user) => dispatch(logout(user)),
    }
};


class UserBarContainer extends Component {

    static propTypes = {
        //redirect: PropTypes.func.isRequired,
        // from connect
        user: PropTypes.object.isRequired,
        fetchUser: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        const { user, fetchUser, updateUser } = props;
        const shouldFetch = !user.email;

        if (shouldFetch) {
            fetchUser().then(({ error, result }) => {
                if (error) {
                    return this.handleLogout();
                }
                updateUser({ isAuthenticated: true, ...result });
            });
        }
    }

    handleLogout() {
        const { logout, updateUser, user: { email }/*, redirect*/ } = this.props;

        logout({ email }).then(() => {
            updateUser(undefined);
            //redirect({ to: '/login' });
        });
    }

    render() {
        const { user: { email } } = this.props;
        const isLoaded = !!email;
        const userMenu = [
            { label: 'Logout', onClick: () => this.handleLogout() }
        ];

        if (!isLoaded) { return null; }

        return <UserBar email={ email } menuItems={ userMenu }/>;
    }
}
UserBarContainer = connect(mapStateToProps, mapDispatchToProps)(UserBarContainer);

export default UserBarContainer;
