import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { Input, Button, FormGroup, Label } from "reactstrap";
import { Icon } from '../../Elements';
import { dateFilterOptions, typeFilterOptions } from "./constants";
import "./AttackListFilter.scss";


const AttackListFilterItem = ({ label, children }) => (
    <div className="afe-item">
        <div className="afe-item-label">{ label }</div>
        <div className="afe-item-control">
            { children }
        </div>
    </div>
);

class AttackListFilterEditor extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        filter: PropTypes.object.isRequired,
        projectList: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onToggle: PropTypes.func.isRequired,
    };

    state = {
        filterValue: this.props.filter,
    };

    componentDidUpdate(prevProps) {
        const { filter } = this.props;
        const { filter: prevFilter } = prevProps;

        const filterChanged = (JSON.stringify(prevFilter) !== JSON.stringify(filter));
        if (filterChanged) {
            this.setState({
                filterValue: filter,
            });
        }
    }

    updateFilterValue = (type) => (e) => {
        const { value } = e.target;
        const { filterValue } = this.state;

        this.setState({
            filterValue: {
                ...filterValue,
                [type]: value,
            },
        });
    };

    render () {
        const { projectList, onSelect, isOpen, onToggle } = this.props;
        const { filterValue } = this.state;
        const { projects: fvProjects = 'all', dates: fvDates = 'all', types: fvTypes = 'all' } = filterValue;
        const onSubmit = () => {
            onSelect(filterValue);
            onToggle();
        };

        return (
            <div className={ cs("attack-filter-editor clearfix", { open: isOpen }) }>
                <AttackListFilterItem label="Projects:">
                    <Input type="select" className="form-control-sm"
                        value={ fvProjects }
                        onChange={ this.updateFilterValue("projects") }
                    >
                        <option value="all">All projects</option>
                        { projectList.map(({ _id: projectId, title: projectTitle }) => (
                            <option key={ projectId } value={ projectId }>{ projectTitle }</option>
                        )) }
                    </Input>
                </AttackListFilterItem>

                <AttackListFilterItem label="Types:">
                    <Input type="select" className="form-control-sm"
                        value={ fvTypes }
                        onChange={ this.updateFilterValue("types") }
                    >
                        { typeFilterOptions.map(({ value, label }) => (
                            <option key={ value } value={ value }>{ label }</option>
                        )) }
                    </Input>
                </AttackListFilterItem>

                <AttackListFilterItem label="Dates:">
                    { dateFilterOptions.map(({ value, label }, i) => (
                        <FormGroup key={ `dfi-${ i }` } check>
                            <Label check>
                                <Input type="radio"
                                    value={ value }
                                    checked={ value === fvDates }
                                    onChange={ this.updateFilterValue("dates") }/> { label }
                            </Label>
                        </FormGroup>
                    )) }
                </AttackListFilterItem>

                <Button color="danger" size="sm" className="pull-right" onClick={ onToggle }>
                    <Icon icon="fw close"/> Close
                </Button>
                <Button color="danger" size="sm" className="pull-right" onClick={ onSubmit }>
                    <Icon icon="fw check"/> Apply
                </Button>
            </div>
        );
    }
}

export default AttackListFilterEditor;
