import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { inviteComplete } from "../../redux/modules/auth";
import { Link } from "react-router-dom";
import AuthInviteCompleteForm from "./AuthInviteCompleteForm";


const mapStateToProps = (state) => {
    return {
        apiError: state.auth.invite.error,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        inviteComplete: (data) => dispatch(inviteComplete(data)),
    }
};


class AuthLoginFormContainer extends Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        error: PropTypes.string,
        redirect: PropTypes.func.isRequired,
        //from connect
        apiError: PropTypes.object,
        inviteComplete: PropTypes.func.isRequired,
    };

    handleLogin(data) {
        const { inviteComplete, redirect } = this.props;

        return inviteComplete(data).then(({ error }) => {
            if (!error) {
                return redirect({ to: '/projects', replace: true });
            }

            const { error: errorStatus, email } = error;
            if (errorStatus === 'Not Found') {
                return redirect({ to: `/invite-complete/${ email }?e=expired`, replace: true });
            }
        });
    }

    renderCriticalError(error) {
        if (error === 'not-found') {
            return (
                <div className="critical-error">Oops! There is something wrong with this link.
                    Please make sure you copy the entire link from the invitation email in order to proceed with sign up.
                </div>
            );
        }

        // Probably 'expired' set above on API error
        return (
            <div className="critical-error">Oops! Registration session is expired.
                To protect your account we may no longer accept new password.
                Please open Invitation email and visit a link inside it to start anew.
            </div>
        );
    }

    render() {
        const { email, error, apiError } = this.props;
        const apiErrorMsg = apiError ? 'Oops! Something went wrong. Please try again later.' : '';
        const onSubmit = (data) => this.handleLogin(data);

        if (error) {
            return (
                <div>
                    { this.renderCriticalError(error) }

                    <p className="form-text text-muted">
                        <Link to="/login">Back to login</Link>
                    </p>
                </div>
            );
        }

        return (
            <AuthInviteCompleteForm
                email={ email }
                errorMsg={ apiErrorMsg }
                onSubmit={ onSubmit }
            />
        );
    }
}
AuthLoginFormContainer = connect(mapStateToProps, mapDispatchToProps)(AuthLoginFormContainer);

export default AuthLoginFormContainer;
