import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { onSyncUpMessage } from "../../services/IoClient";
import { fetchProjectDetails } from '../../redux/modules/project-details';
import { addCollaborator, removeCollaborator, updateOwner } from "../../redux/modules/project-details"
import ProjectSettings from './ProjectSettings';


const mapStateToProps = (state, { projectId }) => {
    const projectDetails = (state.project.details[projectId] || {}).data;
    return {
        userEmail: state.user.email || '--',
        collaborators: !projectDetails ? [] : projectDetails.collaborators,
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        fetchProjectDetails: () => dispatch(fetchProjectDetails(projectId)),
        addCollaborator: (email) => dispatch(addCollaborator(projectId, email)),
        removeCollaborator: (email) => dispatch(removeCollaborator(projectId, email)),
        updateOwner: (email) => dispatch(updateOwner(projectId, email)),
    }
};


class ProjectSettingsContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        // from connect
        collaborators: PropTypes.array.isRequired,
        userEmail: PropTypes.string.isRequired,
        fetchProjectDetails: PropTypes.func.isRequired,
        addCollaborator: PropTypes.func.isRequired,
        removeCollaborator: PropTypes.func.isRequired,
        updateOwner: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { projectId, fetchProjectDetails } = this.props;

        this._unsubscribe = onSyncUpMessage(`projects/${projectId}/collaborators`, () => {
            fetchProjectDetails();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    render() {
        const { collaborators, userEmail, addCollaborator, removeCollaborator, updateOwner } = this.props;
        const onAddCollaborator = (email) => addCollaborator(email);
        const onRemoveCollaborator = (email) => removeCollaborator(email);
        const onTransferOwner = (email) => updateOwner(email);

        if (!collaborators.length) { return null; }

        return (
            <ProjectSettings
                userEmail={ userEmail }
                collaborators={ collaborators }
                onAddCollaborator={ onAddCollaborator }
                onRemoveCollaborator={ onRemoveCollaborator }
                onTransferOwner={ onTransferOwner }
            />
        );
    }
}

ProjectSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsContainer);

export default ProjectSettingsContainer;
