import React, { Component } from "react";
import PropTypes from 'prop-types';
import { TextWithTooltip } from "../Elements";


class ProjectStatusList extends Component {

    static propTypes = {
        install: PropTypes.object.isRequired,
    };

    render() {
        const { os, ipaddr, hostname, date: installDate } = this.props.install;
        const buildServer = [
            ...(ipaddr ? [ipaddr] : []),
            ...(ipaddr && hostname ? [' / '] : []),
            ...(hostname ? [hostname] : []),
        ].join('');
        const buildDate = installDate && new Date(installDate).toString();


        return (
            <ul className="status-list">
                <li>
                    <span className="status-list-label">Build Server:</span>
                    <TextWithTooltip id="bs" className="status-list-value">{ buildServer }</TextWithTooltip>
                </li>
                <li>
                    <span className="status-list-label">Build Server OS:</span>
                    <TextWithTooltip id="os" className="status-list-value">{ os }</TextWithTooltip>
                </li>
                <li>
                    <span className="status-list-label">Install Time:</span>
                    <TextWithTooltip id="bd" className="status-list-value">{ buildDate }</TextWithTooltip>
                </li>
            </ul>
        );
    }
}

export default ProjectStatusList;
