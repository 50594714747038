import { canUseDOM } from "./client/services/DOMUtils";


// Internal vars
//

// Safe to use on client (e.g. components) because of webpack.definePlugin.
// Other vars are only for server code which can see the env vars (unless defaults are OK).
export const isDev = process.env.NODE_ENV !== 'production';

export const port = isDev ? 3000 : (process.env.PORT || 3000);

export const dbUrl = process.env.MONGODB_URI || 'mongodb://localhost/karamba';
export const dbUrlTest = process.env.MONGODB_URI_TEST || 'mongodb://localhost/test';

export const sessionSecret = process.env.SESSION_SECRET || '784d5d7a-698b-473d-99ba-57a5fa00bfc7';

// `host` var is special as it can have different values depending on dev/stage and client/server
export const host = isDev ? `localhost:3001` :      // dev: both server & client
    canUseDOM ? window.location.host :              // prod/client: from dom
        process.env.HOST || `localhost:${ port }`;  // prod/server: from env var or default

export const securityAllowFraming = !!process.env.SECURITY_ALLOW_FRAMING;

// External Services vars
//

// AWS
export const awsAccessKeyId = process.env.AWS_ACCESS_KEY_ID;
export const awsSecretAccessKey = process.env.AWS_SECRET_ACCESS_KEY;
export const awsDefaultRegion = process.env.AWS_DEFAULT_REGION || 'eu-central-1';
export const awsBucketName = process.env.AWS_BUCKET_NAME || 'karamba-releases';

const defDownloadChannel = process.env.AWS_DOWNLOAD_CHANNEL || 'stable';
export const awsProjectDownloadChannel = (projectId) => (
    process.env['AWS_DOWNLOAD_CHANNEL_' + projectId] || defDownloadChannel
);

// Mail: no defaults!
export const sendgridApiKey = process.env.SENDGRID_API_KEY;

// Default collaborator userId(email): no default!
export const defaultCollaboratorUserId = process.env.COLLABORATOR_EMAIL;

// reCAPTCHA
export const captchaSiteKey = canUseDOM ? window.captchaSiteKey : process.env.RECAPTCHA_SITE_KEY; // passed to client in Html.js
export const captchaSecretKey = canUseDOM ? '' : process.env.RECAPTCHA_SECRET_KEY; // Ensure only for server!
