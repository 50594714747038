import qs from 'querystring';

export const RESET = 'incidents/RESET';

export const LIST_FETCH_PAGE = 'incidents/LIST_FETCH_PAGE';
export const LIST_FETCH_PAGE_SUCCESS = 'incidents/LIST_FETCH_PAGE_SUCCESS';
export const LIST_FETCH_PAGE_FAIL = 'incidents/LIST_FETCH_PAGE_FAIL';

const LIST_DELETE_ALL = 'incidents/LIST_DELETE_ALL';
const LIST_DELETE_ALL_SUCCESS = 'incidents/LIST_DELETE_ALL_SUCCESS';
const LIST_DELETE_ALL_FAIL = 'incidents/LIST_DELETE_ALL_FAIL';

export const DEF_PAGE_SIZE = 50;

const initialState = {
    pager: {
        fromIndex: 0,
        untilIndex: 0,
        isFirstPage: true,
        isLastPage: true,
    },
    list: {
        stubs: null,
        loading: false,
        error: null,
    },
    delete: {
        loading: false,
        error: null,
    },
};

// Reducers
//
function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case RESET:
            return {
                ...initialState,
            };
        case LIST_FETCH_PAGE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                    error: null,
                },
            };
        case LIST_FETCH_PAGE_SUCCESS:
            return {
                ...state,
                list: {
                    loading: false,
                    stubs: action.result.stubs,
                },
                pager: getNewPagerState(state, action),
            };
        case LIST_FETCH_PAGE_FAIL:
            return {
                ...state,
                list: {
                    loading: false,
                    error: action.error,
                    stubs: [],
                },
            };
        case LIST_DELETE_ALL:
            return {
                ...state,
                delete: {
                    loading: true,
                    error: null,
                },
            };
        case LIST_DELETE_ALL_SUCCESS:
            return {
                ...initialState, // reset!
                list: {
                    ...initialState.list,
                    stubs: [], // null would show an infinite loader
                },
            };
        case LIST_DELETE_ALL_FAIL:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: action.error,
                },
            };
        default:
            return state;
    }
}

// Helpers
//

function getNewPagerState(state, action) {
    const { query, result: { stubs, prev, next} } = action;

    // Initial load, Re-fetch or empty result -> show first page [0, L)
    if ((!query.prev && !query.next) || stubs.length === 0) {
        return {
            fromIndex: 0,
            untilIndex: stubs.length,
            isFirstPage: true,
            isLastPage: !next,
            prev,
            next,
        };
    }

    // Paging next: older items has fetched
    if (!!query.next) {
        return {
            fromIndex: state.pager.untilIndex,
            untilIndex: state.pager.untilIndex + stubs.length,
            isFirstPage: false,
            isLastPage: !next,
            prev,
            next,
        };
    }

    // Paging prev: newer items has fetched
    // TODO - can use `result.prev` here to show 'Have newer items' label
    if (!!query.prev) {
        const fromIndex = state.pager.fromIndex - stubs.length;
        return {
            fromIndex,
            untilIndex: state.pager.fromIndex,
            isFirstPage: fromIndex === 0,
            isLastPage: false, // next page must exist after we just left it
            prev,
            next,
        };
    }

    return state.pager; // misuse
}

// Action creators
//
export function fetchIncidents({ count = DEF_PAGE_SIZE, prev = null, next = null, filter = {} } = {}) {
    const { projects = 'all', dates = 'all', types = 'all' } = filter;
    const query = {
        count,
        ...(!!prev ? { prev } : {}),
        ...(!!next ? { next } : {}),
        ...(projects !== 'all' ? { projects } : {}),
        ...(dates !== 'all' ? { dates } : {}),
        ...(types !== 'all' ? { types } : {}),
    };
    const queryString = qs.stringify(query);

    return {
        types: [LIST_FETCH_PAGE, LIST_FETCH_PAGE_SUCCESS, LIST_FETCH_PAGE_FAIL],
        promise: (client) => client.get(`/api/incidents?${ queryString }`),
        query, // for paging
        queryString, // for incident-list-stats
    };
}

export function deleteIncidents() {
    return {
        types: [LIST_DELETE_ALL, LIST_DELETE_ALL_SUCCESS, LIST_DELETE_ALL_FAIL],
        promise: (client) => client.del(`/api/incidents`),
    };
}

export function resetStore() {
    return {
        type: RESET,
    };
}

export default reducer;
