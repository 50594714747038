import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { SvgIcon } from "../Elements";


class DeleteProjectModal extends Component {

    static propTypes = {
        deletingProject: PropTypes.object,
        onSubmit: PropTypes.func.isRequired, // called by handleSubmit
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const { deletingProject, onClose, onSubmit } = this.props;
        const isOpen = !!deletingProject;
        const { title, projectId } = deletingProject || {};
        const handleSubmit = () => onSubmit(projectId);

        return (
            <Modal size="md" isOpen={ isOpen } toggle={ onClose } className="modal-delete">
                <ModalHeader toggle={ onClose }>
                    <SvgIcon icon="danger" sz="xl"/>
                    <span>Delete { title }?</span>
                </ModalHeader>
                <ModalBody>
                    A Project that is deleted cannot be recovered. Do you wish to proceed? (Note that your system's build files would not be
                    deleted.)
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={ handleSubmit } block>Delete</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DeleteProjectModal;
