import React from 'react';
import { Switch, Route } from "react-router-dom";
import { InfoBarTitle, InfoBarLink } from "./InfoBar";
import ProjectBackLinkContainer from "./ProjectBackLinkContainer";


const InfoBarLinkRouter = () => (
    <Switch>
        <Route path="/login" render={ () => <InfoBarTitle title="Login"/> }/>
        <Route path="/signup" render={ () => <InfoBarTitle title="Signup"/> }/>
        <Route path="/verify" render={ () => <InfoBarTitle title="Email Verification"/> }/>
        <Route path="/approve" render={ () => <InfoBarTitle title="Registration Approval"/> }/>
        <Route path="/restore(-proceed|-complete)?" render={ () => <InfoBarTitle title="Password Restore"/> }/>
        <Route path="/invite-complete" render={ () => <InfoBarTitle title="Complete Your Registration"/> }/>
        <Route exact path="/projects/:projectId/(whitelist|in-memory|settings)" render={ ({ match: { params: { projectId } } }) =>
            <ProjectBackLinkContainer projectId={ projectId }/>
        }/>
        <Route exact path="/projects/:projectId/not-found" render={ ({ match: { params: { projectId } } }) =>
            <ProjectBackLinkContainer projectId={ projectId } title="Projects main page" topLevel/>
        }/>
        <Route exact path="/projects/:projectId" render={ ({ match: { params: { projectId } } }) =>
            <ProjectBackLinkContainer projectId={ projectId } title="Projects main page" topLevel/>
        }/>
        <Route path="/attacks/:incidentId" render={ ({ location }) => {
                const { backURL } = location.state || {}; // backURL is pushed to state by AttackListContainer
                const to = backURL || '/attacks';
                return (
                    <InfoBarLink to={ to } withBack>Back to Attacks Overview</InfoBarLink>
                );
        } }/>
    </Switch>
);

export default InfoBarLinkRouter;
