import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchProjectDetails, toggleProjectPolicy } from "../../redux/modules/project-details";
import { onSyncUpMessage } from "../../services/IoClient";
import ProjectDetails from "./ProjectDetails";


const mapStateToProps = (state, { projectId }) => {
    return {
        projectDetails: (state.project.details[projectId] || {}).data
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        fetchProjectDetails: () => dispatch(fetchProjectDetails(projectId)),
        toggleProjectPolicy: (policyName, enabled) => dispatch(toggleProjectPolicy(projectId, policyName, enabled)),
    }
};


class ProjectDetailsContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        // from connect
        projectDetails: PropTypes.object,
        fetchProjectDetails: PropTypes.func.isRequired,
        toggleProjectPolicy: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { projectId, fetchProjectDetails } = this.props;

        this._unsubscribe = onSyncUpMessage(`projects/${projectId}`, () => {
            fetchProjectDetails();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    handleTogglePolicy = (policyName, enabled) => {
        const { toggleProjectPolicy } = this.props;
        toggleProjectPolicy(policyName, enabled);
    }

    render() {
        const { projectDetails } = this.props;

        if (!projectDetails) { return null; }

        return (
            <ProjectDetails
                projectDetails={ projectDetails }
                onTogglePolicy={ this.handleTogglePolicy }
            />
        );
    }
}
ProjectDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsContainer);

export default ProjectDetailsContainer;
