import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';
import { SvgIcon } from '../Elements';


class ActionCenterBalloon extends Component {

    static propTypes = {
        onClick: PropTypes.func.isRequired,
    };

    state = {
        isOpen: false,
    };

    toggle(open = !this.state.isOpen) {
        this.setState({ isOpen: open });
    }

    render() {
        const { onClick } = this.props;
        const { isOpen } = this.state;
        const onDismiss = () => this.toggle(false);

        return (
            <div>
                <Popover placement="bottom-end" isOpen={ isOpen } target="info-bar" toggle={ onDismiss }>
                    <PopoverBody onClick={ onClick }>
                        <SvgIcon icon="ac-info-black" sz="md"/>
                        Open Action Center to view new notification!
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

export default ActionCenterBalloon;
