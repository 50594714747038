import superagent from 'superagent';

const methods = ['get', 'post', 'put', 'patch', 'del'];


export default function ApiClient() {
    // API signature: [get|post|...](path, { query, data })
    //
    const call = (method) => (path, { query, data } = {}) => new Promise((resolve, reject) => {
        const request = superagent[method](path).set('Accept', 'application/json');

        if (query) {
            request.query(query);
        }
        if (data) {
            request.send(data);
        }

        request.end((err, { body } = {}) => {
            if (err) {
                reject({ err, body });
            }
            else {
                resolve({ body });
            }
        });
    });

    return methods.reduce(
        (acc, method) => {
            return {
                ...acc,
                [method]: call(method)
            }
        },
        {}
    );
}
