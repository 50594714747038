import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, reset } from "redux-form";
import { validate, InputGroup } from "../Form";
import { Link } from 'react-router-dom'


const formConfig = {
    form: 'invite',
    fields: ['password'],
    validate: validate([
        { password: '* required' },
        { password: (s = '') => s.length < 6 && 'at least 6 chars' },
    ]),
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetForm: () => dispatch(reset('invite')),
    }
};


class AuthInviteCompleteForm extends Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        errorMsg: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        // from redux-form
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        // from connect
        resetForm: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.setDefaultFocus();
    }

    componentDidUpdate(prevProps) {
        const newError = !prevProps.errorMsg && !!this.props.errorMsg;
        if (newError) {
            this.reset();
        }
    }

    setDefaultFocus() {
        const passwordField = this._password.getRenderedComponent();
        passwordField.focus();
    }

    reset() {
        this.props.resetForm();
        setTimeout(() => this.setDefaultFocus(), 0);
    }

    render() {
        const { email, errorMsg, onSubmit, handleSubmit, valid, dirty, submitting } = this.props;
        const errorMsgClass = (errorMsg && !dirty) ? 'visible' : '';
        const disableSubmit = !dirty || !valid || submitting;

        return (
            <form className="clearfix" onSubmit={ handleSubmit(data => onSubmit({ email, ...data })) } >
                <h4 className="mb-3 text-muted">Invitation accepted!</h4>

                <p>You are one step away from accessing a project shared with you.
                    Just set your password and you are done.</p>

                <FormGroup>
                    <Label for="email" className="text-muted">Email</Label>
                    <Input type="email" id="email" name="email" value={ email } valid disabled/>
                </FormGroup>

                <Field type="password" name="password" component={ InputGroup }
                    label="Password"
                    placeholder="Password"
                    forwardRef={ true } ref={ (c) => this._password = c }
                />

                <small className={ `form-text text-danger server-error ${errorMsgClass}` }>
                    { errorMsg }&nbsp;
                </small>

                <Button type="submit" block color="danger" size="lg" disabled={ disableSubmit }>Enter</Button>

                <p className="form-text text-muted">
                    <Link to="/login">Back to login</Link>
                </p>
            </form>
        );
    }
}
AuthInviteCompleteForm = connect(null, mapDispatchToProps)(
    reduxForm(formConfig)(AuthInviteCompleteForm)
);

export default AuthInviteCompleteForm;
