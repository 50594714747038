import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Icon } from "../Elements";
import EditForm from "./EditForm";
import "./ProjectTitle.scss";


class EditableProjectTitle extends Component {

    static propTypes = {
        value: PropTypes.string.isRequired,
        isNew: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    state = {
        isEditing: this.props.isNew,
    };

    toggle() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    handleSubmit(value) {
        const { onChange } = this.props;
        onChange(value);
    }

    render() {
        const { value, isNew } = this.props;
        const { isEditing } = this.state;
        const toggle = () => this.toggle();
        const onSubmit = (val) => this.handleSubmit(val);

        if (!isEditing) {
            return (
                <span onClick={ toggle } className="title-link" title={ value }>
                    { value }
                    <Icon icon="edit"/>
                </span>
            );
        }

        return (
            <EditForm initTitle={ value }
                isNew={ isNew }
                toggle={ toggle }
                onSubmit={ onSubmit }/>
        );
    }
}

export default EditableProjectTitle;
