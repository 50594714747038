import io from "socket.io-client"; // this does not work without DOM!
import { canUseDOM } from "./DOMUtils";
import { host, isDev } from "../../config";


// Dev setup is [proxy:3001] -> [web:3000] but proxy (which location.host would return on client) cannot handle socket,
// so, on dev, connecting directly to localhost:3000
const ioHost = isDev ? 'localhost:3000' : host;
const noop = () => {
};
const dummySocket = ['emit', 'on', 'off'].reduce((acc, method) => ({ ...acc, [method]: noop }), {});


let socket = dummySocket;

export const connectSocket = (mockSocket) => {
    if (mockSocket) {
        socket = mockSocket; // for storybook
    }
    else if (canUseDOM) {
        socket = io.connect(`//${ ioHost }`);
    }
    return socket;
};

export const disconnectSocket = () => {
    socket.emit('disconnect me', '', () => {
        socket = dummySocket;
    });
};

const onMessage = (type, listener) => {
    socket.on(type, listener);
    return () => socket.off(type, listener);
};

export const onSyncUpMessage = (targetFetch, cb) => {
    const filterFn = ({ fetch }) => fetch.includes(targetFetch) && `fetch:${targetFetch}`;

    return onSyncUpMessageBy(filterFn, cb);
};

export const onSyncUpMessageByReason = (targetReason, cb) => {
    const filterFn = ({ reason }) => reason === targetReason && `reason:${targetReason}`;

    return onSyncUpMessageBy(filterFn, cb);
};

const onSyncUpMessageBy = (filterFn, cb) => {
    const listener = (message) => {
        const { reason } = message;
        const match = filterFn(message);
        if (match) {
            isDev && console.log('====> Socket: sync match=[%s], reason=[%s]', match, reason);
            cb(message);
        }
    };

    return onMessage('sync', listener);
};
