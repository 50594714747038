import { createSelector } from "reselect";

const LIST_FETCH = 'project-list/FETCH';
const LIST_FETCH_SUCCESS = 'project-list/FETCH_SUCCESS';
const LIST_FETCH_FAIL = 'project-list/FETCH_FAIL';

const PROJECT_CREATE = 'project-list/PROJECT_CREATE';
const PROJECT_CREATE_SUCCESS = 'project-list/PROJECT_CREATE_SUCCESS';
const PROJECT_CREATE_FAIL = 'project-list/PROJECT_CREATE_FAIL';

const PROJECT_DELETE = 'project-list/PROJECT_DELETE';
const PROJECT_DELETE_SUCCESS = 'project-list/PROJECT_DELETE_SUCCESS';
const PROJECT_DELETE_FAIL = 'project-list/PROJECT_DELETE_FAIL';


// Reducers
//
function reducer(state = {
    data: [],
    error: undefined,
    loading: true,
}, action = {}) {
    switch (action.type) {
        case LIST_FETCH:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case LIST_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.result,
                error: undefined,
            };
        case LIST_FETCH_FAIL:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        // TODO - update list on create/remove response by initiator (save one round-trip)
        default:
            return state;
    }
}

// Action creators
//
export function fetchProjectList() {
    return {
        types: [LIST_FETCH, LIST_FETCH_SUCCESS, LIST_FETCH_FAIL],
        promise: (client) => client.get('/api/projects'),
    };
}

export function createProject() {
    return {
        types: [PROJECT_CREATE, PROJECT_CREATE_SUCCESS, PROJECT_CREATE_FAIL],
        promise: (client) => client.post('/api/projects'), // no data
    };
}

export function deleteProject(projectId) {
    return {
        types: [PROJECT_DELETE, PROJECT_DELETE_SUCCESS, PROJECT_DELETE_FAIL],
        promise: (client) => client.del(`/api/projects/${projectId}`),
        projectId
    };
}

// Selectors
//

const getProjects = (state) => state.project.list.data;
const getProjectId = (_, props) => props.projectId;

export const getProjectTitle = createSelector(
    [getProjects, getProjectId],
    (projects, projectId) => {
        const project = projects.find(p => p._id === projectId);
        return project && project.title;
    }
);

export default reducer;
