import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { MainContent } from "../Layout";
import { Icon, Toggler } from "../Elements";
import ProjectStatusList from "./ProjectStatusList";
import ProjectPolicyCard from "./ProjectPolicyCard";
import ProjectDownloadModalContainer from "../ProjectDownloadModal"
import downloadIconImage from './images/icon-download.svg';
import readyIconImage from './images/icon-ready.svg';
import sealedIconImage from './images/icon-sealed.svg';
import "./ProjectDetails.scss";


const stage2icon = {
    'Download': downloadIconImage,
    'Ready': readyIconImage,
    'Sealed': sealedIconImage,
};
const stage2title = {
    'Download': 'Download!',
    'Ready': 'Ready!',
    'Sealed': 'Sealed!',
};

const ProjectDownloadButton = ({ onClick }) => {
    return (
        <div className="button-cont">
            <Button color="danger" className="download-btn" onClick={ onClick }>
                <div className="btn-content">Installer Download</div>
                <Icon icon="arrow-circle-down"/>
            </Button>
        </div>
    );
};

const CustomCheckbox = ({ id, label, ...rest }) => (
    <div className="custom-control custom-checkbox">
        <input type="checkbox" className="custom-control-input" id={ id } { ...rest }/>
        <label className="custom-control-label" htmlFor={ id }>{ label }</label>
    </div>
);


class ProjectDetails extends Component {

    static propTypes = {
        projectDetails: PropTypes.object.isRequired,
        onTogglePolicy: PropTypes.func.isRequired,
    };

    state = {
        showDownloadButton: (this.props.projectDetails.stage === 'Download'),
        showDownloadModal: false,
    };

    componentDidUpdate(prevProps) {
        const { stage } = this.props.projectDetails;
        const { stage: prevStage } = prevProps.projectDetails;

        if (stage === 'Download' && prevStage !== stage) {
            this.toggleDownloadButton(false);
        }
    }

    toggleDownloadButton(show = !this.state.showDownloadButton) {
        this.setState({ showDownloadButton: show });
    }

    toggleDownloadModal(show = !this.state.showDownloadModal) {
        this.setState({ showDownloadModal: show });
    }

    handlePolicyToggle(field, path) {
        const { projectDetails: { policy }, onTogglePolicy } = this.props;
        const enabled = !policy[field].enabled;

        onTogglePolicy(path, enabled);
    }

    render() {
        const { projectDetails: { _id: projectId, stage, policy, install = {}, downloadAgent = {} } } = this.props;
        const { showDownloadButton, showDownloadModal } = this.state;
        const { whiteList, inMemory } = policy;
        const icon = stage2icon[stage];
        const title = stage2title[stage];
        const downloadAgentEnabled = !!downloadAgent.enabled;
        const onToggleWhiteList = () => this.handlePolicyToggle('whiteList', 'whitelist');
        const onToggleStaticAnalysis = () => this.handlePolicyToggle('inMemory', 'in-memory');
        const onToggleDownloadButton = () => this.toggleDownloadButton();
        const onDownloadButtonClick = () => this.toggleDownloadModal(true);
        const onDownloadModalClose = () => this.toggleDownloadModal(false);

        // Policy stats
        let imCardSummary = 'Waiting for data...';
        let wlCardSummary = 'Waiting for data...';
        if (stage === 'Sealed') {
            const { stats: { exclusionsTotal } } = inMemory;
            const { stats: { pathsTotal, pathsAllowed } } = whiteList;

            imCardSummary = (exclusionsTotal > 0)
                ? `Exclude ${exclusionsTotal} Targets from Instrumentation`
                : 'Instrument All Targets';
            wlCardSummary = (pathsAllowed < pathsTotal)
                ? `${ pathsAllowed } of ${ pathsTotal } Candidates Whitelisted`
                : 'All Candidates Whitelisted';
        }

        // Download button & toggler
        let downloadToggler = null;
        if (downloadAgentEnabled) {
            const downloadTogglerLabel = showDownloadButton ? 'Show stats' : 'Show download';
            const downloadTogglerStyle = (stage === 'Download') ? { visibility: 'hidden' } : {};

            downloadToggler = (
                <div className="toggler-cont" style={ downloadTogglerStyle }>
                    <Toggler checked={ showDownloadButton }
                        onChange={ onToggleDownloadButton }/>
                    <div className="toggler-label">{ downloadTogglerLabel }</div>
                </div>
            );
        }

        // Status
        let statusContent;
        if (downloadAgentEnabled && showDownloadButton) {
            statusContent = <ProjectDownloadButton onClick={ onDownloadButtonClick }/>;
        } else {
            statusContent = <ProjectStatusList install={ install }/>;
        }

        return (
            <Col className="project-details">
                <MainContent>
                    { /*Download configuration modal*/ }
                    <ProjectDownloadModalContainer
                        projectId={ projectId }
                        isOpen={ showDownloadModal }
                        onClose={ onDownloadModalClose }
                    />

                    { /*Summary*/ }
                    <Row className="project-summary">
                        { /*Col 1 - icon*/ }
                        <Col xs="12" md="4" className="project-icon">
                            <img src={ icon } alt=""/>
                            <h3 className="project-stage-label">{ title }</h3>
                            { downloadToggler }
                        </Col>

                        { /*Col 2 - checkboxes*/ }
                        <Col xs="12" lg="4" className="project-checkboxes d-none d-lg-block">
                            <CustomCheckbox id="whitelist"
                                label="Application Whitelisting"
                                checked={ whiteList.enabled }
                                onChange={ onToggleWhiteList }/>
                            <CustomCheckbox id="in-memory"
                                label="In-Memory Validation"
                                checked={ inMemory.enabled }
                                onChange={ onToggleStaticAnalysis }/>
                        </Col>

                        { /*Col 2 - stats*/ }
                        <Col xs="12" md="8" lg="4" className="project-status">
                            { /*Settings button*/ }
                            <Button color="danger" className="project-link settings" role="button"
                                title="Project settings"
                                tag={ Link } to={ `/projects/${ projectId }/settings` }
                            >
                                <span className="d-none d-md-inline">Settings</span>
                                <Icon icon="cog"/>
                            </Button>

                            { statusContent }
                        </Col>
                    </Row>

                    { /*Policy Cards*/ }
                    <Row>
                        <Col xs="12" lg="6">
                            <ProjectPolicyCard
                                disabled={ !whiteList.enabled }
                                title="Application Whitelisting"
                                icon="policy-whitelist"
                                buttonLink={ `/projects/${ projectId }/whitelist` }
                                buttonDisabled={ !whiteList.enabled || stage !== 'Sealed' }
                                onToggle={ onToggleWhiteList }
                            >{ wlCardSummary }</ProjectPolicyCard>
                        </Col>
                        <Col xs="12" lg="6">
                            <ProjectPolicyCard
                                disabled={ !inMemory.enabled }
                                title="In-Memory Validation"
                                icon="policy-static"
                                buttonLink={ `/projects/${ projectId }/in-memory` }
                                buttonDisabled={ !inMemory.enabled || stage !== 'Sealed' }
                                onToggle={ onToggleStaticAnalysis }
                            >{ imCardSummary }</ProjectPolicyCard>
                        </Col>
                    </Row>
                </MainContent>
            </Col>
        );
    }
}

export default ProjectDetails;
