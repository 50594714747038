import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { MainContent } from "../../Layout";
import AttackListFilterContainer from '../AttackListFilter';
import AttackListContainer from '../AttackList';

class AttackListView extends Component {

    static propTypes = {
        pageSize: PropTypes.number,
        // from router
        redirect: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    };

    render() {
        const { pageSize, redirect, location } = this.props;

        return (
            <Col className="attack-list-page">
                <MainContent>
                    {/*Filter & delete*/}
                    <Row>
                        <Col className="pt-4">
                            <AttackListFilterContainer redirect={ redirect } location={ location }/>
                        </Col>
                    </Row>
                    {/*Table*/}
                    <Row>
                        <Col>
                            <AttackListContainer redirect={ redirect } location={ location } pageSize={ pageSize }/>
                        </Col>
                    </Row>
                </MainContent>
            </Col>
        );
    }
}

export default AttackListView;
