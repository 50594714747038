import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Badge } from 'reactstrap';
import { Checkbox, Icon } from "../../Elements";
import { stringToHSL } from "../../../../common/util";
import './FileTree.scss';


const compactLimit = 20;

export const PackageLabel = ({ packageId, title = '' }) => {
    const bg = stringToHSL(packageId);
    const color = stringToHSL(packageId, { level: 5 }); // darker
    const style = { backgroundColor: bg, color };

    return <Badge pill className="node-badge" style={ style } title={ title }>{ packageId }</Badge>;
};

const NodeMeta = ({ children, ...rest }) => <span className="node-meta" { ...rest }>{ children }</span>;

const NodeStatic = ({ offset = 0, children, ...rest }) => {
    const style = { paddingLeft: `${offset * 35}px` };
    return (
        <li style={ style } { ...rest }>
            <NodeMeta>{ children }</NodeMeta>
        </li>
    );
};

const NodeCompactToggler = ({ offset = 0, path, hidden, onVisit, ...rest }) => (
    <div className="node node-toggler" onClick={ () => onVisit(path) } { ...rest }>
        <Checkbox onFocus={ () => onVisit(path) }/>
        <NodeStatic offset={ offset }>Show { hidden } more...</NodeStatic>
    </div>
);


const TreeRoot = ({ children, meta = {}, hiddenCount, onUnCompact, isReady }) => {
    const { loading/*TODO:, error*/ } = meta;
    const isLoading = loading === true;
    const isEmpty = !isLoading && !(children.length);
    const isCompact = hiddenCount > 0;
    const onVisitToggler = (path) => onUnCompact(path, false);

    if (!isReady) { return null; }
    if (isLoading) { return <p>Loading...</p>; }
    if (isEmpty) { return <p>You don't have Application Whitelisting policy yet.</p>; }

    return (
        <ul className="file-tree">
            { children }
            { isCompact && <NodeCompactToggler path="/" hidden={ hiddenCount } onVisit={ onVisitToggler }/> }
        </ul>
    );
};


const FileNode = ({ label, packageId, twinFiles }) => {
    let twinsMeta = null;
    if (twinFiles && twinFiles > 0) {
        twinsMeta = <NodeMeta title={ `+${ twinFiles } more file(s) with identical hash` }>{ `+${ twinFiles }` }</NodeMeta>
    }

    let packageLabel = null, iconStyle = {};
    if (packageId !== 'Default') {
        iconStyle = { color: stringToHSL(packageId) };
        packageLabel = <PackageLabel packageId={ packageId } title={ `Package: ${ packageId }` }/>;
    }

    return (
        <div className="node-label text-nowrap">
            <Icon icon="fw file-text-o" classes="node-icon" style={ iconStyle }/> { label } { twinsMeta } { packageLabel }
        </div>
    );
};

const DirNode = ({ label, total, allowed, isExpanded }) => {
    const icon = (isExpanded ? 'fw folder-open-o' : 'fw folder-o');

    // TODO - when updated on allow/block - add allowed label
    const totalLabel = `(${total} file${ total > 1 ? 's' : '' })`;
    const totalHint = `Total number of files inside this dir`;
    const totalMeta = <NodeMeta title={ totalHint }>{ totalLabel }</NodeMeta>;

    return (
        <div className="node-label text-nowrap">
            <Icon icon={ icon } classes="node-icon"/> { label } { totalMeta }
        </div>
    );
};


class Node extends Component {
    static propTypes = {
        node: PropTypes.object.isRequired,
        offset: PropTypes.number.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isExpanded: PropTypes.bool,
        isLoaded: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
        onLoadMore: PropTypes.func.isRequired,
        onExpand: PropTypes.func,
        onCompact: PropTypes.func,
    };

    static toChildNodes = ({ _nodes = [], _meta, selectedNode, treeState, onExpand, onUnCompact, ...rest }, prefix = 'node', offset = 0) => {
        return _nodes.reduce((acc, it, i) => {
            const key = `${prefix}-${i}`;
            const { isFile, label, data, _nodes } = it;
            const { path, packageId = '' } = data;
            const isSelected = !!selectedNode && selectedNode.nodeId === `${packageId}:${path}`;
            const node = { isFile, label, ...data };

            if (isFile) {
                return [
                    ...acc,
                    <Node key={ key } node={ node } isSelected={ isSelected } offset={ offset } { ...rest }/>
                ];
            }

            const isExpanded = treeState.expanded[path];
            const { loading: isLoading } = _meta[path] || {};
            const isLoaded = !isLoading && _nodes.length > 0;
            const dirNode = (
                <Node key={ key } node={ node }
                    isSelected={ isSelected }
                    isExpanded={ isExpanded }
                    isLoaded={ isLoaded }
                    onExpand={ onExpand }
                    offset={ offset } { ...rest }/>
            );

            if (!isExpanded) {
                return [
                    ...acc,
                    dirNode,
                ];
            }

            if (isLoading) {
                return [
                    ...acc,
                    dirNode,
                    <NodeStatic key={ key + '-load' } offset={ offset + 1 }>Loading...</NodeStatic>,
                ];
            }

            let hiddenCount = 0;
            let visibleNodes = _nodes;
            let togglerNode = null;
            const isCompact = (treeState.compacted[path] !== false) && _nodes.length > compactLimit;

            if (isCompact) {
                hiddenCount = _nodes.length - compactLimit;
                visibleNodes = _nodes.slice(0, compactLimit);
                togglerNode = (
                    <NodeCompactToggler key={ key + '-load' } offset={ offset + 1 }
                        path={ path }
                        hidden={ hiddenCount }
                        onVisit={ (path) => onUnCompact(path, false) }/>
                );
            }

            return [
                ...acc,
                dirNode,
                ...Node.toChildNodes({
                    _nodes: visibleNodes, _meta, selectedNode, treeState, onExpand, onUnCompact, ...rest
                }, key, offset + 1),
                togglerNode,
            ];
        }, []);
    };

    componentDidUpdate(prevProps) {
        const { node: { isFile, path }, isSelected, isLoaded, onLoadMore } = this.props;
        const justFocused = !prevProps.isSelected && isSelected;

        if (justFocused) {
            this._checkbox.focus();

            if (!isFile && !isLoaded) {
                onLoadMore(path);
            }
        }
    }

    handleKeyNav(e) {
        const { node: { isFile, path }, isExpanded, onExpand } = this.props;
        const checkboxEl = this._checkbox.ref();
        const nodeEl = checkboxEl.closest('.node'); // TODO - IE has no .closest()

        const pad = (el) => parseInt((el.style || {}).paddingLeft, 10) || 0;
        const findParent = (nodeEl) => {
            const nodePad = pad(nodeEl);
            let el = nodeEl.previousSibling;
            while (el && pad(el) === nodePad) { el = el.previousSibling; }
            return el;
        };
        const setExpanded = (expanded) => onExpand(path, expanded);

        let nextNodeEl;
        switch (e.key) {
            case 'ArrowDown':
                nextNodeEl = nodeEl.nextSibling || nodeEl.parentNode.firstChild;
                break;
            case 'ArrowUp':
                nextNodeEl = nodeEl.previousSibling || nodeEl.parentNode.lastChild;
                break;
            case 'ArrowRight':
                if (isFile) { break; }
                if (isExpanded) {
                    nextNodeEl = nodeEl.nextSibling;
                }
                else {
                    setExpanded(true);
                }
                break;
            case 'ArrowLeft':
                if (isExpanded) {
                    setExpanded(false);
                }
                else {
                    nextNodeEl = findParent(nodeEl);
                }
                break;
            default:
                break;
        }

        if (nextNodeEl) {
            const nextCheckboxEl = nextNodeEl.querySelector('input[type=checkbox]');
            if (nextCheckboxEl) {
                nextCheckboxEl.focus();
            }
        }
    }

    handleSelect() {
        const { node: { isFile, path, packageId = ''}, onSelect } = this.props;
        const kind = isFile ? 'file' : 'dir';
        const nodeId = `${packageId}:${path}`;

        onSelect(nodeId, kind);
    }

    render() {
        const { node, offset, isSelected, isExpanded, onExpand } = this.props;
        const { isFile, label, path } = node;
        const style = offset ? { paddingLeft: `${ offset * 25 }px` } : {};
        const onFocus = (e) => this.handleSelect();
        const onClick = (e) => this._checkbox.focus(); // triggers onFocus
        const onDoubleClick = (e) => {
            this.handleSelect();
            !isFile && onExpand(path, !isExpanded);
        };
        const onKeyDown = (e) => this.handleKeyNav(e);

        let nodeEl;
        if (isFile) {
            const { twinFiles, packageId } = node;

            nodeEl = (
                <FileNode label={ label }
                    packageId={ packageId }
                    twinFiles={ twinFiles }
                />
            );
        } else {
            const { totalFiles, allowedFiles } = node;

            nodeEl = (
                <DirNode label={ label }
                    total={ totalFiles }
                    allowed={ allowedFiles }
                    isExpanded={ isExpanded }
                />
            );
        }

        return (
            <li className={ cs('node', { selected: isSelected }) } style={ style }>
                <div className="node-controls" onClick={ onClick } onDoubleClick={ onDoubleClick }>
                    <Checkbox ref={ c => this._checkbox = c }
                        checked={ isSelected }
                        onChange={ onClick }
                        onFocus={ onFocus }
                        onKeyDown={ onKeyDown }/>
                    { nodeEl }
                </div>
            </li>
        );
    }
}


class FileTree extends Component {

    static propTypes = {
        tree: PropTypes.object.isRequired,
        selectedNode: PropTypes.object,
        onSelect: PropTypes.func.isRequired,
        onLoadMore: PropTypes.func.isRequired,
    };

    state = {
        expanded: {},
        compacted: {},
        rootCompact: false,
    };

    componentDidUpdate(prevProps) {
        const { _nodes: _prevNodes = [] } = prevProps.tree;
        const { _nodes } = this.props.tree;
        const justLoaded = _nodes.length > _prevNodes.length;

        if (justLoaded) {
            const rootCompact = _nodes.length > compactLimit;
            this.setState({ rootCompact });
        }
    }

    togglePathFlag = flag => (path, isTrue) => {
        this.setState({
            [flag]: {
                ...this.state[flag],
                [path]: isTrue,
            }
        });
    };

    render() {
        const { tree, ...rest } = this.props;
        const { rootCompact, ...stateRest } = this.state;
        const { _nodes = [], _meta = {} } = tree;
        const isReady = tree._nodes !== undefined;
        const rootMeta = _meta['/'];
        const onExpand = this.togglePathFlag('expanded');
        const onUnCompact = this.togglePathFlag('compacted');
        const onUnCompactRoot = () => this.setState({ rootCompact: false });

        let hiddenCount = 0;
        let visibleNodes = _nodes;
        if (rootCompact) {
            hiddenCount = _nodes.length - compactLimit;
            visibleNodes = _nodes.slice(0, compactLimit);
        }

        return (
            <TreeRoot meta={ rootMeta } hiddenCount={ hiddenCount } onUnCompact={ onUnCompactRoot } isReady={ isReady }>
                { Node.toChildNodes({ _nodes: visibleNodes, _meta, treeState: stateRest, onExpand, onUnCompact, ...rest }) }
            </TreeRoot>
        );
    }
}

export default FileTree;
