import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { NavbarToggler } from 'reactstrap';
import { connect } from 'react-redux';
import { getUnreadActionsNum } from '../../redux/modules/actions';
import { toggleSideMenu } from "../../redux/modules/common";


const mapStateToProps = (state) => {
    return {
        unreadActionsNum: getUnreadActionsNum(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggleSideMenu: () => dispatch(toggleSideMenu()),
    };
};

class MenuToggler extends Component {
    static propTypes = {
        // from connect
        unreadActionsNum: PropTypes.number.isRequired,
        toggleSideMenu: PropTypes.func.isRequired,
    };

    render() {
        const { toggleSideMenu, unreadActionsNum } = this.props;
        const onMenuClick = () => toggleSideMenu();

        return (
            <NavbarToggler
                className={ cs('navbar-inverse', { 'has-actions': unreadActionsNum > 0 }) }
                onClick={ onMenuClick }/>
        );
    }
}

const MenuTogglerContainer = connect(mapStateToProps, mapDispatchToProps)(MenuToggler);

export default MenuTogglerContainer;
