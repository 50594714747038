import { createSelector } from "reselect";

const USER_UPDATE = 'user/UPDATE';

const USER_FETCH = 'user/FETCH';
const USER_FETCH_SUCCESS = 'user/FETCH_SUCCESS';
const USER_FETCH_FAIL = 'user/FETCH_FAIL';

const USER_ADD_FILTER = 'user/SAVE_FILTER';
const USER_ADD_FILTER_SUCCESS = 'user/SAVE_FILTER_SUCCESS';
const USER_ADD_FILTER_FAIL = 'user/SAVE_FILTER_FAIL';

const USER_REMOVE_FILTER = 'user/REMOVE_FILTER';
const USER_REMOVE_FILTER_SUCCESS = 'user/REMOVE_FILTER_SUCCESS';
const USER_REMOVE_FILTER_FAIL = 'user/REMOVE_FILTER_FAIL';

const session = {
    get() {
        return window.localStorage['auth'];
    },
    set(val) {
        return window.localStorage['auth'] = !!val;
    },
};

const initialValue = {
    isAuthenticated: session.get(),
};

// Reducers
//
function reducer(state = initialValue, action = {}) {
    switch (action.type) {
        case USER_UPDATE:
            return {
                ...action.user,
            };
        case USER_ADD_FILTER_SUCCESS:
        case USER_REMOVE_FILTER_SUCCESS:
            return {
                ...state,
                ...action.result,
            };
        default:
            return state;
    }
}

// Action creators
//
export function updateUser(user = ({ isAuthenticated: false })) {
    session.set(user.isAuthenticated);

    return {
        type: USER_UPDATE,
        user
    };
}

export function fetchUser() {
    return {
        types: [USER_FETCH, USER_FETCH_SUCCESS, USER_FETCH_FAIL],
        promise: (client) => client.get('/api/user')
    };
}

export function addUserIncidentFilter(filter) {
    const data = { filter };
    return {
        types: [USER_ADD_FILTER, USER_ADD_FILTER_SUCCESS, USER_ADD_FILTER_FAIL],
        promise: (client) => client.post('/api/user/incident-filters', { data }),
    };
}

export function removeUserIncidentFilter(filterId) {
    return {
        types: [USER_REMOVE_FILTER, USER_REMOVE_FILTER_SUCCESS, USER_REMOVE_FILTER_FAIL],
        promise: (client) => client.del(`/api/user/incident-filters/${ filterId }`),
    };
}

// Selectors
//
const constIncidentFilters = [
    { label: 'All attacks', search: '?types=attacks' },
    { label: 'Heartbeats / today', search: '?dates=today&types=heartbeat' },
];

const getUser = (state) => state.user;
const getLocation = (_, props) => props.location;

const getUserIncidentFilters = createSelector(
    [getUser],
    (user) => {
        const savedIncidentFilters = user.incidentFilters || [];
        return [
            ...constIncidentFilters,
            ...savedIncidentFilters,
        ];
    }
);

export const canSaveCurrentIncidentFilter = createSelector(
    [getUserIncidentFilters, getLocation],
    (incidentFilters, location) => {
        return (
            location.pathname === '/attacks'
            && !!location.search
            && !incidentFilters
                .map(({ search }) => search)
                .includes(location.search)
        );
    }
);

export const canRemoveCurrentIncidentFilter = createSelector(
    [getUserIncidentFilters, getLocation],
    (incidentFilters, location) => {
        return (
            location.pathname === '/attacks'
            && !!location.search
            && !!(incidentFilters
                .find(({ search }) => search === location.search) || {})
                ._id // only filters stored in DB have _id
        );
    }
);

export const getUserIncidentFiltersForMenu = createSelector(
    [getUserIncidentFilters, canSaveCurrentIncidentFilter, getLocation],
    (incidentFilters, canSaveCurrentIncidentFilter, location) => {
        return [
            ...incidentFilters,
            ...(canSaveCurrentIncidentFilter ? [
                { label: 'Unsaved filter', search: location.search, isUnsaved: true },
            ] : []),
        ];
    }
);

export default reducer;
