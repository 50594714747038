import React from 'react';
import { parse as parseQS } from 'querystring';
import { Switch, Route } from 'react-router-dom';
import { Page, AuthPageLayout } from '../Layout'
import AuthLoginFormContainer from "../AuthLoginForm";
import AuthSignupFormContainer from "../AuthSignupForm";
import AuthSignupApprove from "../AuthSignupApprove";
import AuthSignupVerify from "../AuthSignupVerify";
import AuthRestoreStartFormContainer from "../AuthRestoreStartForm";
import AuthRestoreProceed from "../AuthRestoreProceed";
import AuthRestoreCompleteFormContainer from "../AuthRestoreCompleteForm";
import AuthInviteCompleteContainer from "../AuthInviteCompleteContainer";


const redirect = (history) => ({ to, replace }) => {
    const method = replace ? 'replace' : 'push';
    history[method](to);
};
const AuthLoginPage = ({ match: { params: { email } }, location: { search }, history }) => {
    const { e } = parseQS(search.slice(1)); // slice to remove `?` from `?qs`
    return (
        <Page title="Login">
            <AuthLoginFormContainer email={ email } isVerified={ e === 'verified' } redirect={ redirect(history) }/>
        </Page>
    );
};
const AuthSignupPage = ({ history }) => (
    <Page title="Signup">
        <AuthSignupFormContainer redirect={ redirect(history) }/>
    </Page>
);
const AuthSignupApprovePage = ({ match: { params: { email } }, location: { search } }) => {
    const { e: status } = parseQS(search.slice(1));
    return (
        <Page title="Signup">
            <AuthSignupApprove email={ email } status={ status }/>
        </Page>
    );
};
const AuthSignupVerifyPage = ({ match: { params: { email } }, location: { search } }) => {
    const { e: error } = parseQS(search.slice(1));
    return (
        <Page title="Signup">
            <AuthSignupVerify email={ email } error={ error }/>
        </Page>
    );
};
const AuthRestoreStartPage = ({ history }) => (
    <Page title="Restore">
        <AuthRestoreStartFormContainer redirect={ redirect(history) }/>
    </Page>
);
const AuthRestoreProceedPage = ({ match: { params: { email } }, location: { search } }) => {
    const { e: error } = parseQS(search.slice(1));
    return (
        <Page title="Restore">
            <AuthRestoreProceed email={ email } error={ error }/>
        </Page>
    );
};
const AuthRestoreCompletePage = ({ match: { params: { email } }, history }) => (
    <Page title="Restore">
        <AuthRestoreCompleteFormContainer email={ email } redirect={ redirect(history) }/>
    </Page>
);
const AuthInviteCompletePage = ({ match: { params: { email } }, location: { search }, history }) => {
    const { e: error } = parseQS(search.slice(1));
    return (
        <Page title="Registration">
            <AuthInviteCompleteContainer email={ email } error={ error } redirect={ redirect(history) }/>
        </Page>
    );
};

const AuthPage = () => (
    <AuthPageLayout>
        <Switch>
            <Route exact path="/login/:email?" component={ AuthLoginPage }/>
            <Route exact path="/signup" component={ AuthSignupPage }/>
            <Route exact path="/approve/:email?" component={ AuthSignupApprovePage }/>
            <Route exact path="/verify/:email?" component={ AuthSignupVerifyPage }/>
            <Route exact path="/restore" component={ AuthRestoreStartPage }/>
            <Route exact path="/restore-proceed/:email" component={ AuthRestoreProceedPage }/>
            <Route exact path="/restore-complete/:email" component={ AuthRestoreCompletePage }/>
            <Route exact path="/invite-complete/:email" component={ AuthInviteCompletePage }/>
        </Switch>
    </AuthPageLayout>
);

export default AuthPage;
