import React, { Component, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import fecha from "fecha";
import cs from "classnames";
import { CloseButton, SvgIcon, useInterval } from "../Elements";
import { ACItemTitle, ACItemBody } from "./ActionCenterItemContent";
import { timeAgo } from "../../../common/util";
import { E_SEVERITY_WARNING} from "../../../common/constants";
import "./ActionCenter.scss";


const ANIMATION_DURATION = 200;
const FRESHNESS_CHECK_INTERVAL = 10000; // every 10s
const FRESHNESS_PERIOD = 12 * 3600 * 1000; // less than 12h old is fresh
const isFreshDate = (date) => (new Date() - new Date(date)) < FRESHNESS_PERIOD;
const formatActionDate = (date) => fecha.format(new Date(date), 'MMM DD YYYY HH:mm');


const AutoUpdateDateLabel = ({ date, disabled = false }) => {
    const [isFresh, setIsFresh] = useState(isFreshDate(date));
    const [timeAgoDate, setTimeAgoDate] = useState(timeAgo(date));
    const regularDate = formatActionDate(date);
    const interval = (isFresh && !disabled) ? FRESHNESS_CHECK_INTERVAL : null;

    useEffect(() => {
        setTimeAgoDate(timeAgo(date));
    }, [disabled, date]);

    useInterval(() => {
        const stillFresh = isFreshDate(date);
        if (stillFresh) {
            setTimeAgoDate(timeAgo(date));
        }
        else {
            setIsFresh(false); // stop interval & show regular date
        }
    }, interval);

    return (
        <div className="ac-item-date" title={ regularDate }>
            { isFresh ? timeAgoDate : regularDate }
        </div>
    )
};

class ActionCenterItem extends Component {
    static propTypes = {
        event: PropTypes.shape({
            isNew: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired,
            severity: PropTypes.string.isRequired,
            creationDate: PropTypes.string.isRequired,
        }),
        isVisible: PropTypes.bool.isRequired,
        isAdding: PropTypes.bool.isRequired,
        isRemoving: PropTypes.bool.isRequired,
        onDismiss: PropTypes.func.isRequired,
    };

    state = {
        animateClass: null,
    };

    componentDidMount() {
        const { isVisible, isAdding } = this.props;

        if (isVisible && isAdding) {
            this._runAnimation('enter');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const isVisibleChanged = (this.props.isVisible !== nextProps.isVisible);
        const isAddingChanged = (this.props.isAdding !== nextProps.isAdding);
        const isRemovingChanged = (this.props.isRemoving !== nextProps.isRemoving);
        const isNewChanged = (this.props.event.isNew !== nextProps.event.isNew);
        const isAnimateClassChanged = (this.state.animateClass !== nextState.animateClass);

        // Ignoring new values of event, onDismiss as they change on items add/remove
        //
        return (isVisibleChanged || isAddingChanged || isRemovingChanged || isNewChanged || isAnimateClassChanged);
    }

    componentDidUpdate(prevProps) {
        const { isVisible, isRemoving } = this.props;
        const justRemoved = !prevProps.isRemoving && isRemoving;

        if (isVisible && justRemoved) {
            this._runAnimation('exit');
        }
    }

    componentWillUnmount() {
        cancelAnimationFrame(this._raf);
        clearTimeout(this._animate_timeout);
    }

    _runAnimation(name) {
        this.setState({ animateClass: `ac-animate-${ name }` }, () => {
            this._raf = requestAnimationFrame(() => {
                this.setState({ animateClass: `ac-animate-${ name } ac-animate-${ name }-active` });

                clearTimeout(this._animate_timeout);

                this._animate_timeout = setTimeout(() => {
                    this.setState({ animateClass: null });
                }, ANIMATION_DURATION);
            });
        });
    }

    render() {
        const { event, isVisible, onDismiss } = this.props;
        const { type, isNew, severity, creationDate } = event;
        const { animateClass } = this.state;
        const icon = (severity === E_SEVERITY_WARNING) ? 'ac-warning' : 'ac-info-white';

        return (
            <div className={ cs('ac-item', animateClass, { 'unread': isNew }) }>
                <CloseButton onClick={ onDismiss }/>
                <SvgIcon icon={ icon } sz="md"/>
                <h5 className="ac-item-title">
                    <ACItemTitle type={type}/>
                </h5>
                <ACItemBody {...event}/>
                <AutoUpdateDateLabel date={ creationDate } disabled={ !isVisible }/>
            </div>
        );
    }
}

export default ActionCenterItem;
