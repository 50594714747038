import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Table, Button } from "reactstrap";
import { Icon } from "../Elements";
import "./ExclusionList.scss";


class ExclusionList extends Component {

    static propTypes = {
        list: PropTypes.array.isRequired,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
    };

    renderTable() {
        const { list, onEdit, onDelete } = this.props;

        const rows = list.map((item, i) => {
            const { _id, type, target, description } = item;
            const isEditable = !!_id; // non-editable items explicitly has _id missing
            const disabledClass = !isEditable ? 'disabled' : '';
            const disabledLabel = !isEditable ? ' (disabled)' : '';
            const onEditClick = () => isEditable && onEdit(item);
            const onDeleteClick = () => isEditable && onDelete(_id);

            return (
                <tr key={ `er-${i}` } className={ disabledClass }>
                    <td className="td-target" title={ target }>
                        <div className="standout">{ target }</div>
                        <div className="small">Type: { type }</div>
                    </td>
                    <td className="td-descr">
                        <div title={ description }>{ description }</div>
                    </td>
                    <td className={ `td-edit ${disabledClass}` } onClick={ onEditClick }>
                        <span title={ `Edit${ disabledLabel }` }><Icon icon="edit"/></span>
                    </td>
                    <td className={ `td-edit ${disabledClass}` } onClick={ onDeleteClick }>
                        <span title={ `Delete${ disabledLabel }` }><Icon icon="trash"/></span>
                    </td>
                </tr>
            )
        });

        return (
            <Table>
                <thead>
                <tr>
                    <th className="td-target">Target / Type</th>
                    <th>Description</th>
                    <th colSpan="2"/>
                </tr>
                </thead>
                <tbody>
                { rows }
                </tbody>
            </Table>
        );
    }

    render() {
        const { list, onEdit } = this.props;
        const onAddBtnClick = () => onEdit(null);
        const isEmpty = (list.length === 0);

        const emptyTable = (
            <div className="exclusion-empty">
                <p>You don't have any exclusion items.</p>
            </div>
        );

        return (
            <div className="exclusion-list clearfix">
                { isEmpty ? emptyTable : this.renderTable() }
                <Button color="danger mt-3 pull-right" onClick={ onAddBtnClick }>New Exclusion</Button>
            </div>
        );
    }
}

export default ExclusionList;
