import React from 'react';
import cs from 'classnames';
import { Table } from 'reactstrap';
import { action2text, type2text, formatTimeShort } from '../incident-utils';
import './AttackList.scss';

const EmptyList = ({ children }) => (
    <div className="attacks-list-empty">{ children }</div>
);

const AttackListRow = ({ action, type, creationDate, description, severity = 'N/A', deviceId = 'N/A', onClick }) => {
    const actionStr = action2text(action);
    const typeText = type2text(type);
    const serverTimeStr = formatTimeShort(creationDate);
    const isDimmed = (severity === 'Information');

    return (
        <tr className={ cs('clickable', { dimmed: isDimmed }) } onClick={ onClick }>
            <td className="nowrap">
                <span title={ deviceId }>{ deviceId }</span>
            </td>
            <td className="nowrap">
                <span title={ severity }>{ severity }</span>
            </td>
            <td className="nowrap">
                <span title={ actionStr }>{ actionStr }</span>
            </td>
            <td className="nowrap">
                <span title={ typeText }>{ typeText }</span>
            </td>
            <td className="nowrap">
                <span title={ description }>{ description }</span>
            </td>
            <td className="nowrap">
                <span title={ serverTimeStr }>{ serverTimeStr }</span>
            </td>
        </tr>
    );
};

const AttackListRowPlaceholder = ({ cells }) => {
    return (
        <tr className="placeholder">
            { [...Array(cells)].map((_, i) => {
                const width = 60 + Math.floor(Math.random() * 20); // 60-80%
                return (
                    <td key={ `phbar-${ i }` }>
                        <span className="bar" style={ { width: width + '%' } }/>
                    </td>
                );
            }) }
        </tr>
    );
};

const AttackList = ({ list = [], isLoading = false, isEmpty = false, onVisit }) => {
    if (isLoading) {
        return (
            <div className="attack-list">
                <EmptyList>
                    <span>Loading...</span>
                </EmptyList>
            </div>
        );
    }

    if (isEmpty) {
        return (
            <div className="attack-list">
                <EmptyList>No items found</EmptyList>
            </div>
        );
    }

    return (
        <div className="attack-list">
            <Table hover>
                <thead>
                <tr>
                    <th className="device-id">Device ID</th>
                    <th className="severity">Severity</th>
                    <th className="action">Action</th>
                    <th className="type">Type</th>
                    <th className="details">Details</th>
                    <th className="time">Time</th>
                </tr>
                </thead>
                <tbody>
                { list.map((item, i) => {
                    return !!item ? (
                        <AttackListRow
                            key={ item._id }
                            onClick={ () => onVisit(item) }
                            { ...item }
                        />
                    ) : (
                        <AttackListRowPlaceholder
                            key={ `phtr-${ i }` }
                            cells={ 6 }
                        />
                    );
                }) }
                </tbody>
            </Table>
        </div>
    );
};
export default AttackList;
