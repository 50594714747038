import { toggleAlert } from '../modules/alert';
import { ALERT_WARNING } from '../../components/Alert';

const hideAlert = (dispatch) => {
    dispatch(toggleAlert(false));
};

const showFailAlert = (dispatch) => (body) => {
    const message = (body || {}).error || 'No connection to server';
    const params = {
        type: ALERT_WARNING,
        title: 'Failed:',
        message,
        onClose: () => hideAlert(dispatch),
    };

    dispatch(toggleAlert(true, params));
};

// Creates middleware that accepts actions of the following format:
// {
//      promise: (client) => client[method]('/api/some', { query, data } ) // returns promise that calls API with superagent
//      types: [T, T_SUCCESS, T_FAIL]       // actual action types to dispatch: [start, success, fail]
//      debounce:                           // TODO
//      noAlert:                            // (optional) suppress dispatching toggleAlert() on API error automatically
//      ...rest                             // optional action data
// }

export default function createApiMiddleware(client) {
    let debounced = {};

    return ({ dispatch, getState }) => next => action => {
        const { promise, types, debounce, noAlert = false, ...rest } = action;

        if (!promise) {
            return next(action); // non-promise
        }

        const fetch = (data) => {
            const [start, success, fail] = types;
            const { alert } = getState();

            if (alert.show) {
                hideAlert(dispatch);
            }

            // start loading
            next({ type: start, ...rest });

            // return Promise to wait
            return promise(client, data)
                .then(
                    ({ body }) => next({ type: success, result: body, ...rest }),
                    ({ err, body }) => {
                        const { status } = err;
                        // if noAlert=true -> suppress all errors
                        // if noAlert=404 -> suppress 404 status err
                        const showAlert = (noAlert === true) ? false : (noAlert !== status);

                        if (showAlert) {
                            setTimeout(() => showFailAlert(dispatch)(body), 100); // delay a bit
                        }

                        return next({ type: fail, error: body, ...rest });
                    },
                ).catch(
                    (error) => next({ type: fail, error, ...rest }),
                );
        };

        if (!debounce) {
            return fetch();
        }
        else {
            // API debouncing logic with accumulating results of individual dispatches
            const { key, wait, acc2data } = debounce;
            const debInstance = debounced[key] = debounced[key] || { acc: [] };
            if (debInstance.timeout) {
                clearTimeout(debInstance.timeout);
            }

            return new Promise(resolve => {
                debInstance.acc.push(rest); //accumulate params of debounced actions
                debInstance.timeout = setTimeout(() => {
                    resolve(debInstance.acc);
                    debInstance.acc = [];
                }, wait);
            }).then(
                (acc) => fetch(acc2data(acc)),
            );
        }
    };
}
