import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Toggler.scss';


class Toggler extends Component {

    static propTypes = {
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const { checked, onChange } = this.props;
        const checkedClass = checked ? 'checked' : '';

        return (
            <div className="toggler" role="button" onClick={ onChange }>
                <div className={ `gripper ${checkedClass}` }></div>
            </div>
        );
    }
}

export default Toggler;
