import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Label, Input, FormGroup, FormFeedback, FormText } from "reactstrap";
import Captcha from "./Captcha";


export { Captcha }; // re-export

// Usage: validate(validators)(values) => {field?: error}
// Examples:
// validate([ 'title' ])({ title: ''}) => { title: 'title is required' }
// validate([ { title: '* required' } ])({ title: ''}) => { title: '* required' }
// validate([ { password: (p) => p.length < 5 && 'should be at least 5 chars' } ])({ password: '123'}) => { password: 'should be at least 5 chars' }
//
export const validate = (validators = []) => {
    // normalize to [{ field: 'str', checker: fn}]
    const validatorsNormalized = validators.map((validator) => {
        let field, checker;
        if (typeof validator === 'string') {
            field = validator;
            checker = `${validator} is required`
        }
        else if (typeof validator === 'object') {
            field = Object.keys(validator)[0];
            checker = validator[field];
        }

        if (typeof checker === 'string') {
            checker = ((str) => (fn) => !fn && str)(checker);
        }

        return { field, checker };
    });

    return (values) => {
        const errors = {};
        validatorsNormalized.forEach(({ field, checker }) => {
            const error = checker(values[field], values);
            if (error) {
                errors[field] = errors[field] || error;
            }
        });

        return errors;
    }
};


export class InputGroup extends Component {

    static propTypes = {
        // from Field
        type: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        helpText: PropTypes.node,
        maxLength: PropTypes.string,
        // from redux-form
        input: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
    };

    focus() {
        this._input.focus();
    }

    render() {
        const { type, label, placeholder, helpText, maxLength = 80, input, meta: { touched, error } } = this.props;
        const { name } = input;
        const showError = (touched && error);
        const labelClass = !label ? 'sr-only' : '';
        const errorLabel = showError ? <FormFeedback>{ error }</FormFeedback> : null;
        const helpLabel = helpText ? <FormText className="text-help">{ helpText }</FormText> : null;

        return (
            <FormGroup>
                <Label for={ name } className={ `text-muted ${labelClass}` }>{ label || placeholder }</Label>
                <Input type={ type } id={ name } maxLength={ maxLength }
                    invalid={ !!showError }
                    innerRef={ (c) => this._input = c }
                    { ...input } />
                { errorLabel }
                { helpLabel }
            </FormGroup>
        );
    }
}

export class SelectGroup extends Component {

    static propTypes = {
        // from Field
        options: PropTypes.array.isRequired,
        label: PropTypes.string,
        // from redux-form
        input: PropTypes.object.isRequired,
    };

    render() {
        const { label, options, input } = this.props;
        const { name } = input;
        const labelClass = !label ? 'sr-only' : '';
        const optionsEls = options.map((val, i) => {
            return <option key={ `opt-${i}` }>{ val }</option>;
        });

        return (
            <FormGroup>
                <Label for={ name } className={ `text-muted ${labelClass}` }>{ label }</Label>
                <Input type="select" id={ name } { ...input }>
                    { optionsEls }
                </Input>
            </FormGroup>
        );
    }
}

export class TextareaGroup extends Component {

    static propTypes = {
        // from Field
        label: PropTypes.string,
        placeholder: PropTypes.string,
        maxLength: PropTypes.string,
        // from redux-form
        input: PropTypes.object.isRequired,
    };

    render() {
        const { label, placeholder, maxLength = 300, input } = this.props;
        const { name } = input;
        const labelClass = !label ? 'sr-only' : '';

        return (
            <FormGroup>
                <Label for={ name } className={ `text-muted ${labelClass}` }>{ label }</Label>
                <Input type="textarea" id={ name } placeholder={ placeholder } maxLength={ maxLength } { ...input }/>
            </FormGroup>
        );
    }
}
