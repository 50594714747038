import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { InboxIcon } from "../Elements"


class AuthSignupApprove extends Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    };

    render() {
        const { email, status } = this.props;
        const isPending = status === 'pending';
        const isApproved = status === 'approved';
        const isApprovedNoop = status === 'approved-noop';
        const hasError = status === 'not-approved';
        const hasIcon = isPending || isApproved;
        const title = isPending ? 'Registration approval is pending' :
            (isApproved || isApprovedNoop) ? 'Registration is approved!' :
                hasError ? 'Registration is not approved!' : 'Bad option';

        return (
            <div className="verify">
                { hasError && (
                    <div className="critical-error">Oops! There is something wrong with this link.
                        Please make sure you copy the entire link from the email in order to proceed with approval.
                        User is not approved still.
                    </div>
                )}

                <h4 className="mb-3 text-muted">{ title }</h4>

                { isPending && (
                    <p>Your registration request has been received. We will process it as soon as possible and send an email
                        to <span className="text-muted">{ email }</span> to enable you to proceed.</p>
                )}

                { isApproved && (
                    <p>Registration is approved and we've sent an email to <span className="text-muted">{ email }</span> to let them
                        proceed.</p>
                )}

                { isApprovedNoop && (
                    <p>Registration was approved already. This is a noop.</p>
                )}

                { hasIcon && (
                    <p className="text-center">
                        <InboxIcon/>
                    </p>
                )}

                <p className="form-text text-muted">
                    <Link to="/login">Back to login</Link>
                </p>
            </div>
        );
    }
}

export default AuthSignupApprove;

