import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { Icon } from "../../Elements";
import "./AttackListPager.scss";

const AttackListPager = ({ from, to, isFirstPage, isLastPage, onClickNewer, isLoading = false, onClickOlder }) => {
    return (
        <div className="attack-list-pager">
            <span>{ from }–{ to }</span>
            <Button size="sm" title="Newer"
                disabled={ isFirstPage || isLoading  }
                onClick={ onClickNewer }
            >
                <Icon icon="chevron-left" classes="menu-chevron"/>
            </Button>
            <Button size="sm" title="Older"
                disabled ={ isLastPage || isLoading }
                onClick={ onClickOlder }
            >
                <Icon icon="chevron-right" classes="menu-chevron"/>
            </Button>
        </div>
    );
};

AttackListPager.propTypes = {
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
    isFirstPage: PropTypes.bool.isRequired,
    isLastPage: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    onClickNewer: PropTypes.func.isRequired,
    onClickOlder: PropTypes.func.isRequired,
};

export default AttackListPager;
