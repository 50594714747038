import React, { Component } from "react";
import PropTypes from 'prop-types';
import cs from "classnames";
import fecha from "fecha";
import { connect } from "react-redux";
import { ModalBody, ModalFooter, Alert, NavLink, Button } from "reactstrap";
import { generateApiKey } from "../../redux/modules/project-details";
import { fetchProjectInstallerList, getSelectedPackage, selectPackage } from "../../redux/modules/project-installer";
import ProjectDownloadModal from "./ProjectDownloadModal";
import ProjectDownloadFormButton from "./ProjectDownloadFormButton";
import ButtonWithPrompt from "./ButtonWithPrompt";
import { Icon, IconWithTooltip, Hint } from "../Elements";
import { host } from "../../../config";


const mapStateToProps = (state, { projectId }) => {
    return {
        projectDetails: (state.project.details[projectId] || {}).data,
        packageList: state.project.installer.list,
        selectedPackage: getSelectedPackage(state),
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        fetchProjectInstallerList: () => dispatch(fetchProjectInstallerList(projectId)),
        generateApiKey: () => dispatch(generateApiKey(projectId)),
        selectPackage: (packageName) => dispatch(selectPackage(packageName)),
    };
};

const formatTimeShort = (timeStr) => fecha.format(new Date(timeStr), 'MMM DD YYYY');

const ProjectInstallerMeta = ({ data, isSelected, onClick = () => null }) => {
    const { packageName, version, modified } = data;
    const date = formatTimeShort(modified);

    return (
        <div className={ cs('package-meta', { selected: isSelected }) } onClick={ onClick }>
            <h6>{ packageName }</h6>
            <small>{ version } / { date }</small>
        </div>
    );
};


class ProjectDownloadSection extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        helpText: PropTypes.string,
        children: PropTypes.node.isRequired,
        hasError: PropTypes.bool,
    };

    render() {
        const { name, title, helpText, children, hasError = false } = this.props;

        return (
            <div className={ cs("download-section", { 'has-error': hasError }) }>
                <h6 className="download-section-title">
                    { title }
                    { helpText && (
                        <>{ ` ` }<Hint id={ name }>{ helpText }</Hint></>
                    ) }
                </h6>
                { children }
            </div>
        );
    }
}


class ProjectDownloadBody extends Component {
    static propTypes = {
        icon: PropTypes.node,
        children: PropTypes.node.isRequired,
        withScroll: PropTypes.bool,
    };

    render() {
        const { icon, children, withScroll = false } = this.props;

        return (
            <div className={ cs('download-body', { 'with-scroll': withScroll }) }>
                { icon && (
                    <span className="download-body-icon">{ icon }</span>
                ) }
                { children }
            </div>
        );
    }
}


class ProjectInstallerMetaList extends Component {
    static propTypes = {
        list: PropTypes.array.isRequired,
        selectedPackageName: PropTypes.string.isRequired,
        onSelect: PropTypes.func.isRequired,
    };

    render() {
        const { list, selectedPackageName, onSelect } = this.props;

        return (
            <div className="download-package-list">
                { list.map((data) => {
                    const { packageName } = data;
                    const onClick = () => onSelect(packageName);
                    const isSelected = (packageName === selectedPackageName);
                    return (
                        <ProjectInstallerMeta key={ packageName }
                            data={ data }
                            isSelected={ isSelected }
                            onClick={ onClick }
                        />
                    );
                }) }
            </div>
        );
    }
}

class ProjectDownloadModalContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        // from connect
        projectDetails: PropTypes.object.isRequired,
        packageList: PropTypes.object.isRequired,
        selectedPackage: PropTypes.object,
        fetchProjectInstallerList: PropTypes.func.isRequired,
        selectPackage: PropTypes.func.isRequired,
        generateApiKey: PropTypes.func.isRequired,
    };

    state = {
        downloadError: null,
        showSelectionList: false,
    };

    componentDidUpdate(prevProps) {
        const { isOpen, fetchProjectInstallerList } = this.props;
        const { isOpen: prevIsOpen } = prevProps;
        const justOpened = !prevIsOpen && isOpen;

        if (justOpened) {
            fetchProjectInstallerList();
        }
    }

    showDownloadError = (message) => {
        this.setState({ downloadError: message });
    };

    hideDownloadError = () => {
        this.setState({ downloadError: null });
    };

    toggleSelectionList = () => {
        this.setState({ showSelectionList: !this.state.showSelectionList });
    };

    renderSelectedPackageSection() {
        const { packageList: { loading, error }, selectedPackage } = this.props;
        const hasError = !!error;
        const hasData = !loading && !error && !!selectedPackage;

        const bodyIcon = !hasError ? (
            <Hint id="pkg-ico" icon="fw file-zip-o" placement="left">GZip file</Hint>
        ) : (
            <Hint id="pkg-err" icon="fw exclamation-triangle" placement="left">Unavailable</Hint>
        );
        const helpText = !hasError
            ? 'The Install Package contains everything you need to install the product onto your build system.'
            : 'The Install Package cannot be downloaded: request it from Karamba. You can download the project-configuration portion' +
            ' of a Config File and manually apply it after the product has been installed.';

        let bodyContent = 'Loading...';
        if (hasError) {
            bodyContent = (
                <div className="package-meta">
                    Not available due to external service error:<br/>
                    <strong>{ error.message }</strong>
                </div>
            );
        }
        else if (hasData) {
            bodyContent = (
                <ProjectInstallerMeta data={ selectedPackage }/>
            );
        }

        return (
            <ProjectDownloadSection
                name="package"
                title="Install Package"
                helpText={ helpText }
                hasError={ hasError }
            >
                { hasData && (
                    <NavLink href="#" className="link-show-more" onClick={ this.toggleSelectionList }>More packages</NavLink>
                ) }
                <ProjectDownloadBody icon={ bodyIcon }>
                    { bodyContent }
                </ProjectDownloadBody>
            </ProjectDownloadSection>
        );
    }

    renderConfigSection() {
        const { projectId, packageList: { error }, generateApiKey } = this.props;
        const {
            apiKeySanitized = {},
            reportApiKeySanitized = null, // optional, exists only for projects created after v0.6+
        } = this.props.projectDetails;
        const hasError = !!error;

        const bodyIcon = (
            <Hint id="conf-ico" icon="fw file-text-o" placement="left">Text file</Hint>
        );
        const helpText = !hasError
            ? 'The Config File is enclosed within the Install Package download. This ensures that each specific package comes' +
            ' uniquely pre-configured'
            : 'Optionally download project-configuration portion of a Config File (for example, if key rotation is needed) to manually' +
            ' update the relevant part of the Config File for an installation.';

        return (
            <ProjectDownloadSection
                name="config"
                title="Config File"
                helpText={ helpText }
            >
                <ProjectDownloadBody icon={ bodyIcon }>
                    <div className="download-record">{ `KMS_HOST=${ host }` }</div>
                    <div className="download-record">{ `KMS_PROJECT_ID=${ projectId }` }</div>
                    <div className="download-record-group">
                        <div className="download-record">{ `KMS_API_KEY_ID=${ apiKeySanitized.id || '' }` }</div>
                        <div className="download-record">{ `KMS_API_KEY_SECRET=${ apiKeySanitized.secret || '' }` }</div>
                        { /*New key btn*/ }
                        <ButtonWithPrompt
                            btnInner={
                                <IconWithTooltip id="gen-key" icon="fw key" placement="top">Generate new API Key</IconWithTooltip>
                            }
                            onConfirm={ generateApiKey }>
                            <Icon icon="exclamation-triangle"/> Generate new API Key?
                            The client machine will not be able to communicate with the server until this new key is specified in the config
                            file!
                        </ButtonWithPrompt>
                    </div>
                    {reportApiKeySanitized && (
                        <>
                            <div className="download-record">{ `KMS_REPORT_API_KEY_ID=${ reportApiKeySanitized.id || '' }` }</div>
                            <div className="download-record">{ `KMS_REPORT_API_KEY_SECRET=${ reportApiKeySanitized.secret || '' }` }</div>
                        </>
                    )}
                </ProjectDownloadBody>
            </ProjectDownloadSection>
        );
    }

    renderDownloadFormButton() {
        const { projectId, packageList: { loading, error }, selectedPackage } = this.props;
        const packageDownloadDisabled = !loading && error;
        const target = packageDownloadDisabled ? 'config' : 'package';
        const url = `/download/projects/${ projectId }/agent-install/${ target }`;

        const buttonLabel = (loading) ? 'Download...' : 'Download';

        let jsonParam;
        if (!packageDownloadDisabled && selectedPackage) {
            const { channel, packageName } = selectedPackage;
            jsonParam = {
                channel,
                packageName,
            };
        }

        return (
            <ProjectDownloadFormButton
                disabled={ loading }
                label={ buttonLabel }
                url={ url }
                param={ jsonParam && JSON.stringify(jsonParam) }
                onError={ this.showDownloadError }
            />
        );
    }

    renderSelectionListSection() {
        const {
            packageList: { data },
            selectedPackage: { packageName: selectedPackageName },
            selectPackage,
        } = this.props;

        return (
            <ProjectDownloadSection
                name="package"
                title="Select Package from list"
            >
                <NavLink href="#" className="link-show-more" onClick={ this.toggleSelectionList }>Back</NavLink>

                <ProjectDownloadBody withScroll>
                    <ProjectInstallerMetaList
                        list={ data }
                        selectedPackageName={ selectedPackageName }
                        onSelect={ selectPackage }
                    />
                </ProjectDownloadBody>
            </ProjectDownloadSection>
        )
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { downloadError, showSelectionList } = this.state;

        return (
            <ProjectDownloadModal isOpen={ isOpen } onClose={ onClose }>
                { showSelectionList ? (
                    <>
                        <ModalBody>
                            { this.renderSelectionListSection() }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" block onClick={ this.toggleSelectionList }>Select</Button>
                        </ModalFooter>
                    </>
                ) : (
                    <>
                        <ModalBody>
                            <Alert color="warning" isOpen={ !!downloadError } toggle={ this.hideDownloadError }>
                                { downloadError }
                            </Alert>
                            { this.renderSelectedPackageSection() }
                            { this.renderConfigSection() }
                        </ModalBody>
                        <ModalFooter>
                            { this.renderDownloadFormButton() }
                        </ModalFooter>
                    </>
                ) }
            </ProjectDownloadModal>
        );
    }
}

ProjectDownloadModalContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectDownloadModalContainer);

export default ProjectDownloadModalContainer;
