import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { setEventViewed, setEventDiscarded } from "../../redux/modules/actions";
import ActionCenterItem from "./ActionCenterItem";


const mapStateToProps = (state) => {
    return {
        isAcOpen: state.actions.center.visible,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setEventViewed: (eventId) => dispatch(setEventViewed(eventId)),
        setEventDiscarded: (eventId) => dispatch(setEventDiscarded(eventId)),
    };
};
const VIEW_TIMEOUT = 2000; // item considered unread if AC was opened for just a moment (<1s)


class ActionCenterItemContainer extends Component {
    static propTypes = {
        event: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        // from connect
        isAcOpen: PropTypes.bool.isRequired,
        setEventViewed: PropTypes.func.isRequired,
        setEventDiscarded: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this._maybeDelaySetViewed();
    }

    componentDidUpdate() {
        this._maybeDelaySetViewed();
    }

    componentWillUnmount() {
        clearTimeout(this._view_tout);
    }

    _maybeDelaySetViewed = () => {
        const { event: { _id: eventId, isNew }, isVisible, isAcOpen, setEventViewed } = this.props;

        clearTimeout(this._view_tout);

        if (isNew && isVisible && isAcOpen) {
            this._view_tout = setTimeout(() => {
                setEventViewed(eventId);
            }, VIEW_TIMEOUT);
        }
    };

    _setEventDiscarded = () => {
        const { event: { _id: eventId }, setEventDiscarded } = this.props;

        setEventDiscarded(eventId);
    };

    render() {
        const { isAcOpen, isVisible, event } = this.props;
        const { meta: { isAdding = false, isRemoving = false } = {} } = event;

        return (
            <ActionCenterItem
                event={ event }
                isVisible={ isAcOpen && isVisible }
                isAdding={ isAdding }
                isRemoving={ isRemoving }
                onDismiss={ this._setEventDiscarded }
            />
        );
    }
}

ActionCenterItemContainer = connect(mapStateToProps, mapDispatchToProps)(ActionCenterItemContainer);

export default ActionCenterItemContainer;

