import { ALERT_INFO } from '../../components/Alert';

const SHOW_ALERT = 'alert/SHOW';
const HIDE_ALERT = 'alert/HIDE';

// Reducers
//
function reducer(state = {
    show: false,
    type: ALERT_INFO,
    title: '',
    message: '',
}, action = {}) {
    switch (action.type) {
        case SHOW_ALERT:
            return {
                ...state,
                show: true,
                ...action.params,
            };
        case HIDE_ALERT:
            return {
                ...state,
                show: false,
            };
        default:
            return state;
    }
}

// Action creators
//
export function toggleAlert(show, params = {}) {
    return {
        type: show ? SHOW_ALERT : HIDE_ALERT,
        show,
        params
    };
}

export default reducer;
