import React, { Component } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Link } from 'react-router-dom';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import ActionCenterItemContainer from "./ActionCenterItemContainer";
import "./ActionCenter.scss";


const DEF_ITEM_HEIGHT = 115;
const MIN_ITEM_HEIGHT = 44; // group title items are slim
const cellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: DEF_ITEM_HEIGHT,
    minHeight: MIN_ITEM_HEIGHT,
});


const ActionCenterGroupTitle = ({ groupId, groupName }) => (
    <div className="ac-item-group-title" title={ groupName }>
        <Link className="ac-group-link" to={ `/projects/${ groupId }` }>{ groupName }</Link>
    </div>
);

class ActionCenter extends Component {

    static propTypes = {
        isPage: PropTypes.bool.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isEmpty: PropTypes.bool.isRequired,
        eventGroupsFlat: PropTypes.array.isRequired,
        onClearAll: PropTypes.func.isRequired,
    };

    UNSAFE_componentWillReceiveProps({ eventGroupsFlat: nextEventGroupsFlat }) {
        const { eventGroupsFlat } = this.props;
        const hasNewItem = eventGroupsFlat.length !== nextEventGroupsFlat.length;

        // Hack: this is no way to update `keyMapper` after initialization, so manky-patching..
        if (hasNewItem) {
            cellMeasurerCache._keyMapper = (index) => nextEventGroupsFlat[index].uid;
        }
    }

    render() {
        const { isPage, isOpen, isEmpty, eventGroupsFlat, onClearAll } = this.props;
        const classes = classNames('action-center', {
            page: isPage,
            open: isOpen || isPage,
        });

        let clearAllBtn = null;
        if (!isEmpty) {
            clearAllBtn = <div className="ac-header-link" onClick={ onClearAll }>Clear All</div>;
        }

        return (
            <div className={ classes }>
                <div className="ac-header">
                    <h4 className="ac-title">Action Center</h4>
                    { clearAllBtn }
                </div>
                <div className="ac-body">
                    <AutoSizer>
                        { ({ height, width }) => (
                            <List
                                height={ height }
                                width={ width }
                                deferredMeasurementCache={ cellMeasurerCache }
                                rowHeight={ cellMeasurerCache.rowHeight }
                                rowCount={ eventGroupsFlat.length }
                                rowRenderer={ ({ index, parent, style, isVisible }) => {
                                    const { type, uid/*keep*/, ...rest } = eventGroupsFlat[index];
                                    return (
                                        <CellMeasurer
                                            key={ uid }
                                            cache={ cellMeasurerCache }
                                            parent={ parent }
                                            rowIndex={ index }
                                            columnIndex={ 0 }
                                        >
                                            {({ registerChild }) => (
                                                <div ref={ registerChild} style={ { ...style } }>
                                                    { type === 'group' && <ActionCenterGroupTitle { ...rest }/> }
                                                    { type === 'event' && <ActionCenterItemContainer { ...rest } isVisible={ isVisible }/> }
                                                </div>
                                            )}
                                        </CellMeasurer>
                                    )
                                } }
                            />
                        ) }
                    </AutoSizer>
                </div>
            </div>
        );
    }
}

export default ActionCenter;
