import { createSelector } from 'reselect';

const FETCH_DETAILS = 'incidents/FETCH_DETAILS';
const FETCH_DETAILS_SUCCESS = 'incidents/FETCH_DETAILS_SUCCESS';
const FETCH_DETAILS_FAIL = 'incidents/FETCH_DETAILS_FAIL';

// State is a map of format:
// {
//   <incidentId1>: { loading, error, data },
//   ...
// }
//
const initialState = {};

// Reducers
//
function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_DETAILS:
            return {
                ...state,
                [action.incidentId]: {
                    loading: true,
                },
            };
        case FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                [action.incidentId]: {
                    loading: false,
                    data: action.result,
                },
            };
        case FETCH_DETAILS_FAIL:
            return {
                ...state,
                [action.incidentId]: {
                    loading: false,
                    error: action.error,
                },
            };
        default:
            return state;
    }
}

// Action creators
//
export function fetchIncidentDetails(incidentId) {
    return {
        types: [FETCH_DETAILS, FETCH_DETAILS_SUCCESS, FETCH_DETAILS_FAIL],
        promise: (client) => client.get(`/api/incidents/${ incidentId }`),
        incidentId,
    };
}

// Selectors
//
const getProjects = (state) => state.project.list.data;
const getIncidentDataById = (state, props) => (state.incidentDetails[props.incidentId] || {}).data; // undefined is ok

export const getProjectTitleById = createSelector(
    [getProjects, getIncidentDataById],
    (projects = [], incidentData = {}) => {
        const project = projects.find(p => p._id === incidentData.projectId);
        return project ? project.title : '?';
    },
);

export default reducer;
