import React, { Component } from "react";
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Row, Col } from "reactstrap";
import { MainContent } from "../Layout";
import { SvgIcon, ProgressIcon } from "../Elements";
import FileTreeContainer from './FileTree';
import FileDetailsContainer from './FileDetails';
import { onSyncUpMessage, onSyncUpMessageByReason } from "../../services/IoClient";
import { E_TYPE_PROJECT_POLICY_WL } from "../../../common/constants";
import "./ProjectWhiteList.scss";


class ProjectWhiteList extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
    };

    state = {
        isDisabled: false,
    };

    componentDidMount() {
        const { projectId } = this.props;

        this._unsubscribeStart = onSyncUpMessageByReason(`${E_TYPE_PROJECT_POLICY_WL}/${projectId}/agent-start`, () => {
            this.setState({ isDisabled: true });
        });
        this._unsubscribeEnd = onSyncUpMessage(`projects/${projectId}/whitelist`, () => {
            this.setState({ isDisabled: false });
        });
    }

    componentWillUnmount() {
        this._unsubscribeStart();
        this._unsubscribeEnd();
    }

    render() {
        const { projectId } = this.props;
        const { isDisabled } = this.state;

        return (
            <Col className="h-100">
                <MainContent className={ cs('project-whitelist', { disabled: isDisabled }) }>
                    { /*Disabled banner*/ }
                    { isDisabled && (
                        <div className="wl-disabled-warning">
                            Please hold on. New policy is being installed because of new
                            build... <ProgressIcon className="d-inline" color="normal"/>
                        </div>
                    ) }

                    <Row className="wl-content-row">
                        { /*Icon*/ }
                        <Col xs="12" lg="3" className="icon-col">
                            <SvgIcon icon="policy-whitelist" sz="lg"/>
                            <h2 className="d-block d-lg-none">Application Whitelisting</h2>
                        </Col>

                        { /*Tree & details*/ }
                        <Col xs="12" lg="9" className="content-col">
                            <div className="wl-tree-cont">
                                <FileTreeContainer projectId={ projectId }/>
                            </div>
                            <div className="wl-col-divider"/>
                            <div className="wl-details-cont">
                                <FileDetailsContainer projectId={ projectId }/>
                            </div>
                        </Col>
                    </Row>
                </MainContent>
            </Col>
        );
    }
}

export default ProjectWhiteList;
