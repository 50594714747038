import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchTreeNode, selectNode, invalidateTree } from '../../../redux/modules/project-policy-tree';
import { onSyncUpMessage } from "../../../services/IoClient";
import FileTree from "./FileTree";


const mapStateToProps = (state, { projectId }) => {
    return {
        tree: state.project.tree[projectId] || {},
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        fetchTreeNode: (path) => dispatch(fetchTreeNode(projectId, path)),
        selectNode: (nodeId, kind) => dispatch(selectNode(projectId, nodeId, kind)),
        invalidateTree: () => dispatch(invalidateTree(projectId)),
    }
};


class FileTreeContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        // from connect
        tree: PropTypes.object.isRequired,
        fetchTreeNode: PropTypes.func.isRequired,
        selectNode: PropTypes.func.isRequired,
        invalidateTree: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { projectId } = this.props;

        this.fetchAndSelectFirst();

        this._unsubscribe = onSyncUpMessage(`projects/${projectId}/whitelist`, () => {
            this.fetchAndSelectFirst();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    fetchAndSelectFirst() {
        const { fetchTreeNode, selectNode, invalidateTree } = this.props;

        invalidateTree();

        fetchTreeNode().then(({ result }) => {
            const { paths: [firstNode] = [] } = result || {};

            if (firstNode) {
                const { path, packageId = '', _t: kind } = firstNode;
                const nodeId = `${ packageId }:${ path }`;
                selectNode(nodeId, kind);
            }
        });
    }

    render() {
        const { tree, fetchTreeNode, selectNode } = this.props;
        const { selectedNode } = tree;
        const onSelect = (nodeId, kind) => selectNode(nodeId, kind);
        const onLoadMore = (path) => fetchTreeNode(path);

        return (
            <FileTree
                tree={ tree }
                selectedNode={ selectedNode }
                onSelect={ onSelect }
                onLoadMore={ onLoadMore }
            />
        );
    }
}

FileTreeContainer = connect(mapStateToProps, mapDispatchToProps)(FileTreeContainer);

export default FileTreeContainer;
