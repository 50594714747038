import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { reduxForm, Field, reset } from 'redux-form';
import { Icon } from '../../Elements';
import { InputGroup } from '../../Form';


const formName = 'saveFilter';
const formConfig = {
    form: formName,
    fields: ['label'],
    enableReinitialize: true, // force re-init of initialValues from props
};
const required = value => (value) ? undefined : '* required';
const maxLength = max => value => (value && value.length > max)
    ? `Must be ${ max } characters or less`
    : undefined;
const maxLength300 = maxLength(300);

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: {
            label: ownProps.initLabel,
        },
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetForm: () => dispatch(reset(formName)),
    };
};

class SaveFilterModal extends Component {

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        initLabel: PropTypes.string.isRequired,
        onCancel: PropTypes.func.isRequired,
        // from redux-form
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        // from connect
        resetForm: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        const { isOpen: prevIsOpen } = prevProps;
        const hasOpened = prevIsOpen !== isOpen;
        if (hasOpened && isOpen) {
            this.props.resetForm();
            setTimeout(() => this.setDefaultFocus(), 100); // after render
        }
    }

    setDefaultFocus() {
        const inputEl = this._label.getRenderedComponent()._input;
        if (inputEl) {
            inputEl.select();
            inputEl.focus();
        }
    }

    render() {
        const { isOpen, onCancel, handleSubmit, valid, submitting } = this.props;
        const disableSubmit = !valid || submitting;
        const disabledClass = disableSubmit ? 'disabled' : '';
        const onSaveClick = () => handleSubmit();

        return (
            <Modal size="md" isOpen={ isOpen } toggle={ onCancel } className="modal-add">
                <ModalHeader toggle={ onCancel }>
                    <Icon icon="floppy-o" classes="mb-2"/>
                    <span>Save filter</span>
                </ModalHeader>
                <ModalBody>
                    { /*Workaround for a Modal error leading to `Target container is not a DOM element` err on second open*/ }
                    { isOpen && (
                        <Field type="label" name="label" component={ InputGroup }
                            validate={ [required, maxLength300] }
                            label="Give filter some descriptive name"
                            forwardRef={ true }
                            ref={ (c) => this._label = c }
                        />
                    ) }
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" block
                        className={ disabledClass }
                        onClick={ onSaveClick }
                    >Save filter</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

SaveFilterModal = connect(mapStateToProps, mapDispatchToProps)(
    reduxForm(formConfig)(SaveFilterModal),
);

export default SaveFilterModal;
