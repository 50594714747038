import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { fetchProjectDetails } from "../../redux/modules/project-details";


const mapStateToProps = (state, { projectId }) => {
    return {
        projectDetails: (state.project.details[projectId] || {}).data || {},
    };
};
const mapDispatchToProps = (dispatch, { projectId }) => {
    return {
        fetchProjectDetails: () => dispatch(fetchProjectDetails(projectId)),
    }
};

class ProjectPageContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        subTitle: PropTypes.string,
        // from router
        redirect: PropTypes.func.isRequired,
        // from connect
        projectDetails: PropTypes.object,
        fetchProjectDetails: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { projectId, fetchProjectDetails, redirect } = this.props;

        fetchProjectDetails().then(({ error: { error } = {} }) => {
            if (error === 'Not Found') {
                redirect({ to: `/projects/${ projectId }/not-found`, replace: true });
            }
        });
    }

    render() {
        const { children, subTitle, projectDetails: { title } } = this.props;
        const titleParts = [
            'Karamba',
            ...(subTitle ? [subTitle] : []),
            ...(title ? [title] : []),
        ];

        return (
            <Fragment>
                <Helmet><title>{ titleParts.join(' • ') }</title></Helmet>
                { children }
            </Fragment>
        );
    }
}

ProjectPageContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectPageContainer);

export default ProjectPageContainer;
