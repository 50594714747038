import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Icon } from '../../Elements';
import { deleteIncidents } from '../../../redux/modules/incident-list';
import DeleteAttacksModal from './DeleteAttacksModal';

const mapDispatchToProps = (dispatch) => {
    return {
        deleteIncidents: () => dispatch(deleteIncidents()),
    }
};

const AttackDeleteButtonContainer = ({ deleteIncidents }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <>
            <Button color="danger" size="sm" className="delete-btn" onClick={ () => setModalOpen(true) }>
                <Icon icon="fw trash"/> Clear All
            </Button>

            <DeleteAttacksModal
                isOpen={ isModalOpen }
                onClose={ () => setModalOpen(false) }
                onSubmit={ () => {
                    deleteIncidents();
                    setModalOpen(false)
                } }
            />
        </>
    );
}

AttackDeleteButtonContainer.propTypes = {
    // from connect
    deleteIncidents: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(AttackDeleteButtonContainer);
