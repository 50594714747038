import React, { Component } from "react";
import PropTypes from "prop-types";


class Checkbox extends Component {
    static propTypes = {
        label: PropTypes.node,
        disabled: PropTypes.bool,
    };

    ref() {
        return this._input;
    }

    focus() {
        return this._input.focus();
    }

    render() {
        const { label = '', disabled = false, ...rest } = this.props;

        return (
            <div className={ `checkbox ${ disabled ? 'disabled' : ''}` }>
                <label className="checkbox-label">
                    <input type="checkbox" disabled={ disabled } { ...rest }
                        ref={ c => this._input = c }
                    /> { label }
                </label>
            </div>
        );
    }
}

export default Checkbox;
