import React, { Component } from "react";
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button } from "reactstrap";
import { CloseButton } from "../Elements";
import "./ButtonWithPrompt.scss";


class ButtonWithPrompt extends Component {

    static propTypes = {
        btnInner: PropTypes.node.isRequired,
        children: PropTypes.node.isRequired,
        onConfirm: PropTypes.func.isRequired,
    };

    state = {
        showPrompt: false,
    };

    handlePromprt(showPrompt) {
        this.setState({ showPrompt });
    }

    render() {
        const { btnInner, children, onConfirm } = this.props;
        const { showPrompt } = this.state;
        const onClickBtn = () => this.handlePromprt(true);
        const onClickConfirm = () => {
            onConfirm();
            onClose();
        };
        const onClose = () => this.handlePromprt(false);

        return (
            <div className={ cs('btn-prompt-cont', { 'show-prompt': showPrompt }) }>
                <Button color="danger" size="sm" className="main-btn" onClick={ onClickBtn }>
                    { btnInner }
                </Button>

                <div className="btn-prompt">
                    <div className="prompt-text">
                        { children }
                    </div>
                    <Button color="danger" size="sm" onClick={ onClickConfirm }>Confirm</Button>
                    <CloseButton onClick={ onClose }/>
                </div>
            </div>
        );
    }
}

export default ButtonWithPrompt;
