import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { connect } from 'react-redux';
import { withToggle, withQueryFilter } from '../../Elements';
import { dateFilterOptions, typeFilterOptions } from './constants';
import AttackListFilterEditor from './AttackListFilterEditor';
import AttackDeleteButtonContainer from './AttackDeleteButtonContainer';
import AttackListFilterSaveRemoveContainer from './AttackListFilterSaveRemoveContainer';
import { formatFilterLabel } from './format-utils';

const mapStateToProps = (state) => {
    return {
        projectList: state.project.list.data,
    };
};

const AttackListFilterToggler = ({ isActive, filterLabel, onClick }) => (
    <div className={ cs('attack-filter-toggler clickable', { active: isActive }) }
        onClick={ onClick }
        title={ filterLabel }
    >
        <div className={ cs('chevron', { right: !isActive }) }/>
        <span className="text-mute">Filter:</span> <span className="aft-long-val text-truncate">{ filterLabel }</span>
    </div>
);

class AttackListFilterContainer extends Component {
    static propTypes = {
        // from withQueryFilter
        filter: PropTypes.shape({
            projects: PropTypes.string,
            dates: PropTypes.oneOf(dateFilterOptions.map(it => it.value)),
            types: PropTypes.oneOf(typeFilterOptions.map(it => it.value)),
        }).isRequired,
        setFilter: PropTypes.func.isRequired,
        // from withToggle
        show: PropTypes.bool.isRequired,
        onToggle: PropTypes.func.isRequired,
        // from connect
        projectList: PropTypes.array.isRequired,
    };

    render() {
        const { show, onToggle, filter, setFilter, projectList } = this.props;
        const filterLabel = formatFilterLabel(filter, projectList);

        return (
            <div className="attack-filter">
                <div className="attack-filter-bar">
                    <AttackListFilterToggler
                        isActive={ show }
                        filterLabel={ filterLabel }
                        onClick={ onToggle }
                    />
                    <AttackListFilterSaveRemoveContainer filterLabel={ filterLabel }/>
                    <AttackDeleteButtonContainer/>
                </div>
                <AttackListFilterEditor
                    isOpen={ show }
                    filter={ filter }
                    projectList={ projectList }
                    onSelect={ setFilter }
                    onToggle={ onToggle }
                />
            </div>
        );
    }
}

AttackListFilterContainer = withQueryFilter('/attacks')(
    withToggle({ show: false })(
        connect(mapStateToProps)(AttackListFilterContainer),
    ),
);

export default AttackListFilterContainer;
