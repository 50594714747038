import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { CloseButton, Icon, SvgIcon } from '../Elements';
import { matchMedia } from '../../services/DOMUtils';
import brandLogoImage from './images/logo-bw.svg';
import './SideMenu.scss';

const SideMenuLink = ({ children, className, indent = false, ...rest }) => (
    <NavLink
        className={ cs('nav-link nowrap', className, { 'nav-indent': indent }) }
        activeClassName="active"
        { ...rest }
    >
        { children }
    </NavLink>
);

class SideMenu extends Component {

    static propTypes = {
        pathname: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        projects: PropTypes.array.isRequired,
        incidentFilters: PropTypes.array.isRequired,
        hasUnreadActions: PropTypes.bool.isRequired,
        toggle: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { isOpen, toggle } = this.props;
        this._autoHide = matchMedia('sm-down');

        if (this._autoHide && isOpen) {
            toggle();
        }
    }

    componentDidUpdate(prevProps) {
        const { isOpen, toggle, pathname } = this.props;
        const isPathnameChanged = prevProps.pathname !== pathname;
        if (this._autoHide && isOpen && isPathnameChanged) {
            toggle();
        }
    }

    render() {
        const { isOpen, toggle, projects, incidentFilters, hasUnreadActions } = this.props;
        const openClass = isOpen ? 'open' : '';
        const actionsIcon = hasUnreadActions ? 'menu-actions-unread' : 'menu-actions';
        const onClose = () => toggle();

        return (
            <div className={ `side-menu-container ${ openClass }` }>
                <div className="shadow d-block d-md-none" onClick={ onClose }/>

                <div className="side-menu">
                    <CloseButton onClick={ onClose } className="d-block d-md-none"/>

                    <img className="brand-icon" src={ brandLogoImage } alt=""/>

                    <Nav pills className="nav-main flex-column">
                        {/*Projects*/}
                        <NavItem>
                            <SideMenuLink exact to="/projects">
                                Projects
                            </SideMenuLink>
                        </NavItem>
                        <NavItem>
                            <Nav pills className="nav-inner flex-column">
                                { projects.map(({ _id: projectId, title }, i) => (
                                    <NavItem key={ `smi-${i}` }>
                                        <SideMenuLink indent to={ `/projects/${ projectId }` }>
                                            <Icon icon="chevron-right" classes="menu-chevron"/>
                                            <span title={ title }>{ title }</span>
                                        </SideMenuLink>
                                    </NavItem>
                                )) }
                            </Nav>
                        </NavItem>
                        {/*Attacks & Attack filters*/}
                        <NavItem>
                            <SideMenuLink to="/attacks" className="nav-bt" isActive={ (match, location) => {
                                const { url, isExact } = match || {};
                                const { search } = location;
                                return (isExact && url === '/attacks' && !search);
                            }}>
                                <SvgIcon icon="menu-attacks"/>
                                Attacks Overview
                            </SideMenuLink>
                        </NavItem>
                        <NavItem>
                            <Nav pills className="nav-inner flex-column">
                                { incidentFilters.map(({ label, search, isUnsaved= false }, i) => (
                                    <NavItem key={ `smi-${i}` }>
                                        <SideMenuLink indent
                                            to={ `/attacks${ search }` }
                                            className={ cs({ 'nav-em': isUnsaved }) }
                                            isActive={ (match, location) => {
                                                const { url, isExact } = match || {};
                                                return (isExact && url === '/attacks' && location.search === search);
                                            }}
                                        >
                                            <Icon icon="filter" classes="menu-chevron lg"/>
                                            <span title={ label }>{ label }</span>
                                        </SideMenuLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </NavItem>
                        {/*Action Center (mobile only)*/}
                        <NavItem className="d-block d-sm-none">
                            <SideMenuLink to="/actions" className="nav-bt">
                                <SvgIcon icon={ actionsIcon } classes="actions-icon"/>
                                Actions
                            </SideMenuLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
        );
    }
}


export default SideMenu;
