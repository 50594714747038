import React, { Component } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SvgIcon } from '../Elements';
import ActionCenterBalloon from './ActionCenterBalloon';
import "./ActionCenter.scss";


class ActionCenterBar extends Component {

    static propTypes = {
        // from connect
        isOpen: PropTypes.bool.isRequired,
        totalActionsNum: PropTypes.number.isRequired,
        unreadActionsNum: PropTypes.number.isRequired,
        toggle: PropTypes.func.isRequired,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    componentDidUpdate(prevProps) {
        const { isOpen, totalActionsNum, unreadActionsNum, toggle } = this.props;

        const hasNewActions = prevProps.unreadActionsNum < unreadActionsNum;
        if (hasNewActions && !isOpen) {
            this.toggleBalloon(true);
        }

        const justRemovedAll = prevProps.totalActionsNum > 0 && totalActionsNum === 0;
        if (isOpen && justRemovedAll) {
            setTimeout(() => toggle(false), 200); // after animation
        }
    }

    handleDocumentClick = (e) => {
        const { isOpen, toggle } = this.props;
        const isElementOrChildClicked = (el) => el === e.target || el.contains(e.target);
        const isButtonClick = isElementOrChildClicked(this._button);
        const isAcClick = isElementOrChildClicked(document.querySelector('.action-center'));

        if (isOpen && !isButtonClick && !isAcClick) {
            toggle(false);
        }
    }

    toggleBalloon(show) {
        if (show) {
            setTimeout(() => this._balloon?.toggle(true), 1000); // delay for aesthetic reasons
        } else {
            this._balloon.toggle(false);
        }
    }

    render() {
        const { isOpen, unreadActionsNum, toggle } = this.props;
        const barClass = classNames('action-center-bar d-none d-sm-block', { open: isOpen });
        const hasUnread = unreadActionsNum > 0;
        const icon = classNames({
            'acbar-pink': hasUnread,
            'acbar-white': !hasUnread && isOpen,
            'acbar-grey': !hasUnread && !isOpen,
        });
        const onClick = () => toggle(!isOpen);
        const onBalloonClick = () => {
            this.toggleBalloon(false);
            setTimeout(() => toggle(true), 0); // async to let docClick handler run first and prevent close
        };

        return (
            <div className={ barClass } onClick={ onClick } ref={ (c) => this._button = c }>
                <SvgIcon icon={ icon } sz="md"/>
                <ActionCenterBalloon
                    ref={ (c) => this._balloon = c }
                    onClick={ onBalloonClick }/>
            </div>
        );
    }
}

export default ActionCenterBar;
