import { dateFilterOptions, typeFilterOptions } from './constants';

export const formatFilterLabel = (filter, projectList) => {
    const { projects: fProjects = 'all', dates: fDates = 'all', types: fTypes = 'all' } = filter;

    let label = '';
    if (fProjects === 'all') {
        label += `All projects`;
    }
    else {
        const { title: projectTitle = '?' } = projectList.find(p => p._id === fProjects) || {};
        label += `Project: ${ projectTitle }`;
    }

    if (fTypes !== 'all') {
        const typeLabel = typeFilterOptions.find(d => d.value === fTypes).label;
        label += ` / ${ typeLabel }`;
    }

    if (fDates !== 'all') {
        const dateLabel = dateFilterOptions.find(d => d.value === fDates).label;
        label += ` / ${ dateLabel }`;
    }

    return label;
};
