import React from "react";
import { Col } from "reactstrap";
import { Helmet } from "react-helmet";
import { MainContent } from "../Layout";


// Fixes warning: jsx-a11y/accessible-emoji
const Emoji = () => <span role="img" aria-label="Roadwork Sign">🚧</span>;

const Project404 = ({ projectId }) => {
    return (
        <Col className="project-404">
            <Helmet><title>Karamba • Not Found • { projectId }</title></Helmet>
            <MainContent>
                <div className="text-center text-mute mt-5">
                    <h1><Emoji/> 404 <Emoji/></h1>
                    <h3>Sorry, no Project with ID <span className="text-white">{ projectId }</span> has been found in your set of Projects.</h3>
                    <p>Project Owners can grant you permission to access projects.</p>
                </div>
            </MainContent>
        </Col>
    )
};

export default Project404;
