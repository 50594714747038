const PROJECT_FETCH = 'details/PROJECT_FETCH';
const PROJECT_FETCH_SUCCESS = 'details/PROJECT_FETCH_SUCCESS';
const PROJECT_FETCH_FAIL = 'details/PROJECT_FETCH_FAIL';

const PATCH_TITLE = 'details/PATCH_TITLE';
const PATCH_TITLE_SUCCESS = 'details/PATCH_TITLE_SUCCESS';
const PATCH_TITLE_FAIL = 'details/PATCH_TITLE_FAIL';

const PATCH_POLICY = 'details/PATCH_POLICY';
const PATCH_POLICY_SUCCESS = 'details/PATCH_POLICY_SUCCESS';
const PATCH_POLICY_FAIL = 'details/PATCH_POLICY_FAIL';

const ADD_COLLABORATOR = 'settings/ADD_COLLABORATOR';
const ADD_COLLABORATOR_SUCCESS = 'settings/ADD_COLLABORATOR_SUCCESS';
const ADD_COLLABORATOR_FAIL = 'settings/ADD_COLLABORATOR_FAIL';

const REMOVE_COLLABORATOR = 'settings/REMOVE_COLLABORATOR';
const REMOVE_COLLABORATOR_SUCCESS = 'settings/REMOVE_COLLABORATOR_SUCCESS';
const REMOVE_COLLABORATOR_FAIL = 'settings/REMOVE_COLLABORATOR_FAIL';

const SET_OWNER = 'settings/SET_OWNER';
const SET_OWNER_SUCCESS = 'settings/SET_OWNER_SUCCESS';
const SET_OWNER_FAIL = 'settings/SET_OWNER_FAIL';

const CREATE_APIKEY = 'installer/CREATE_APIKEY';
const CREATE_APIKEY_SUCCESS = 'installer/CREATE_APIKEY_SUCCESS';
const CREATE_APIKEY_FAIL = 'installer/CREATE_APIKEY_FAIL';



// Reducers
//
function projectReducer(state = {
    data: undefined,
    error: undefined,
    loading: false,
}, action = {}) {
    switch (action.type) {
        case PROJECT_FETCH:
        case PATCH_TITLE:
        case PATCH_POLICY:
        case ADD_COLLABORATOR:
        case REMOVE_COLLABORATOR:
        case SET_OWNER:
        case CREATE_APIKEY:
            return {
                ...state,
                error: undefined
            };
        case PROJECT_FETCH_SUCCESS:
        case PATCH_TITLE_SUCCESS:
        case PATCH_POLICY_SUCCESS:
        case ADD_COLLABORATOR_SUCCESS:
        case REMOVE_COLLABORATOR_SUCCESS:
        case SET_OWNER_SUCCESS:
        case CREATE_APIKEY_SUCCESS:
            return {
                ...state,
                data: action.result, // replace project data
                error: undefined,
            };
        case PROJECT_FETCH_FAIL:
        case PATCH_TITLE_FAIL:
        case PATCH_POLICY_FAIL:
        case ADD_COLLABORATOR_FAIL:
        case REMOVE_COLLABORATOR_FAIL:
        case SET_OWNER_FAIL:
        case CREATE_APIKEY_FAIL:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}

function reducer(state = {}, action = {}) {
    switch (action.type) {
        case PROJECT_FETCH:
        case PROJECT_FETCH_SUCCESS:
        case PROJECT_FETCH_FAIL:
        case PATCH_TITLE:
        case PATCH_TITLE_SUCCESS:
        case PATCH_TITLE_FAIL:
        case PATCH_POLICY:
        case PATCH_POLICY_SUCCESS:
        case PATCH_POLICY_FAIL:
        case ADD_COLLABORATOR:
        case ADD_COLLABORATOR_SUCCESS:
        case ADD_COLLABORATOR_FAIL:
        case REMOVE_COLLABORATOR:
        case REMOVE_COLLABORATOR_SUCCESS:
        case REMOVE_COLLABORATOR_FAIL:
        case SET_OWNER:
        case SET_OWNER_SUCCESS:
        case SET_OWNER_FAIL:
        case CREATE_APIKEY:
        case CREATE_APIKEY_SUCCESS:
        case CREATE_APIKEY_FAIL:
            return {
                ...state,
                [action.projectId]: projectReducer(state[action.projectId], action)
            };
        default:
            return state;
    }
}

// Action creators
//
export function fetchProjectDetails(projectId) {
    return {
        types: [PROJECT_FETCH, PROJECT_FETCH_SUCCESS, PROJECT_FETCH_FAIL],
        promise: (client) => client.get(`/api/projects/${ projectId }`),
        projectId,
        noAlert: 404,
    };
}

// title
export function setProjectTitle(projectId, title) {
    const data = { title };
    return {
        types: [PATCH_TITLE, PATCH_TITLE_SUCCESS, PATCH_TITLE_FAIL],
        promise: (client) => client.patch(`/api/projects/${projectId}/title`, { data }),
        projectId,
    };
}

// policy on/off
export function toggleProjectPolicy(projectId, policyName, enabled) {
    const data = { enabled };
    return {
        types: [PATCH_POLICY, PATCH_POLICY_SUCCESS, PATCH_POLICY_FAIL],
        promise: (client) => client.patch(`/api/projects/${projectId}/${policyName}/toggle`, { data }),
        projectId,
    };
}

// collaborators
export function addCollaborator(projectId, email) {
    const data = { email };
    return {
        types: [ADD_COLLABORATOR, ADD_COLLABORATOR_SUCCESS, ADD_COLLABORATOR_FAIL],
        promise: (client) => client.post(`/api/projects/${ projectId }/collaborators`, { data }),
        projectId,
    };
}

export function removeCollaborator(projectId, email) {
    const data = { email };
    return {
        types: [REMOVE_COLLABORATOR, REMOVE_COLLABORATOR_SUCCESS, REMOVE_COLLABORATOR_FAIL],
        promise: (client) => client.del(`/api/projects/${ projectId }/collaborators`, { data }),
        projectId,
    };
}

export function updateOwner(projectId, email) {
    const data = { email };
    return {
        types: [SET_OWNER, SET_OWNER_SUCCESS, SET_OWNER_FAIL],
        promise: (client) => client.put(`/api/projects/${ projectId }/owner`, { data }),
        projectId,
    };
}

export function generateApiKey(projectId) {
    return {
        types: [CREATE_APIKEY, CREATE_APIKEY_SUCCESS, CREATE_APIKEY_FAIL],
        promise: (client) => client.post(`/api/projects/${ projectId }/api-key`),
        projectId,
    };
}

export default reducer;
