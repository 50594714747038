import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEventGroupsFlat, fetchEvents, appendEvent, discardAllEvents } from '../../redux/modules/actions';
import { onSyncUpMessage } from '../../services/IoClient';
import ActionCenter from './ActionCenter';
import ActionCenterBarContainer from './ActionCenterBarContainer';

const mapStateToProps = (state) => {
    return {
        isOpen: state.actions.center.visible,
        isEmpty: state.actions.events.list.length === 0,
        eventGroupsFlat: getEventGroupsFlat(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchEvents: () => dispatch(fetchEvents()),
        clearEvents: () => dispatch(discardAllEvents()),
        appendEvent: (event) => dispatch(appendEvent(event)),
    };
};

class ActionCenterContainer extends Component {

    static propTypes = {
        isPage: PropTypes.bool,
        // from connect
        isOpen: PropTypes.bool.isRequired,
        isEmpty: PropTypes.bool.isRequired,
        eventGroupsFlat: PropTypes.array.isRequired,
        fetchEvents: PropTypes.func.isRequired,
        clearEvents: PropTypes.func.isRequired,
        appendEvent: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { fetchEvents, appendEvent } = this.props;

        fetchEvents();

        onSyncUpMessage('events', ({ reason, event }) => {
            if (reason.startsWith('EventsRemoved/')) {
                fetchEvents();
            }
            else {
                appendEvent(event);
            }
        });
    }

    render() {
        const { isPage = false, isOpen, isEmpty, eventGroupsFlat, clearEvents } = this.props;

        return (
            <ActionCenter isPage={ isPage }
                isOpen={ isOpen }
                isEmpty={ isEmpty }
                eventGroupsFlat={ eventGroupsFlat }
                onClearAll={ clearEvents }
            />
        );
    }
}

ActionCenterContainer = connect(mapStateToProps, mapDispatchToProps)(ActionCenterContainer);

export { ActionCenterBarContainer };
export default ActionCenterContainer;
