import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { login } from "../../redux/modules/auth";
import { updateUser } from "../../redux/modules/user";
import AuthLoginForm from "./AuthLoginForm";


const mapStateToProps = (state) => {
    return {
        error: state.auth.login.error,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(login(data)),
        updateUser: (user) => dispatch(updateUser(user)),
    }
};


class AuthLoginFormContainer extends Component {

    static propTypes = {
        email: PropTypes.string,
        isVerified: PropTypes.bool.isRequired,
        redirect: PropTypes.func.isRequired,
        //from connect
        error: PropTypes.object,
        login: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
    };

    handleLogin(data) {
        const { login, updateUser, redirect } = this.props;

        return login(data).then(({ error, result }) => {
            if (!error) {
                updateUser({ isAuthenticated: true, ...result.user });
                return redirect({ to: '/projects' });
            }

            const { error: errorStatus, email } = error;
            if (errorStatus === 'Email Not Approved') {
                redirect({ to: `/approve/${email}?e=pending` });
            }
            if (errorStatus === 'Email Not Verified') {
                redirect({ to: `/verify/${email}` });
            }
        });
    }

    renderErrorMsg(error) {
        if (!error) { return ''; }

        const { error: errorStatus } = error;

        if (errorStatus === 'Email Not Approved') { return ''; }
        if (errorStatus === 'Email Not Verified') { return ''; }
        if (errorStatus === 'Unauthorized') { return 'Oops! That email / password combination is not valid.'; }
        return 'Oops! Something went wrong. Please try again later.'; // unknown error
    }

    render() {
        const { email, error, isVerified, redirect } = this.props;
        const errorMsg = this.renderErrorMsg(error);
        const onSubmit = (data) => this.handleLogin(data);
        const onVerified = () => redirect({ to: `/login/${email}`, replace: true });

        return (
            <AuthLoginForm
                email={ email }
                isVerified={ isVerified }
                errorMsg={ errorMsg }
                onSubmit={ onSubmit }
                onVerified={ onVerified }
            />
        );
    }
}

AuthLoginFormContainer = connect(mapStateToProps, mapDispatchToProps)(AuthLoginFormContainer);

export default AuthLoginFormContainer;
