import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    resetStore,
    fetchIncidents,
    DEF_PAGE_SIZE,
} from '../../../redux/modules/incident-list';
import { withQueryFilter } from '../../Elements';
import { onSyncUpMessage } from '../../../services/IoClient';
import AttackList from './AttackList';
import AttackListHeader from './AttackListHeader';
import AttackListPager from './AttackListPager';

const mapStateToProps = (state) => {
    return {
        incidents: state.incidentList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetStore: () => dispatch(resetStore()),
        fetchIncidents: (params) => dispatch(fetchIncidents(params)),
    };
};

class AttackListContainer extends Component {

    static propTypes = {
        pageSize: PropTypes.number,
        // from router
        redirect: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        // from connect
        incidents: PropTypes.object.isRequired,
        resetStore: PropTypes.func.isRequired,
        fetchIncidents: PropTypes.func.isRequired,
        // from withQueryFilter
        filter: PropTypes.object.isRequired,
    };

    static defaultProps = {
        pageSize: DEF_PAGE_SIZE,
    };

    componentDidMount() {
        const { resetStore } = this.props;

        resetStore();
        this._refresh();

        // Re-fetch on incidents clean-up or project removal
        this._unsubscribe = onSyncUpMessage('incidents', () => {
            this._refresh()
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    componentDidUpdate(prevProps) {
        const { filter: prevFilter } = prevProps;
        const { filter, resetStore } = this.props;

        // Fetch with new filter and reset paging
        if (prevFilter !== filter) {
            resetStore(); // reset to show loading indicator
            this._refresh();
        }
    }

    _refresh = () => {
        const { filter, pageSize, fetchIncidents } = this.props;

        return fetchIncidents({ count: pageSize, filter });
    };

    _nextPage = () => {
        const { filter, pageSize, incidents: { pager }, fetchIncidents } = this.props;

        return fetchIncidents({ count: pageSize, filter, next: pager.next });
    };

    _prevPage = () => {
        const { filter, pageSize, incidents: { pager }, fetchIncidents } = this.props;

        return fetchIncidents({ count: pageSize, filter, prev: pager.prev });
    };

    render() {
        const { incidents, redirect, location } = this.props;
        const {
            list: { loading, stubs },
            pager: { fromIndex, untilIndex, isFirstPage, isLastPage },
        } = incidents;
        const isInitialLoading = (!stubs);
        const isEmptyListLoaded = (stubs?.length === 0);
        const isReloadingEmpty = (isEmptyListLoaded && loading);
        const onVisitIncident = ({ _id: incidentId }) => redirect({
            to: `/attacks/${ incidentId }`,
            state: { backURL: `/attacks${ location.search }` },
        });

        return (
            <>
                <AttackListHeader
                    isLoading={ isInitialLoading || loading }
                    onClickRefresh={ () => this._refresh() }
                >
                    <AttackListPager
                        from={ Math.min(fromIndex + 1, untilIndex) }
                        to={ untilIndex }
                        isFirstPage={isFirstPage}
                        isLastPage={isLastPage}
                        onClickNewer={ () => this._prevPage() }
                        onClickOlder={ () => this._nextPage() }
                    />
                </AttackListHeader>
                <AttackList
                    isLoading={ isInitialLoading || isReloadingEmpty }
                    isEmpty={ isEmptyListLoaded }
                    list={ stubs }
                    onVisit={ onVisitIncident }
                />
            </>
        );
    }
}

AttackListContainer = withQueryFilter('/attacks')(
    connect(mapStateToProps, mapDispatchToProps)(AttackListContainer),
);

export default AttackListContainer;
