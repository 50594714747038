import React from "react";
import inboxIconImage from './images/icon-inbox-lg.svg';
import dangerIconImage from './images/icon-danger.svg';
import acBarGreyIconImage from './images/icon-acbar-grey.svg';
import acBarWhiteIconImage from './images/icon-acbar-white.svg';
import acBarPinkIconImage from './images/icon-acbar-pink.svg';
import menuFolderIconImage from './images/icon-menu-folder.svg';
import menuAttacksIconImage from './images/icon-menu-incidents.svg';
import menuActionsIconImage from './images/icon-menu-actions.svg';
import menuActionsUnreadIconImage from './images/icon-menu-actions-unread.svg';
import policyWhitelistIconImage from './images/icon-policy-whitelist.svg';
import policyStaticIconImage from './images/icon-policy-static.svg';
import btnChangeIconImage from './images/icon-btn-change.svg';
import acInfoWhiteIconImage from './images/icon-ac-info-white.svg';
import acInfoBlackIconImage from './images/icon-ac-info-black.svg';
import acWarningIconImage from './images/icon-ac-warning.svg';
import "./Icon.scss";

// FontAwsome
export const Icon = ({ icon, classes = '', ...rest }) => {
    const faClasses = icon.split(' ').map(c => 'fa-' + c).join(' ');
    return (
        <i className={ `fa ${ faClasses } ${classes}` } {...rest}></i>
    );
};

const StackedIcon = ({ bottom, top, classes = '' }) => {
    return (
        <span className={ `fa-stack ${ classes }` }>
          <i className={ `fa fa-stack-2x fa-${ bottom }` }></i>
          <i className={ `fa fa-stack-1x fa-${ top }` }></i>
        </span>
    );
};


// Back
export const BackIcon = ({ className = '' }) => {
    return <StackedIcon bottom="circle-o" top="chevron-left" classes={ `back-icon ${className}` } />
};


// Progress
export const ProgressIcon = ({ className, color = 'mute' }) => (
    <div className={ className }>
        <i className={`fa fa-circle-o-notch fa-spin fa-fw text-${ color }`}/>
    </div>
);


// SVG
const icon2img = {
    'danger': dangerIconImage,
    'acbar-grey': acBarGreyIconImage,
    'acbar-white': acBarWhiteIconImage,
    'acbar-pink': acBarPinkIconImage,
    'menu-folder': menuFolderIconImage,
    'menu-attacks': menuAttacksIconImage,
    'menu-actions': menuActionsIconImage,
    'menu-actions-unread': menuActionsUnreadIconImage,
    'policy-whitelist': policyWhitelistIconImage,
    'policy-static': policyStaticIconImage,
    'btn-change': btnChangeIconImage,
    'ac-info-white': acInfoWhiteIconImage,
    'ac-info-black': acInfoBlackIconImage,
    'ac-warning': acWarningIconImage,
};
export const SvgIcon = ({ icon, sz = 'sm', classes = '' }) => {
    return <img src={ icon2img[icon] } className={ `svg-icon sz-${sz} ${classes}` } alt=""/>
};

// Inbox
export const InboxIcon = () => <img src={ inboxIconImage } className="w-50" alt="Inbox"/>;

