import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import AuthPageLayout from "./AuthPageLayout";
import ErrorPageLayout from "./ErrorPageLayout";
import PrivatePageLayout from "./PrivatePageLayout";
import './Layout.scss';

export const Page = ({ title, children }) => (
    <Fragment>
        <Helmet><title>Karamba • { title }</title></Helmet>
        { children }
    </Fragment>
);

export const LayoutPrivate = ({ children = null, addonAfter = null, wide, ...rest }) => {
    return (
        <Row { ...rest }>
            <Col xs="12" md={{ size: 9, offset: 3 }} xl={{ size: wide ? 10 : 8, offset: 2 }}>
                { children }
            </Col>
            { addonAfter }
        </Row>
    );
};


const MainContent = ({ wide = false, ...rest }) => {
    return <LayoutPrivate className="main-content" wide={ wide } { ...rest } />;
};

export const Layout = ({ children }) => {
    return (
        <Container fluid className="main-container">
            { children }
        </Container>
    );
};
Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export {
    MainContent,
    AuthPageLayout,
    ErrorPageLayout,
    PrivatePageLayout,
};
export default Layout;
