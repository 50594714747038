import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { getProjectTitle } from "../../redux/modules/project-list";
import EditableProjectTitleContainer from "./EditableProjectTitleContainer"


const mapStateToProps = (state, { projectId }) => {
    return {
        title: getProjectTitle(state, { projectId })
    };
};


class ProjectTitleContainer extends Component {

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        // from connect
        title: PropTypes.string,
    };

    render() {
        const { title, subTitle } = this.props;

        if (!title) { return null; }

        return (
            <div>{ title }<span className="sub-title"> - { subTitle }</span></div>
        );
    }
}
ProjectTitleContainer = connect(mapStateToProps)(ProjectTitleContainer);

export { EditableProjectTitleContainer };
export default ProjectTitleContainer;
