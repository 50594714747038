import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink } from 'reactstrap';
import './UserBar.scss';


const UserLogo = ({ name }) => {
    return (
        <span className="user-logo">{ name[0] }</span>
    );
};


class UserBar extends Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        menuItems: PropTypes.array.isRequired,
    };

    state = {
        menuOpen: false
    };

    toggleMenu() {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    render() {
        const { email, menuItems: items } = this.props;
        const { menuOpen } = this.state;
        const toggleMenu = () => this.toggleMenu();

        const menuItems = items.map(({ label, ...rest }, i) => {
            return <DropdownItem key={ `menu-${i}` } { ...rest }>{ label }</DropdownItem>;
        });

        return (
            <div className="user-bar pull-right">
                <Nav className="pull-xs-right" navbar>
                    <Dropdown nav isOpen={ menuOpen } toggle={ toggleMenu }>
                        <DropdownToggle nav caret tag={ NavLink } href="#" className="user-menu">
                            <UserLogo name={ email }/>
                            <span className="d-none d-sm-block">{ email }</span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            { menuItems }
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
            </div>
        );
    }
}

export default UserBar;
