import { createSelector, defaultMemoize as memoize } from 'reselect';

const AC_SHOW = 'actions/AC_SHOW';
const AC_HIDE = 'actions/AC_HIDE';

const FETCH_ALL = 'events/FETCH_ALL';
const FETCH_ALL_SUCCESS = 'events/FETCH_ALL_SUCCESS';
const FETCH_ALL_FAIL = 'events/FETCH_ALL_FAIL';

const SET_VIEWED_MANY = 'events/SET_VIEWED_MANY';
const SET_VIEWED_MANY_SUCCESS = 'events/SET_VIEWED_MANY_SUCCESS';
const SET_VIEWED_MANY_FAIL = 'events/SET_VIEWED_MANY_FAIL';

const SET_DISCARDED_ONE = 'events/SET_DISCARDED_ONE';
const SET_DISCARDED_ONE_SUCCESS = 'events/SET_DISCARDED_ONE_SUCCESS';
const SET_DISCARDED_ONE_FAIL = 'events/SET_DISCARDED_ONE_FAIL';

const SET_DISCARDED_ALL = 'events/SET_DISCARDED_ALL';
const SET_DISCARDED_ALL_SUCCESS = 'events/SET_DISCARDED_ALL_SUCCESS';
const SET_DISCARDED_ALL_FAIL = 'events/SET_DISCARDED_ALL_FAIL';

const APPEND_ONE = 'events/APPEND_ONE';

// Reducers
//
function reduceActionCenter(state = {
    visible: false,
}, action = {}) {
    switch (action.type) {
        case AC_SHOW:
            return { ...state, visible: true };
        case AC_HIDE:
            return { ...state, visible: false };
        default:
            return state;
    }
}

function reduceEvents(state = {
    list: [],
    meta: {},
}, action = {}) {
    switch (action.type) {
        case FETCH_ALL_SUCCESS:
            return {
                ...state,
                list: action.result.events,
            };
        case APPEND_ONE:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    [action.event._id]: { isAdding: true },
                },
                list: [
                    action.event,
                    ...state.list,
                ],
            };
        case SET_VIEWED_MANY_SUCCESS:
            // Note: due to TTL index on `events` database, the exists possibility that
            // setting viewed/discarded may be done on documents that do not exist anymore.
            // We may safely ignore this fact in UI.
            const { eventIds } = action.result;
            return {
                ...state,
                meta: {
                    ...state.meta,
                    ...eventIds.reduce((acc, eventId) => ({
                        ...acc,
                        [eventId]: undefined,
                    }), {}),
                },
                list: state.list.map(event => ({
                    ...event,
                    isNew: eventIds.includes(event._id) ? false : event.isNew,
                })),
            };
        case SET_DISCARDED_ONE:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    [action.eventId]: { isRemoving: true },
                },
            };
        case SET_DISCARDED_ONE_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    [action.eventId]: undefined,
                },
                list: state.list.filter(({ _id }) => _id !== action.eventId),
            };
        case SET_DISCARDED_ALL_SUCCESS:
            return {
                ...state,
                meta: {},
                list: [],
            };
        default:
            return state;
    }
}

function reducer(state = {
    center: reduceActionCenter(),
    events: reduceEvents(),
}, action) {
    switch (action.type) {
        case AC_SHOW:
        case AC_HIDE:
            return {
                ...state,
                center: reduceActionCenter(state.center, action),
            };
        case FETCH_ALL:
        case FETCH_ALL_SUCCESS:
        case FETCH_ALL_FAIL:
        case SET_VIEWED_MANY_FAIL:
        case SET_DISCARDED_ONE:
        case SET_DISCARDED_ONE_SUCCESS:
        case SET_DISCARDED_ONE_FAIL:
        case SET_DISCARDED_ALL:
        case SET_DISCARDED_ALL_SUCCESS:
        case SET_DISCARDED_ALL_FAIL:
        case APPEND_ONE:
        case SET_VIEWED_MANY_SUCCESS:
            return {
                ...state,
                events: reduceEvents(state.events, action),
            };
        default:
            return state;
    }
}

// Action creators
//
export function toggleActionCenter(show) {
    return {
        type: show ? AC_SHOW : AC_HIDE,
    };
}

export function appendEvent(event) {
    return {
        type: APPEND_ONE,
        event,
    };
}

export function fetchEvents() {
    return {
        types: [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_FAIL],
        promise: (client) => client.get(`/api/events`),
    };
}

export function setEventViewed(eventId) {
    return {
        types: [SET_VIEWED_MANY, SET_VIEWED_MANY_SUCCESS, SET_VIEWED_MANY_FAIL],
        promise: (client, data) => client.post(`/api/events/viewed`, { data }),
        eventId,
        debounce: {
            key: SET_VIEWED_MANY,
            wait: 500,
            acc2data: (acc) => ({ eventIds: acc.map(it => it.eventId) }),
        },
    };
}

export function setEventDiscarded(eventId) {
    return {
        types: [SET_DISCARDED_ONE, SET_DISCARDED_ONE_SUCCESS, SET_DISCARDED_ONE_FAIL],
        promise: (client) => client.post(`/api/events/${ eventId }/discarded`, {}),
        eventId,
    };
}

export function discardAllEvents() {
    return {
        types: [SET_DISCARDED_ALL, SET_DISCARDED_ALL_SUCCESS, SET_DISCARDED_ALL_FAIL],
        promise: (client) => client.del(`/api/events`),
    };
}

// Selectors
//
const getProjects = (state) => state.project.list.data;
const getEvents = (state) => state.actions.events.list;
const getEventsMeta = (state) => state.actions.events.meta;
const getProjectTitleById = memoize(
    (projects, projectId) => {
        const project = projects.find(p => p._id === projectId);
        return project ? project.title : '';
    },
);

export const getEventGroupsFlat = createSelector(
    [getProjects, getEvents, getEventsMeta],
    (projects, events, meta) => {
        const cache = {};

        return events
            .reduce((acc, data) => {
                const { _id: eventId, payload: { projectId } } = data;
                const projectTitle = getProjectTitleById(projects, projectId);

                let group = cache[projectId];
                let isNew = !group;
                if (isNew) {
                    group = cache[projectId] = { groupId: projectId, groupName: projectTitle, events: [] };
                }
                group.events.push({
                    ...data,
                    meta: meta[eventId],
                });

                return [
                    ...acc,
                    ...(isNew ? [group] : []),
                ];
            }, [])
            .reduce((acc, { groupId, groupName, events }) => ([
                ...acc,
                { type: 'group', uid: groupId, groupId, groupName },
                ...events.map(event => ({ type: 'event', uid: event._id, event })),
            ]), []);
    },
);

export const getUnreadActionsNum = createSelector(
    [getEvents],
    (events) => events.filter(n => n.isNew).length,
);

export default reducer;
