import React from "react";
import { Link } from 'react-router-dom';
import { BackIcon } from "../Elements";


export const InfoBarTitle = ({ title }) => (
    <span className="info-bar-content info-bar-title">{ title }</span>
);

export const InfoBarLink = ({ children, withBack = false, ...rest }) => (
    <Link {...rest} className="info-bar-content info-bar-link">
        { withBack ? <BackIcon/> : undefined }
        { children }
    </Link>
);

const InfoBar = ({ children }) => (
    <div className="info-bar-row">
        { children }
    </div>
);

export default InfoBar;
