import React from "react";
import { Link } from "react-router-dom";
import {
    E_TYPE_PROJECT_NEW,
    E_TYPE_PROJECT_INSTALL,
    E_TYPE_PROJECT_POLICY_WL,
    E_TYPE_PROJECT_POLICY_IM,
    E_TYPE_PROJECT_COLLABORATOR_ADD,
    E_TYPE_PROJECT_COLLABORATOR_ADD_OTHER,
    E_TYPE_PROJECT_COLLABORATOR_REMOVE,
    E_TYPE_PROJECT_COLLABORATOR_REMOVE_OTHER,
    E_TYPE_PROJECT_COLLABORATOR_SET_OWNER,
} from "../../../common/constants";


const Em = ({ children }) => (
    <span className="ac-em">{ children }</span>
);

export const ACItemTitle = ({ type }) => {
    const titles = {
        [E_TYPE_PROJECT_NEW]: 'New project created',
        [E_TYPE_PROJECT_INSTALL]: 'Agent installed',
        [E_TYPE_PROJECT_POLICY_WL]: 'Application Whitelisting policy',
        [E_TYPE_PROJECT_POLICY_IM]: 'In-Memory Validation policy',
        [E_TYPE_PROJECT_COLLABORATOR_ADD]: 'Project access granted',
        [E_TYPE_PROJECT_COLLABORATOR_ADD_OTHER]: 'Project access granted',
        [E_TYPE_PROJECT_COLLABORATOR_REMOVE]: 'Project access restricted',
        [E_TYPE_PROJECT_COLLABORATOR_REMOVE_OTHER]: 'Project access restricted',
        [E_TYPE_PROJECT_COLLABORATOR_SET_OWNER]: 'Project has new owner',
    };

    return titles[type] || 'TODO';
};

export const ACItemBody = (props) => {
    const { type, creator, payload: { projectId, projectTitle, targetUserId } } = props;

    const bodies = {
        [E_TYPE_PROJECT_NEW]: (
            <p className="ac-body">
                Proceed with new project by downloading Agent install package and installing it.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }` }>Visit Project</Link>
            </p>
        ),
        [E_TYPE_PROJECT_INSTALL]: (
            <p className="ac-body">
                Agent is installed and ECU is ready to become Sealed. Run new build to proceed.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }` }>Visit Project</Link>
            </p>
        ),
        [E_TYPE_PROJECT_POLICY_WL]: (
            <p className="ac-body">
                New Application Whitelisting policy is generated during build. You can review and modify it.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }/whitelist` }>View policy</Link>
            </p>
        ),
        [E_TYPE_PROJECT_POLICY_IM]: (
            <p className="ac-body">
                New In-Memory Validation policy is generated during build. You can review and modify it.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }/in-memory` }>View policy</Link>
            </p>
        ),
        [E_TYPE_PROJECT_COLLABORATOR_ADD]: (
            <p className="ac-body">
                A project was shared with you by <Em>{ creator }</Em>.
                Now you can modify it and share with others.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }` }>Visit Project</Link>
            </p>
        ),
        [E_TYPE_PROJECT_COLLABORATOR_ADD_OTHER]: (
            <p className="ac-body">
                A project was shared with <Em>{ targetUserId }</Em> by <Em>{ creator }</Em>.
                Now <Em>{ targetUserId }</Em> can modify it and share with others.
                As project owner, you should be aware of it.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }` }>Visit Project</Link>
            </p>
        ),
        [E_TYPE_PROJECT_COLLABORATOR_REMOVE]: (
            <p className="ac-body">
                User <Em>{ creator }</Em> has unshared the project <Em>{ projectTitle }</Em> with you.
                You cannot see it anymore.
            </p>
        ),
        [E_TYPE_PROJECT_COLLABORATOR_REMOVE_OTHER]: (
            <p className="ac-body">
                User <Em>{ creator }</Em> has unshared the project with <Em>{ targetUserId }</Em>.
                As project owner, you should be aware of it.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }` }>Visit Project</Link>
            </p>
        ),
        [E_TYPE_PROJECT_COLLABORATOR_SET_OWNER]: (
            <p className="ac-body">
                User <Em>{ creator }</Em> has passed project ownership to you.
                Now you have power to remove the project or set new owner.<br/>
                <Link className="ac-link" to={ `/projects/${ projectId }` }>Visit Project</Link>
            </p>
        ),
    };

    return bodies[type] || 'TODO';
};
